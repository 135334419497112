.error-page__content {
  margin-top: 56px;
  padding-top: 260px;
}
@media (min-width: 720px) {
  .error-page__content {
    padding-bottom: 90px;
    padding-top: 64px;
  }
}
@media (min-width: 960px) {
  .error-page__content {
    padding-bottom: 100px;
    padding-top: 80px;
  }
}
@media (min-width: 1248px) {
  .error-page__content {
    margin-top: 80px;
    padding-bottom: 240px;
    padding-top: 144px;
    padding-top: 136px;
  }
}
.error-page__content:before {
  /*background-image: url(/images/404.70964f9a..jpg);*/
  background-position: 77% top;
  background-repeat: no-repeat;
  background-size: 720px 380px;
  content: "";
  height: 380px;
  left: 0;
  position: absolute;
  top: 56px;
  width: 100%;
  z-index: 0;
}
@media (min-width: 720px) {
  .error-page__content:before {
    background-position: top;
    background-size: 960px 505px;
    height: 505px;
    top: 82px;
  }
}
@media (min-width: 960px) {
  .error-page__content:before {
    background-size: 1248px 657px;
    height: 657px;
    top: 56px;
  }
}
@media (min-width: 1248px) {
  .error-page__content:before {
    background-size: 1632px 859px;
    height: 859px;
    top: 28px;
  }
}
@media (min-width: 1632px) {
  .error-page__content:before {
    background-position: 50%;
    background-size: contain;
  }
}
.error-page__content-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  z-index: 1;
}
.error-page__content-wrapper > * {
  z-index: 1;
}
@media (min-width: 720px) {
  .error-page__content-wrapper {
    align-items: flex-start;
    max-width: 460px;
  }
}
.error-page__code {
  color: #283151;
  font-size: 40px;
  line-height: 50px;
}
@media (min-width: 720px) {
  .error-page__code {
    font-size: 32px;
    line-height: 40px;
  }
}
@media (min-width: 1248px) {
  .error-page__code {
    font-size: 40px;
    line-height: 50px;
  }
}
.error-page__title {
  color: #fff;
  margin-bottom: 16px;
}
.error-page__text {
  color: hsla(0, 0%, 100%, 0.65);
  margin-bottom: 32px;
  text-align: center;
}
@media (min-width: 720px) {
  .error-page__text {
    text-align: left;
  }
}
.error-page__button {
  min-width: 165px;
  padding: 12px 20px !important;
}