* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    color: #fff;
}

.stub {
    text-align: center;
    background-image: url('../../container/assets/restricted/background.jpeg');
    background-repeat: no-repeat;
    background-size: 1568px auto;
    background-position: center;
    background-color: #141B34;
}

.stub .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 766px;
    width: calc(100% - 32px);
    margin: auto;
    padding: 69px 0;
    min-height: 100vh;
}

.stub__top {
    padding-bottom: 449px;
}

.stub__logo img {
    max-width: 255px;
    width: 100%;
}

.stub__title {
    font: bold 48px/56px 'Montserrat';
    text-transform: uppercase;
    margin-top: 32px;
    margin-bottom: 16px;
}

.stub__text {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.stub__support {
    display: flex;
    gap: 16px;
    text-align: left;
}

.stub__support--item {
    display: flex;
    border-radius: 20px;
    background: rgba(14, 19, 37, 0.40);
    backdrop-filter: blur(5px);
    padding: 24px;
    flex: 1 1 100%;
}

.item__icon img {
    width: 32px;
    height: 32px;
    margin-right: 12px;
}

.item__content {
    font-size: 16px;
    line-height: 24px;

}

.item__content--title {
    margin-bottom: 8px;
    font-weight: 700;
}

.item__content--text {
    font-weight: 400;
    color: rgba(255, 255, 255, 0.65);
}

.item__content--text a {
    color: #ffd653;
    text-decoration: none;
    transition: color 0.3s;
}

.item__content--text a:hover,
.item__content--text a:active,
.item__content--text a:focus {
    color: #fff;
}


@media (max-width: 1248px) {
    .stub {
        background-size: 1248px auto;
    }

    .stub .container {
        padding: 64px 0;
    }

    .stub__top {
        padding-bottom: 348px;
    }
}

@media (max-width: 960px) {
    .stub .container {
        padding: 56px 0;
    }

    .stub__top {
        padding-bottom: 398px;
    }

    .stub__logo img {
        max-width: 212px;
    }

    .stub__title {
        font-size: 40px;
        line-height: 50px;
    }

    .stub__support {
        gap: 12px;
        flex-direction: column;
        max-width: 500px;
        margin: 0 auto;
    }
}

@media (max-width: 720px) {
    .stub__top {
        padding-bottom: 422px;
    }
}

@media (max-width: 460px) {
    .stub {
        background-size: 861px auto;
    }

    .stub .container {
        padding: 24px 0;
    }

    .stub__top {
        padding-bottom: 258px;
    }

    .stub__logo img {
        max-width: 153px;
    }

    .stub__title {
        font-size: 24px;
        line-height: 30px;
    }

    .stub__support {
        gap: 8px;
    }

    .stub__support--item {
        padding: 20px 16px;
    }
}