.cqsFgi {
    margin: 15px 20px 32px;
}

.kvpgjy {
    margin-block-end: 15px;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

@media (min-width: 1200px) {
    .kvpgjy {
        display: none;
    }
    .cqsFgi {
        margin-block-start: 30px;
    }
}

