iframe#_hjRemoteVarsFrame {
    display: none !important;
    width: 1px !important;
    height: 1px !important;
    opacity: 0 !important;
    pointer-events: none !important;
}

@keyframes omBounce {
    0%,
    20%,
    50%,
    80%,
    to {
        transform: translateY(0)
    }
    40% {
        transform: translateY(-30px)
    }
    60% {
        transform: translateY(-15px)
    }
}

.om-animation-bounce {
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-name: omBounce
}

@keyframes omBounceIn {
    0% {
        opacity: 0;
        transform: scale(.3)
    }
    50% {
        opacity: 1;
        transform: scale(1.05)
    }
    70% {
        transform: scale(.9)
    }
    to {
        opacity: 1;
        transform: scale(1)
    }
}

.om-animation-bounce-in {
    animation-duration: 1s;
    animation-name: omBounceIn
}

@keyframes omBounceInDown {
    0% {
        opacity: 0;
        transform: translateY(-2000px)
    }
    60% {
        opacity: 1;
        transform: translateY(30px)
    }
    80% {
        transform: translateY(-10px)
    }
    to {
        transform: translateY(0)
    }
}

.om-animation-bounce-in-down {
    animation-duration: 1s;
    animation-name: omBounceInDown
}

@keyframes omBounceInLeft {
    0% {
        opacity: 0;
        transform: translateX(-2000px)
    }
    60% {
        opacity: 1;
        transform: translateX(30px)
    }
    80% {
        transform: translateX(-10px)
    }
    to {
        transform: translateX(0)
    }
}

.om-animation-bounce-in-left {
    animation-duration: 1s;
    animation-name: omBounceInLeft
}

@keyframes omBounceInRight {
    0% {
        opacity: 0;
        transform: translateX(2000px)
    }
    60% {
        opacity: 1;
        transform: translateX(-30px)
    }
    80% {
        transform: translateX(10px)
    }
    to {
        transform: translateX(0)
    }
}

.om-animation-bounce-in-right {
    animation-duration: 1s;
    animation-name: omBounceInRight
}

@keyframes omBounceInUp {
    0% {
        opacity: 0;
        transform: translateY(2000px)
    }
    60% {
        opacity: 1;
        transform: translateY(-30px)
    }
    80% {
        transform: translateY(10px)
    }
    to {
        transform: translateY(0)
    }
}

.om-animation-bounce-in-up {
    animation-duration: 1s;
    animation-name: omBounceInUp
}

@keyframes omFlash {
    0%,
    50%,
    to {
        opacity: 1
    }
    25%,
    75% {
        opacity: 0
    }
}

.om-animation-flash {
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-name: omFlash
}

@keyframes omFlip {
    0% {
        transform: perspective(800px) translateZ(0) rotateY(0) scale(1);
        animation-timing-function: ease-out
    }
    40% {
        transform: perspective(800px) translateZ(150px) rotateY(170deg) scale(1);
        animation-timing-function: ease-out
    }
    50% {
        transform: perspective(800px) translateZ(150px) rotateY(190deg) scale(1);
        animation-timing-function: ease-in
    }
    80% {
        transform: perspective(800px) translateZ(0) rotateY(1turn) scale(.95);
        animation-timing-function: ease-in
    }
    to {
        transform: perspective(800px) translateZ(0) rotateY(1turn) scale(1);
        animation-timing-function: ease-in
    }
}

.om-animation-flip {
    animation-duration: 1s;
    backface-visibility: visible;
    animation-name: omFlip
}

@keyframes omFlipInX {
    0% {
        transform: perspective(800px) rotateX(90deg);
        opacity: 0
    }
    40% {
        transform: perspective(800px) rotateX(-10deg)
    }
    70% {
        transform: perspective(800px) rotateX(10deg)
    }
    to {
        transform: perspective(800px) rotateX(0deg);
        opacity: 1
    }
}

.om-animation-flip-down {
    animation-duration: 1s;
    backface-visibility: visible;
    animation-name: omFlipInX
}

@keyframes omFlipInY {
    0% {
        transform: perspective(800px) rotateY(90deg);
        opacity: 0
    }
    40% {
        transform: perspective(800px) rotateY(-10deg)
    }
    70% {
        transform: perspective(800px) rotateY(10deg)
    }
    to {
        transform: perspective(800px) rotateY(0deg);
        opacity: 1
    }
}

.om-animation-flip-side {
    animation-duration: 1s;
    backface-visibility: visible;
    animation-name: omFlipInY
}

@keyframes omLightSpeedIn {
    0% {
        transform: translateX(100%) skewX(-30deg);
        opacity: 0
    }
    60% {
        transform: translateX(-20%) skewX(30deg);
        opacity: 1
    }
    80% {
        transform: translateX(0) skewX(-15deg);
        opacity: 1
    }
    to {
        transform: translateX(0) skewX(0deg);
        opacity: 1
    }
}

.om-animation-light-speed {
    animation-duration: 1s;
    animation-name: omLightSpeedIn;
    animation-timing-function: ease-out
}

@keyframes omPulse {
    0% {
        transform: scale(1)
    }
    50% {
        transform: scale(1.1)
    }
    to {
        transform: scale(1)
    }
}

.om-animation-pulse {
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-name: omPulse
}

@keyframes omRollIn {
    0% {
        opacity: 0;
        transform: translateX(-100%) rotate(-120deg)
    }
    to {
        opacity: 1;
        transform: translateX(0) rotate(0deg)
    }
}

.om-animation-roll-in {
    animation-duration: 1s;
    animation-name: omRollIn
}

@keyframes omRotateIn {
    0% {
        transform-origin: center center;
        transform: rotate(-200deg);
        opacity: 0
    }
    to {
        transform-origin: center center;
        transform: rotate(0);
        opacity: 1
    }
}

.om-animation-rotate {
    animation-duration: 1s;
    animation-name: omRotateIn
}

@keyframes omRotateInDownLeft {
    0% {
        transform-origin: left bottom;
        transform: rotate(-90deg);
        opacity: 0
    }
    to {
        transform-origin: left bottom;
        transform: rotate(0);
        opacity: 1
    }
}

.om-animation-rotate-down-left {
    animation-duration: 1s;
    animation-name: omRotateInDownLeft
}

@keyframes omRotateInDownRight {
    0% {
        transform-origin: right bottom;
        transform: rotate(90deg);
        opacity: 0
    }
    to {
        transform-origin: right bottom;
        transform: rotate(0);
        opacity: 1
    }
}

.om-animation-rotate-down-right {
    animation-duration: 1s;
    animation-name: omRotateInDownRight
}

@keyframes omRotateInUpLeft {
    0% {
        transform-origin: left bottom;
        transform: rotate(90deg);
        opacity: 0
    }
    to {
        transform-origin: left bottom;
        transform: rotate(0);
        opacity: 1
    }
}

.om-animation-rotate-up-left {
    animation-duration: 1s;
    animation-name: omRotateInUpLeft
}

@keyframes omRotateInUpRight {
    0% {
        transform-origin: right bottom;
        transform: rotate(-90deg);
        opacity: 0
    }
    to {
        transform-origin: right bottom;
        transform: rotate(0);
        opacity: 1
    }
}

.om-animation-rotate-up-right {
    animation-duration: 1s;
    animation-name: omRotateInUpRight
}

@keyframes omRubberBand {
    0% {
        transform: scale(1)
    }
    30% {
        transform: scaleX(1.25) scaleY(.75)
    }
    40% {
        transform: scaleX(.75) scaleY(1.25)
    }
    60% {
        transform: scaleX(1.15) scaleY(.85)
    }
    to {
        transform: scale(1)
    }
}

.om-animation-rubber-band {
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-name: omRubberBand
}

@keyframes omShake {
    0%,
    to {
        transform: translateX(0)
    }
    10%,
    30%,
    50%,
    70%,
    90% {
        transform: translateX(-10px)
    }
    20%,
    40%,
    60%,
    80% {
        transform: translateX(10px)
    }
}

.om-animation-shake {
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-name: omShake
}

@keyframes omSlideInDown {
    0% {
        opacity: 0;
        transform: translateY(-2000px)
    }
    to {
        transform: translateY(0)
    }
}

.om-animation-slide-in-down {
    animation-duration: 1s;
    animation-name: omSlideInDown
}

@keyframes omSlideInLeft {
    0% {
        opacity: 0;
        transform: translateX(-2000px)
    }
    to {
        transform: translateX(0)
    }
}

.om-animation-slide-in-left {
    animation-duration: 1s;
    animation-name: omSlideInLeft
}

@keyframes omSlideInRight {
    0% {
        opacity: 0;
        transform: translateX(2000px)
    }
    to {
        transform: translateX(0)
    }
}

.om-animation-slide-in-right {
    animation-duration: 1s;
    animation-name: omSlideInRight
}

@keyframes omSwing {
    20% {
        transform: rotate(15deg)
    }
    40% {
        transform: rotate(-10deg)
    }
    60% {
        transform: rotate(5deg)
    }
    80% {
        transform: rotate(-5deg)
    }
    to {
        transform: rotate(0deg)
    }
}

.om-animation-swing {
    animation-duration: 1s;
    transform-origin: top center;
    animation-name: omSwing
}

@keyframes omTada {
    0% {
        transform: scale(1)
    }
    10%,
    20% {
        transform: scale(.9) rotate(-3deg)
    }
    30%,
    50%,
    70%,
    90% {
        transform: scale(1.1) rotate(3deg)
    }
    40%,
    60%,
    80% {
        transform: scale(1.1) rotate(-3deg)
    }
    to {
        transform: scale(1) rotate(0)
    }
}

.om-animation-tada {
    animation-duration: 1s;
    animation-name: omTada
}

@keyframes omWobble {
    0% {
        transform: translateX(0)
    }
    15% {
        transform: translateX(-25%) rotate(-5deg)
    }
    30% {
        transform: translateX(20%) rotate(3deg)
    }
    45% {
        transform: translateX(-15%) rotate(-3deg)
    }
    60% {
        transform: translateX(10%) rotate(2deg)
    }
    75% {
        transform: translateX(-5%) rotate(-1deg)
    }
    to {
        transform: translateX(0)
    }
}

.om-animation-wobble {
    animation-duration: 1s;
    animation-name: omWobble
}

.om-content-lock {
    color: transparent!important;
    text-shadow: rgba(0, 0, 0, .5) 0 0 10px;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
    filter: url("data:image/svg+xml;utf9,<svg%20version='1.1'%20xmlns='http://www.w3.org/2000/svg'><filter%20id='blur'><feGaussianBlur%20stdDeviation='10'%20/></filter></svg>#blur");
    -webkit-filter: blur(10px);
    -ms-filter: blur(10px);
    -o-filter: blur(10px);
    filter: blur(10px)
}

html.om-mobile-position,
html.om-mobile-position body {
    position: fixed!important
}

html.om-ios-form,
html.om-ios-form body {
    transform: translateZ(0)!important;
    -webkit-overflow-scrolling: touch!important;
    height: 100%!important;
    overflow: auto!important
}

html.om-position-popup body {
    overflow: hidden!important
}

html.om-position-floating-top {
    transition: padding-top .5s ease!important
}

html.om-position-floating-bottom {
    transition: padding-bottom .5s ease!important
}

html.om-reset-dimensions {
    height: 100%!important;
    width: 100%!important
}

.om-verification-confirmation {
    font-family: Lato, Arial, Helvetica, sans-serif;
    position: fixed;
    border-radius: 10px;
    bottom: 20px;
    left: 20px;
    padding: 10px 20px;
    opacity: 0;
    transition: opacity .3s ease-in;
    background: #85bf31;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    z-index: 9999
}