.page-game {
    display: block;
    background: #1c1e26;
    z-index: 100;
    padding-bottom: 32px
}

.page-game .page-game__header {
    position: relative;
    z-index: 10
}

.page-game__bg,
.page-game__bg img {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.page-game__bg img {
    border: none;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
    opacity: .7
}

.page-game__bg:after {
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    height: 256px;
    background: linear-gradient(180deg, rgba(28, 30, 38, 0), #1c1e26)
}

.page-game__bg:after,
.page-game__bg:before {
    content: "";
    display: block;
    position: absolute;
    width: 100%
}

.page-game__bg:before {
    top: 0;
    left: 0;
    height: 180px;
    background: linear-gradient(180deg, rgba(28, 30, 38, 0), rgba(28, 30, 38, .8));
    transform: matrix(1, 0, 0, -1, 0, 0);
    z-index: 1
}

.page-game__container {
    margin: 0 auto;
    padding: 0 100px;
    overflow: hidden
}

@media(min-width:1632px) {
    .page-game__container {
        max-width: 1530px
    }
}

@media(min-width:1920px) {
    .page-game__container {
        max-width: 1770px
    }
}

.page-game__container--mobile {
    padding: 0
}

.page-game__container--game {
    position: static;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 120px);
    margin-bottom: 40px
}

.page-game__content {
    background-color: #1c1e26;
    position: relative
}

.page-game__content:before {
    content: "";
    display: none;
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 100%;
    height: 256px;
    background: linear-gradient(180deg, rgba(28, 30, 38, 0), #1c1e26);
    z-index: 1;
    color: #fff
}

@media(min-width:720px) {
    .page-game__content:before {
        display: block
    }
}

.page-game .page-game__panel {
    display: none;
    z-index: 1
}

@media(min-width:960px) {
    .page-game .page-game__panel {
        display: flex
    }
}

.page-game__description {
    margin: 32px 0
}

.page-game__grid {
    flex-grow: 1;
    display: grid;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0;
    margin: 0;
    grid-template-columns: 1fr;
    max-width: 100%;
    max-height: 100%;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    width: calc(177.77778vh - 78.22222px);
    height: calc(75vw - 49.5px);
    z-index: 2
}

@media(min-width:1248px) {
    .page-game__grid {
        grid-column-gap: 24px;
        grid-row-gap: 24px
    }
}

@media(min-width:1632px) {
    .page-game__grid {
        width: calc(177.77778vh - 92.44444px);
        height: calc(75vw - 69px)
    }
}

.page-game__grid--count-2 {
    grid-template-columns: repeat(2, 1fr);
    width: calc(355.55556vh - 192px);
    height: calc(37.5vw - 24.75px)
}

@media(min-width:1248px) {
    .page-game__grid--count-2 {
        width: calc(355.55556vh - 241.77778px)
    }
}

@media(min-width:1632px) {
    .page-game__grid--count-2 {
        width: calc(355.55556vh - 270.22222px);
        height: calc(37.5vw - 34.5px)
    }
}

.page-game__grid--count-4 {
    grid-template-columns: repeat(2, 1fr);
    width: calc(177.77778vh - 96px);
    height: calc(75vw - 57px)
}

@media(min-width:1248px) {
    .page-game__grid--count-4 {
        width: calc(177.77778vh - 120.88889px);
        height: calc(75vw - 67.5px)
    }
}

@media(min-width:1632px) {
    .page-game__grid--count-4 {
        width: calc(177.77778vh - 135.11111px);
        height: calc(75vw - 87px)
    }
}

.page-game__grid .game-frame__frame--fix {
    max-width: calc(129.03226vh - 56.77419px)
}

@media(min-width:1632px) {
    .page-game__grid .game-frame__frame--fix {
        max-width: calc(129.03226vh - 67.09677px)
    }
}

.page-game__grid--count-2 .game-frame__frame--fix {
    max-width: calc(129.03226vh - 69.67742px)
}

@media(min-width:1248px) {
    .page-game__grid--count-2 .game-frame__frame--fix {
        max-width: calc(129.03226vh - 87.74194px)
    }
}

@media(min-width:1632px) {
    .page-game__grid--count-2 .game-frame__frame--fix {
        max-width: calc(129.03226vh - 98.06452px)
    }
}

.page-game__grid--count-4 .game-frame__frame--fix {
    max-width: calc(64.51613vh - 34.83871px)
}

@media(min-width:1248px) {
    .page-game__grid--count-4 .game-frame__frame--fix {
        max-width: calc(64.51613vh - 43.87097px)
    }
}

@media(min-width:1632px) {
    .page-game__grid--count-4 .game-frame__frame--fix {
        max-width: calc(64.51613vh - 49.03226px)
    }
}

.page-game__grid-item {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    width: 100%;
    min-height: 100%;
    max-height: 100%;
    background-color: #1c1e26;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    overflow: hidden
}

.page-game__grid--count-4 .page-game__grid-item {
    height: calc(50vh - 65px)
}

.page-game__slider {
    display: block;
    z-index: 1
}

.page-game__frame-modal {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    width: 100%;
    top: 27px;
    height: calc(100% - 80px);
    border: none
}

.unavailable-game {
    height: 100%
}

.unavailable-game__message {
    text-align: center;
    padding: 30px
}

.unavailable-game__games-block {
    height: 100%;
    overflow: auto
}

.common-slider__controls {
    display: none
}

@media(min-width:960px) {
    .common-slider__controls {
        position: absolute;
        display: flex;
        top: 0;
        right: 0
    }
}

.common-slider__button {
    min-width: 40px;
    width: 40px
}

.common-slider__button--prev {
    border-radius: 8px 0 0 8px
}

.common-slider__button--prev>i {
    transform: rotate(90deg)
}

.common-slider__button--next {
    border-radius: 0 8px 8px 0
}

.common-slider__button--next>i {
    transform: rotate(-90deg)
}

.common-slider.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent
}

.common-slider.slick-slider .slick-list {
    position: relative;
    overflow: visible!important;
    display: block;
    margin: 0;
    padding: 0
}

@media(min-width:960px) {
    .common-slider.slick-slider .slick-list {
        overflow: hidden!important
    }
}

.common-slider.slick-slider .slick-list:focus {
    outline: none
}

.common-slider.slick-slider .slick-list.dragging {
    cursor: pointer;
    cursor: hand
}

.common-slider.slick-slider .slick-list,
.common-slider.slick-slider .slick-track {
    transform: translateZ(0)
}

.common-slider.slick-slider .slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto
}

.common-slider.slick-slider .slick-track:after,
.common-slider.slick-slider .slick-track:before {
    content: "";
    display: table
}

.common-slider.slick-slider .slick-track:after {
    clear: both
}

.slick-loading .common-slider.slick-slider .slick-track {
    visibility: hidden
}

.common-slider.slick-slider .slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    display: none
}

.common-slider.slick-slider .slick-slide:not(:last-child) {
    margin-right: 8px
}

@media(min-width:720px) {
    .common-slider.slick-slider .slick-slide:not(:last-child) {
        margin-right: 12px
    }
}

@media(min-width:1248px) {
    .common-slider.slick-slider .slick-slide:not(:last-child) {
        margin-right: 16px
    }
}

[dir=rtl] .common-slider.slick-slider .slick-slide {
    float: right
}

.common-slider.slick-slider .slick-slide.slick-loading img {
    display: none
}

.common-slider.slick-slider .slick-slide.dragging img {
    pointer-events: none
}

.common-slider.slick-slider.slick-initialized .slick-slide {
    display: block
}

.common-slider.slick-slider.slick-loading .slick-slide {
    visibility: hidden
}

.common-slider.slick-slider.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent
}

.common-slider.slick-slider .slick-arrow.slick-hidden {
    display: none
}


/* CrossGaming comment
@font-face {
    font-family: slick;
    src: url(/fonts/slick.a4e97f5a.eot);
    src: url(/fonts/slick.a4e97f5a.eot?#iefix) format("embedded-opentype"), 
    url(/fonts/slick.29518378.woff) format("woff"), 
    url(/fonts/slick.c94f7671.ttf) format("truetype"), 
    url(/fonts/slick.2630a3e3.svg#slick) format("svg");
    font-weight: 400;
    font-style: normal
}*/

.slider.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent
}

.slider.slick-slider .slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0
}

.slider.slick-slider .slick-list:focus {
    outline: none
}

.slider.slick-slider .slick-list.dragging {
    cursor: pointer;
    cursor: hand
}

.slider.slick-slider .slick-list,
.slider.slick-slider .slick-track {
    transform: translateZ(0)
}

.slider.slick-slider .slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto
}

.slider.slick-slider .slick-track:after,
.slider.slick-slider .slick-track:before {
    content: "";
    display: table
}

.slider.slick-slider .slick-track:after {
    clear: both
}

.slick-loading .slider.slick-slider .slick-track {
    visibility: hidden
}

.slider.slick-slider .slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    display: none
}

[dir=rtl] .slider.slick-slider .slick-slide {
    float: right
}

.slider.slick-slider .slick-slide img {
    display: block
}

.slider.slick-slider .slick-slide.slick-loading img {
    display: none
}

.slider.slick-slider .slick-slide.dragging img {
    pointer-events: none
}

.slider.slick-slider.slick-initialized .slick-slide {
    display: block
}

.slider.slick-slider.slick-loading .slick-slide {
    visibility: hidden
}

.slider.slick-slider.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent
}

.slider.slick-slider .slick-arrow.slick-hidden {
    display: none
}

.slider.slick-slider .slick-loading .slick-list {
    background: #fff url(../assets/loader.gif) 50% no-repeat
}

.slider.slick-slider .slick-next,
.slider.slick-slider .slick-prev {
    position: absolute;
    display: block;
    height: 20px;
    width: 20px;
    line-height: 0;
    font-size: 0;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: 50%;
    transform: translateY(-50%);
    padding: 0;
    border: none;
    outline: none
}

.slider.slick-slider .slick-next:focus,
.slider.slick-slider .slick-next:hover,
.slider.slick-slider .slick-prev:focus,
.slider.slick-slider .slick-prev:hover {
    outline: none;
    background: transparent;
    color: transparent
}

.slider.slick-slider .slick-next:focus:before,
.slider.slick-slider .slick-next:hover:before,
.slider.slick-slider .slick-prev:focus:before,
.slider.slick-slider .slick-prev:hover:before {
    opacity: 1
}

.slider.slick-slider .slick-next.slick-disabled:before,
.slider.slick-slider .slick-prev.slick-disabled:before {
    opacity: .25
}

.slider.slick-slider .slick-next:before,
.slider.slick-slider .slick-prev:before {
    font-family: slick;
    font-size: 20px;
    line-height: 1;
    color: #fff;
    opacity: .75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.slider.slick-slider .slick-prev {
    left: -25px
}

[dir=rtl] .slider.slick-slider .slick-prev {
    left: auto;
    right: -25px
}

.slider.slick-slider .slick-prev:before {
    content: "←"
}

[dir=rtl] .slider.slick-slider .slick-prev:before {
    content: "→"
}

.slider.slick-slider .slick-next {
    right: -25px
}

[dir=rtl] .slider.slick-slider .slick-next {
    left: -25px;
    right: auto
}

.slider.slick-slider .slick-next:before {
    content: "→"
}

[dir=rtl] .slider.slick-slider .slick-next:before {
    content: "←"
}

.slider.slick-slider.slick-dotted {
    margin-bottom: 30px
}

.slider.slick-slider .slick-dots {
    position: absolute;
    bottom: -25px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%
}

.slider.slick-slider .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer
}

.slider.slick-slider .slick-dots li button {
    border: 0;
    background: transparent;
    display: block;
    height: 20px;
    width: 20px;
    outline: none;
    line-height: 0;
    font-size: 0;
    color: transparent;
    padding: 5px;
    cursor: pointer
}

.slider.slick-slider .slick-dots li button:focus,
.slider.slick-slider .slick-dots li button:hover {
    outline: none
}

.slider.slick-slider .slick-dots li button:focus:before,
.slider.slick-slider .slick-dots li button:hover:before {
    opacity: 1
}

.slider.slick-slider .slick-dots li button:before {
    position: absolute;
    top: 0;
    left: 0;
    content: "•";
    width: 20px;
    height: 20px;
    font-family: slick;
    font-size: 6px;
    line-height: 20px;
    text-align: center;
    color: #fff;
    opacity: .25;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.slider.slick-slider .slick-dots li.slick-active button:before {
    color: #fff;
    opacity: .75
}

.game-menu {
    display: flex;
    flex-direction: column;
    z-index: 2
}

.game-menu__slider {
    display: none
}

@media(min-width:960px) {
    .game-menu__slider {
        display: block
    }
}

.game-menu__list {
    display: flex;
    flex-direction: column
}

.game-menu__list .home-game-slider__head {
    align-items: center;
    margin-bottom: 12px
}

.game-menu__list .slick-list {
    overflow: visible!important
}

@media(min-width:960px) {
    .game-menu__list .slick-list {
        overflow: hidden!important
    }
}

@media(min-width:960px) {
    .game-menu__list {
        display: none
    }
}

.game-menu__category {
    margin-bottom: 36px
}

.game-panel {
    position: absolute;
    left: 0;
    top: 0;
    justify-content: center;
    width: 88px;
    height: 100%;
    margin: 0;
    padding: 0;
    transition: width .3s ease
}

.game-controls,
.game-panel {
    display: flex;
    flex-direction: column;
    align-items: center
}

.game-controls {
    border-radius: 8px;
    width: 48px;
    overflow: hidden
}

.game-controls .game-controls__view {
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    margin-bottom: 12px;
    color: hsla(0, 0%, 100%, .4)
}

.game-controls .game-controls__view:hover {
    color: hsla(0, 0%, 100%, .65)
}

.game-controls .game-controls__button {
    padding: 0;
    min-width: 40px;
    font-size: 18px;
    background-color: rgba(24, 25, 32, .8);
    -webkit-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px);
    border: none!important;
    border-radius: 0;
    outline: none!important
}

.game-controls .game-controls__button:first-child {
    padding-top: 6px
}

.game-controls .game-controls__button:last-child {
    padding-bottom: 6px
}

.game-controls .game-controls__button .game-controls__icon {
    color: #fff
}

.game-controls .game-controls__button .game-controls__icon:before {
    font-size: inherit;
    line-height: inherit;
    color: inherit
}

.game-controls .game-controls__button--active .game-controls__icon,
.game-controls .game-controls__button:hover .game-controls__icon {
    color: #3ecdf1;
    border: none
}

.game-controls .game-controls__icon {
    transition: color .3s ease
}

.game-slider-wrapper {
    position: relative;
    width: 100%;
    padding: 0;
    margin: 0;
    border-radius: 4px;
    z-index: 1
}

.game-slider-wrapper__tabs {
    position: absolute;
    top: 0;
    left: 0
}

.game-slider-wrapper__list {
    display: inline-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center
}

.game-slider__item {
    width: calc(50vw - 20px)!important
}

@media(min-width:600px) {
    .game-slider__item {
        width: calc(33.33333vw - 16px)!important
    }
}

@media(min-width:720px) {
    .game-slider__item {
        width: calc(25vw - 17px)!important
    }
}

@media(min-width:960px) {
    .game-slider__item {
        width: calc(16.66667vw - 19px)!important
    }
}

@media(min-width:1248px) {
    .game-slider__item {
        width: calc(16.66667vw - 22px)!important
    }
}

@media(min-width:1350px) {
    .game-slider__item {
        width: 203px!important
    }
}

@media(min-width:1632px) {
    .game-slider__item {
        width: 248px!important
    }
}

.jackpot-select__btn {
    display: flex;
    align-items: center
}

.jackpot-select__btn-wrap {
    position: absolute;
    top: 12px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10
}

@media(max-width:1248px) {
    .jackpot-select__btn-wrap {
        top: 0
    }
}

@media(max-width:1200px) {
    .jackpot-select__btn-wrap {
        transform: none;
        left: auto;
        right: 346px
    }
}

@media(max-width:960px) {
    .jackpot-select__btn-wrap {
        top: 16px;
        display: none
    }
}

@media(max-width:600px) {
    .jackpot-select__btn-wrap {
        left: auto;
        right: 130px
    }
}

@media(max-width:390px) {
    .jackpot-select__btn-wrap {
        display: none
    }
}

@media(max-width:960px) {
    .jackpot-select__btn-logo {
        width: 20px;
        height: 20px
    }
    .jackpot-select__btn-logo img {
        max-width: 100%
    }
}

.jackpot-select__btn-content {
    margin-left: 16px;
    padding-right: 24px;
    position: relative
}

@media(max-width:960px) {
    .jackpot-select__btn-content {
        margin-left: 8px;
        display: flex;
        align-items: center
    }
}

.jackpot-select__btn-name {
    margin-bottom: 2px;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: .01em;
    color: hsla(0, 0%, 100%, .4);
    font-family: Montserrat, Arial, sans-serif
}

@media(max-width:960px) {
    .jackpot-select__btn-name {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 0
    }
}

.jackpot-select__btn-pool {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: .01em;
    text-transform: uppercase;
    color: #fff
}

@media(max-width:960px) {
    .jackpot-select__btn-pool {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        margin-left: 6px
    }
}

.jackpot-select__btn-icon-wrap {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    top: 26px;
    right: 0;
    background: #38353b;
    -webkit-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px);
    font-size: 6px;
    font-weight: 600;
    color: #fff
}

@media(max-width:960px) {
    .jackpot-select__btn-icon-wrap {
        top: 4px;
        width: 12px;
        height: 12px
    }
}

.jackpot-select__btn-icon-wrap--open {
    transform: rotate(180deg)
}

.jackpot-select__btn-arrow-icon {
    margin-left: -1px;
    font-size: 12px;
    margin-top: 2px
}

.jackpot-select__dropdown {
    position: absolute;
    display: none;
    align-items: center;
    padding: 16px 24px;
    top: 60px;
    left: 50%;
    transform: translateX(-50%);
    background: #2e313d;
    box-shadow: 0 6px 12px rgba(0, 0, 0, .25);
    border-radius: 8px;
    cursor: auto
}

@media(max-width:960px) {
    .jackpot-select__dropdown {
        top: 34px;
        flex-wrap: wrap
    }
}

.jackpot-select__dropdown--open {
    display: flex
}

.jackpot-select__item {
    margin: 0 16px;
    display: flex;
    align-items: flex-start;
    cursor: pointer
}

.jackpot-select__item:first-child .jackpot-select__bottom-item-pool {
    color: #ffd653
}

.jackpot-select__item:nth-child(3) .jackpot-select__logo {
    width: 46px
}

@media(max-width:1200px) {
    .jackpot-select__item:nth-child(3) .jackpot-select__logo {
        width: 34px;
        height: 40px
    }
}

@media(max-width:1200px) {
    .jackpot-select__item {
        margin: 0 8px;
        align-items: center
    }
}

@media(max-width:960px) {
    .jackpot-select__item {
        width: 100%;
        margin: 0
    }
}

.jackpot-select__item .icon-info {
    margin-left: 5px;
    font-size: 14px;
    position: absolute;
    top: 0;
    right: 0;
    display: none
}

.jackpot-select__item:hover .icon-info {
    display: block
}

.jackpot-select__logo {
    width: 54px;
    height: 54px;
    display: flex;
    justify-content: center;
    align-items: center
}

@media(max-width:1200px) {
    .jackpot-select__logo {
        width: 40px;
        height: 40px;
        margin-top: 8px
    }
}

@media(max-width:960px) {
    .jackpot-select__logo {
        width: 52px;
        height: 52px
    }
}

.jackpot-select__logo img {
    max-width: 100%
}

.jackpot-select__bottom-item-text {
    margin-left: 10px;
    margin-top: 10px
}

@media(max-width:960px) {
    .jackpot-select__bottom-item-text {
        width: calc(100% - 48px);
        margin-left: 12px;
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 9px;
        padding-bottom: 9px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap
    }
}

.jackpot-select__bottom-item-name {
    white-space: nowrap;
    font-family: Montserrat, Arial, sans-serif;
    margin-bottom: 4px;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
    color: hsla(0, 0%, 100%, .4);
    padding-right: 18px;
    position: relative
}

@media(max-width:1200px) {
    .jackpot-select__bottom-item-name {
        font-size: 10px;
        line-height: 12px;
        margin-bottom: 0
    }
}

@media(max-width:960px) {
    .jackpot-select__bottom-item-name {
        margin-bottom: 0;
        font-size: 12px;
        line-height: 16px
    }
}

.jackpot-select__bottom-item-pool {
    white-space: nowrap;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: .01em;
    text-transform: uppercase;
    color: #fff
}

@media(max-width:1200px) {
    .jackpot-select__bottom-item-pool {
        font-size: 14px
    }
}

@media(max-width:960px) {
    .jackpot-select__bottom-item-pool {
        font-size: 14px;
        line-height: 17px;
        width: 100%
    }
}

.jackpot-slider {
    position: fixed;
    height: 28px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: #181920
}

.jackpot-slider .slick-list {
    padding-left: 0!important;
    padding-right: 0!important
}

.jackpot-slider .slick-slide {
    justify-content: center;
    height: 28px!important
}

.jackpot-slider .slick-slide,
.jackpot-slider__item {
    display: flex!important;
    align-items: center
}

.jackpot-slider__item--Platinum .jackpot-slider__bottom-item-pool {
    color: #ffd653
}

.jackpot-slider__logo {
    width: 22px;
    height: 22px;
    margin-right: 4px
}

.jackpot-slider__logo img {
    max-width: 100%
}

.jackpot-slider__bottom-item-text {
    display: flex;
    align-items: center
}

.jackpot-slider__bottom-item-name {
    font-size: 12px;
    line-height: 18px;
    color: hsla(0, 0%, 100%, .65);
    margin-right: 4px
}

.jackpot-slider__bottom-item-pool {
    font-size: 12px;
    line-height: 18px;
    color: #fff;
    font-weight: 700
}

.tabs {
    width: 100%;
    z-index: 1
}

.tabs__tabs-wrapper {
    margin-bottom: 20px
}

.tabs__tab {
    display: inline-block;
    color: hsla(0, 0%, 100%, .65);
    transition: color .3s;
    cursor: pointer
}

.tabs__tab:hover {
    color: #fff
}

.tabs__tab:not(:last-child) {
    margin-right: 24px
}

.tabs__tab--active {
    color: #fff;
    border-bottom: 2px solid #3ecdf1
}

.header-game {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 0 16px;
    margin: 0;
    height: 56px;
    z-index: 100;
    transition: .3s ease;
    background-color: #262933
}

@media(min-width:720px) {
    .header-game {
        padding: 0 24px
    }
}

@media(min-width:960px) {
    .header-game {
        background-color: transparent
    }
}

@media(min-width:1248px) {
    .header-game {
        padding: 0 32px;
        height: 80px
    }
}

.header-game--bg {
    background-color: #262933
}

.header-game--hide {
    display: none
}

.header-game .header-game__mode {
    display: none;
    margin-right: auto
}

@media(min-width:960px) {
    .header-game .header-game__mode {
        display: block
    }
}

.header-game .header-game__locales {
    margin-right: -12px;
    display: none
}

.header-game .header-game__locales .select-language-icons-with-code__button .select-language-icons-with-code__caret,
.header-game .header-game__locales .select-language-icons-with-code__button .select-language-icons-with-code__code {
    display: none
}

@media(min-width:720px) {
    .header-game .header-game__locales {
        display: inline-flex
    }
}

.header-game .header-game__search {
    display: none;
    align-items: center;
    padding: 0;
    margin-right: 24px;
    margin-top: -3px
}

@media(min-width:960px) {
    .header-game .header-game__search {
        display: flex;
        justify-content: center
    }
}

.header-game .header-game__search-icon {
    color: #fff;
    font-size: 22px
}

.header-game .header-game__search-placeholder {
    display: none;
    color: hsla(0, 0%, 100%, .4);
    padding-left: 14px;
    font-size: 14px
}

.header-game .header-game__back {
    color: #fff
}

@media(min-width:960px) {
    .header-game .header-game__back {
        display: none
    }
}

.header-game .header-game__notification-center {
    display: none
}

@media(min-width:960px) {
    .header-game .header-game__notification-center {
        display: inline-flex
    }
}

.header-game__user-menu-balance {
    display: inline-flex
}

.header-game__user-menu-select {
    display: none
}

@media(min-width:720px) {
    .header-game__user-menu-select {
        display: inline-flex
    }
}

.header-game .header-game__button-deposit {
    margin: 0 0 0 8px
}

@media(min-width:1248px) {
    .header-game .header-game__button-deposit {
        margin: 0 12px
    }
}

.header-game .header-game__button {
    margin: 0 0 0 12px
}

@media(min-width:720px) {
    .header-game .header-game__button {
        display: inline-flex
    }
}

.header-game__right {
    display: flex;
    align-items: center;
    margin-left: auto
}

.header-game .header__back {
    margin-right: 23px;
    padding-right: 20px;
    position: relative;
    padding-left: 16px;
    display: none
}

@media(min-width:960px) {
    .header-game .header__back {
        display: block
    }
}

.header-game .header__back:after {
    content: "";
    display: block;
    height: 16px;
    width: 1px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    background: hsla(0, 0%, 100%, .2)
}

.header-game .header__back .header__back-text {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    margin-left: 12px
}

.header-game .header__back .header__back-icon {
    font-size: 22px;
    color: #fff;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%)
}

.game-required-fields-modal__content {
    padding: 24px
}

.game-required-fields-modal .game-required-fields-modal__form {
    background-color: transparent
}

@media(min-width:320px) {
    .game-required-fields-modal .game-required-fields-modal__form {
        padding: 16px
    }
}

.game-required-fields-modal__reject-button {
    text-transform: uppercase;
    margin: 10px 0 0
}

@media(min-width:320px) {
    .game-required-fields-modal__reject-button {
        margin: 0 16px 0 0
    }
}

.game-required-fields-modal__submit-button {
    text-transform: uppercase
}

.game-frame .select-games-search-for-modal__dropdown {
    scrollbar-color: #fff;
    scrollbar-width: thin
}

.game-frame .select-games-search-for-modal__dropdown::-webkit-scrollbar {
    width: 2px;
    height: 2px
}

.game-frame .select-games-search-for-modal__dropdown::-webkit-scrollbar-track {
    border-radius: 2px;
    background: hsla(0, 0%, 60%, .5)
}

.game-frame .select-games-search-for-modal__dropdown::-webkit-scrollbar-thumb {
    background: #fff;
    border-radius: 2px;
    margin: 0
}

.game-frame .select-games-search-for-modal__dropdown::-webkit-scrollbar-thumb:hover {
    background: #fff
}

.game-frame {
    display: flex;
    align-items: center;
    padding: 0;
    max-width: 100%;
    max-height: 100%;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    align-self: stretch
}

.game-frame,
.game-frame__empty {
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    margin: 0;
    width: 100%
}

.game-frame__empty {
    display: none;
    align-items: flex-start;
    padding: 24px;
    height: 100%
}

.game-frame--empty .game-frame__empty {
    display: flex
}

.game-frame__inner {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%
}

.game-frame--empty .game-frame__inner {
    display: none
}

.game-frame__head {
    display: none;
    position: relative;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 0 16px;
    margin: 0;
    width: 100%;
    height: 36px;
    background-color: #181920;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px
}

@media(min-width:960px) {
    .game-frame__head {
        display: flex
    }
}

@media(min-width:1632px) {
    .game-frame__head {
        height: 44px
    }
}

.game-frame__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 18px 24px
}

.game-frame__footer .game-frame__button {
    font-size: 20px
}

@media(min-width:960px) {
    .game-frame__footer {
        display: none
    }
}

.game-frame__block-frame {
    flex-wrap: nowrap;
    flex-direction: row;
    width: 100%;
    height: 100%;
    flex: 1;
    overflow: hidden;
    background-color: #181920
}

.game-frame__block-frame,
.game-frame__frame {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0
}

.game-frame__frame {
    flex-wrap: nowrap;
    flex-direction: row;
    max-width: 100%;
    max-height: 100%;
    border: none
}

.game-frame__title {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    text-align: left
}

.game-frame__controls {
    display: inline-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-right: 8px
}

.game-frame__button {
    display: inline-flex;
    align-items: center;
    color: #fff;
    font-size: 16px
}

@media(min-width:1248px) {
    .game-frame__button {
        font-size: 22px
    }
}

.game-frame__button:hover {
    color: hsla(0, 0%, 100%, .65)
}

.game-frame__search {
    display: block;
    width: 100%;
    padding: 0;
    margin: 0
}

.game-frame .select-games-search-for-modal__dropdown {
    max-height: 400px;
    overflow: auto
}

.page-game__grid--count-4 .game-frame .select-games-search-for-modal__dropdown {
    max-height: 300px
}

.page-game__grid--count-4 .game-frame .game-frame__title {
    font-size: 12px
}

@media(min-width:1632px) {
    .page-game__grid--count-4 .game-frame .game-frame__title {
        font-size: 14px
    }
}

.page-game__grid--count-4 .game-frame .game-frame__head {
    height: 24px
}

@media(min-width:1632px) {
    .page-game__grid--count-4 .game-frame .game-frame__head {
        height: 32px
    }
}

.game-info {
    margin-bottom: 56px;
    margin-top: 20px
}

.game-info__top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    background: #262933;
    border-radius: 4px;
    padding: 24px;
    cursor: pointer
}

@media(max-width:960px) {
    .game-info__top {
        flex-wrap: wrap
    }
}

@media(max-width:960px) {
    .game-info__top-block {
        margin-bottom: 24px;
        width: 100%;
        text-align: center
    }
    .game-info__top-block:last-child {
        margin-bottom: 0
    }
}

.game-info__top-block--info {
    display: flex;
    align-items: flex-start
}

@media(max-width:960px) {
    .game-info__top-block--info {
        flex-wrap: wrap
    }
}

.game-info__top-name {
    margin-bottom: 4px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
    color: #fff
}

.game-info__top-provider {
    font-family: Montserrat, Arial, sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: .01em;
    color: hsla(0, 0%, 100%, .4);
    text-transform: uppercase
}

.game-info__top-mini-block {
    margin-left: 32px
}

@media(max-width:960px) {
    .game-info__top-mini-block {
        margin-bottom: 24px;
        margin-left: 0;
        width: 100%
    }
}

.game-info__top-mini-name {
    margin-bottom: 4px;
    font-family: Montserrat, Arial, sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: .01em;
    color: hsla(0, 0%, 100%, .4);
    text-transform: uppercase
}

.game-info__top-mini-value {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    color: #fff
}

.game-info__btn-icon-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 32px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: #2e313d;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    transform: rotate(-90deg)
}

@media(max-width:960px) {
    .game-info__btn-icon-wrap {
        margin-left: auto;
        margin-right: auto
    }
}

.game-info__btn-icon-wrap--open {
    transform: rotate(90deg)
}

.game-info__btn-arrow-icon {
    margin-top: 2px;
    font-size: 20px
}

.game-info__dropdown {
    display: none;
    padding: 40px 24px;
    border-radius: 4px;
    background-color: #262933;
    border-top: 1px solid hsla(0, 0%, 100%, .12)
}

.game-info__dropdown--open {
    display: block
}

.game-info__dropdown .game-description {
    margin-left: auto;
    margin-right: auto;
    max-width: 1248px;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: .01em;
    color: #727272
}

.game-box--preview {
    height: 100vw
}

.game-box__preview {
    position: relative;
    height: 100%
}

.game-box__preview-bg,
.game-box__preview-content {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0
}

.game-box__preview-content {
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: linear-gradient(180deg, rgba(28, 30, 38, .4), #1c1e26);
    text-transform: uppercase
}

.game-box__preview-bg {
    background-size: cover
}

.game-box .game-box__preview-bottom-row {
    position: absolute;
    right: 16px;
    top: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: auto
}

.game-box .game-box__preview-bottom-row .text-btn {
    font-size: 26px
}

.game-box .game-box__preview-bottom-row .icon-heart-active {
    background: linear-gradient(180deg, #47db79, #138f53);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent
}

.game-box__title {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #fff;
    margin-bottom: 4px
}

.game-box__provider {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: .01em;
    text-transform: uppercase;
    color: hsla(0, 0%, 100%, .65)
}

.game-box__game-frame {
    position: absolute;
    top: 0;
    height: calc(100% - 52px);
    left: 0;
    right: 0;
    z-index: 99
}

@media(min-width:720px) {
    .game-box__game-frame {
        height: calc(100% - 64px)
    }
}

.game-box .btn--primary {
    background: none;
    box-shadow: none;
    padding: 12px
}

.game-box__button {
    width: 70px
}

.game-session-close-modal__content {
    padding: 32px;
    line-height: 1.5
}

.game-session-close-modal__buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: 40px
}

.game-session-close-modal.modal .modal__close-button {
    top: 10px;
    right: 10px
}

.reality-check-modal__body {
    padding: 32px;
    line-height: 1.5
}

.reality-check-modal__buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: 40px
}

.reality-check-modal__buttons .btn {
    margin-bottom: 10px
}

.reality-check-modal.modal .modal__close-button {
    top: 10px;
    right: 10px
}