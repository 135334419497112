.gSaNTA {
    width: 100vw;
}

.jIpIlW {
    background-color: rgb(25, 28, 36);
    width: 300px;
}

.fyYAFt {
    margin: 10px 0px;
}

.PNtMq {
    -webkit-box-align: center;
    align-items: center;
    box-sizing: border-box;
    color: rgb(255, 255, 255);
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-weight: 400;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 9px 20px;
    text-decoration: none;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    width: 100%;
}

.sidebar-icons {
    font-size: 16px;
    margin-inline-end: 20px;
}
.PNtMq.active {
    background-color: rgb(53, 56, 69);
}

.guQvBr {
    display: flex;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    align-items: center;
}

.hvGzuo {
    margin-inline-end: 5px;
}

@media (min-width: 1200px) { 
    .jIpIlW {
        inset-block: 60px 0px;
        left: 0px;
        min-width: 270px;
        overflow: hidden auto;
        position: fixed;
        width: 270px;
        z-index: 1025;
    }
    .gSaNTA {
        display: flex;
    }
}