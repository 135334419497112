.games__section {
    display: block;
    width: 100%;
    padding: 32px 0;
    margin: 0
}

@media(min-width:720px) {
    .games__section {
        padding: 40px 0 64px
    }
}

.games__header {
    display: flex;
    align-items: center;
    margin-bottom: 32px
}

@media(min-width:320px) {
    .games__header {
        flex-direction: column
    }
}

@media(min-width:1248px) {
    .games__header {
        margin-bottom: 40px
    }
}

.games__headline {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center
}

@media(min-width:960px) {
    .games__provider {
        display: none
    }
}

.games__catalog {
    width: 100%;
    padding: 0;
    margin: 0
}

.games__catalog .catalog__item:first-child {
    grid-column: auto!important;
    grid-row: auto!important
}

.games__catalog--jackpots {
    margin-top: 70px;
    margin-bottom: 100px
}

.games__filter {
    margin-bottom: 32px
}

@media(min-width:960px) {
    .games__filter {
        margin-bottom: 40px
    }
}

@media(min-width:1248px) {
    .games__filter {
        margin-bottom: 48px
    }
}

.games .menu-providers__content {
    top: calc(100% - 20px)
}

@media(max-width:1248px) {
    .games .menu-providers__content {
        width: calc(100vw - 50px);
        left: unset;
        right: 0
    }
}

@media(max-width:960px) {
    .games .menu-providers__content {
        width: calc(100vw - 30px)
    }
}

@media(min-width:320px) {
    .games .menu-providers__content {
        top: 0;
        left: 0;
        width: 100%;
        z-index: 101
    }
}

@media(min-width:960px) {
    .games .menu-providers__content {
        top: calc(100% - 20px)
    }
}

@media(min-width:960px) {
    .games .games-filter__cell--category,
    .games .games-filter__cell--search {
        display: flex
    }
}

.games .catalog__list {
    grid-template-columns: repeat(2, 1fr)
}

@media(min-width:720px) {
    .games .catalog__list {
        grid-template-columns: repeat(4, 1fr)
    }
}

@media(min-width:1248px) {
    .games .catalog__list {
        grid-template-columns: repeat(6, 1fr)
    }
}

@media(min-width:1632px) {
    .games .catalog__item:first-child {
        grid-column: span 2;
        grid-row: span 2
    }
}

.games-selected-provider {
    display: flex;
    align-items: center
}

@media(min-width:960px) {
    .games-selected-provider {
        margin-bottom: 25px
    }
}

.games-selected-provider__label {
    font-family: Montserrat, Arial, sans-serif;
    font-weight: 900;
    font-size: 20px;
    line-height: 26px
}

@media(min-width:960px) {
    .games-selected-provider__label {
        font-size: 24px
    }
}

.games-selected-provider__icon {
    max-height: 26px;
    margin-right: 10px
}