.support-page {
    display: flex;
    flex-direction: column;
    padding: 24px 16px
}

@media(min-width:720px) {
    .support-page {
        padding: 24px
    }
}

@media(min-width:1248px) {
    .support-page {
        padding: 40px 32px
    }
}

.support-page__title {
    margin-bottom: 16px
}

.support-page__description {
    margin-bottom: 24px
}

@media(min-width:1248px) {
    .support-page__description {
        margin-bottom: 40px
    }
}

.support-page__container {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 1
}

@media(min-width:1632px) {
    .support-page__container {
        max-width: 1020px
    }
}

.support-page__content {
    display: flex;
    flex-direction: column;
    width: 100%
}

@media(min-width:960px) {
    .support-page__content {
        flex-direction: row
    }
}

.support-page__form {
    margin-bottom: 48px;
    width: 100%
}

@media(min-width:1248px) {
    .support-page__form {
        max-width: 652px
    }
}

.support-page__items {
    display: flex;
    flex-direction: column;
    align-items: flex-start
}

@media(min-width:960px) {
    .support-page__items {
        margin-left: 40px
    }
}

@media(min-width:1248px) {
    .support-page__items {
        margin-left: 48px
    }
}

.support-page__item {
    margin-bottom: 32px;
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    align-items: flex-start
}

.support-page__item-img {
    margin-right: 20px;
    flex-shrink: 0
}

.support-page__item-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start
}

.support-page__item-title {
    margin-bottom: 4px
}

.support-page__item-text {
    font-weight: 700;
    color: #fff;
    margin-top: 8px
}

.support-page__item .text {
    font-size: 14px
}