.sign-in-page {
    position: relative;
    padding: 0 15px
}

.sign-in-page:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: 272px;
    width: 100%;
    background-image: linear-gradient(180deg, rgba(28, 30, 38, 0), #1c1e26 68.38%), url(../assets/bg.15086353.png);
    background-size: cover;
    z-index: -1
}

@media(min-width:1632px) {
    .sign-in-page:before {
        display: none
    }
}

@media(min-width:720px) {
    .sign-in-page {
        padding: 52px 32px 40px;
        border-radius: 20px
    }
}

.sign-in-page__menu {
    padding-top: 52px;
    margin-bottom: 42px
}

@media(min-width:720px) {
    .sign-in-page__menu {
        padding-top: 0
    }
}

@media(min-width:1632px) {
    .sign-in-page__menu {
        padding-top: 0;
        display: none
    }
}

.sign-in-page__header {
    font-weight: 900;
    text-align: left;
    margin-bottom: 24px
}

@media(min-width:1632px) {
    .sign-in-page__header {
        text-align: center
    }
}

.login-form.login-form {
    min-width: 176px;
    justify-content: center;
    align-items: center
}

.login-form__two-factor-errors {
    margin-bottom: 12px
}

.login-form__forgot-password-link-wrapper {
    width: 100%;
    text-align: right;
    margin: 12px 0 16px
}

.login-form .login-form__forgot-password-link {
    font-size: 14px;
    color: hsla(0, 0%, 100%, .65)
}

.login-form .login-form__forgot-password-link:hover {
    color: #fff
}

.login-form__footer {
    display: none;
    margin-top: 20px;
    text-align: center;
    color: hsla(0, 0%, 100%, .65)
}

@media(min-width:1632px) {
    .login-form__footer {
        display: block
    }
}

.login-form__footer .registration-dynamic-form__link {
    font-size: 16px;
    line-height: 24px;
    text-decoration: none;
    color: #33d672
}

.login-form__footer .registration-dynamic-form__link:hover {
    color: #5ddf8f
}