.lhnzQ {
    align-items: flex-end;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    margin-block-start: 8px;
    width: 100%;
}

.ikLPkb {
    display: flex;
    flex-wrap: wrap;
    margin: -8px;
    padding-block-start: 10px;
}

.hVAReK {
    color: rgb(255, 255, 255);
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    margin: 0px;
    margin-block-start: 24px;
}

.jPodWk {
    align-items: flex-end;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    margin-block-start: 8px;
    width: 100%;
}

.eDKWmR {
    -webkit-box-align: center;
    align-items: center;
    background: rgb(68, 71, 86);
    border-radius: 4px;
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 0%;
    margin-block-end: 10px;
    padding: 15px 20px;
    align-self: stretch;
}

.fhbxkc {
    -webkit-box-align: center;
    align-items: center;
    background: rgb(68, 71, 86);
    border-radius: 4px;
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 0%;
    margin-block-end: 10px;
    padding: 15px 20px;
    align-self: stretch;
}

.gaNReY {
    flex: 1 1 0%;
}

.ieepbn {
    border-block-start: 1px solid rgb(53, 54, 57);
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    margin-block-start: 10px;
    padding-block-start: 10px;
}

.iAEnGT {
    font-size: 16px;
    font-weight: 700;
    color: rgb(255, 255, 255);
    text-align: left;
}

.hRYZJR {
    margin-inline-start: 10px;
}

.cMhwVm {
    font-size: 12px;
    color: rgb(170, 170, 172);
    text-align: left;
    margin-inline-end: 15px;
    margin-block-end: 8px;
}

.ibwKCH {
    width: 45%;
    min-width: 150px;
}

.jpwidV {
    width: 100%;
    height: 4px;
    border-radius: 2px;
    overflow: hidden;
    background-color: rgb(192, 49, 40);
}

.jpwidV .progress-bar {
    border-radius: 2px;
    background-color: rgb(41, 121, 255);
}

.jrHbyV {
    display: flex;
    flex-direction: column;
}

.egHtzZ {
    color: rgb(3, 255, 120);
    font-size: 14px;
    font-weight: 400;
}

.eGuNiQ {
    padding-inline-start: 10px;
}

.koRqLQ {
    position: relative;
    height: 0px;
}

.IjpeF {
    display: none;
}

.gFlPhX {
    color: rgb(173, 173, 173);
    font-size: 14px;
    font-weight: 400;
}

.bSSBze {
    display: flex;
    flex-direction: column;
    -webkit-box-align: stretch;
    align-items: stretch;
    position: relative;
    margin-block-start: 12px;
}
.lkvxQx:first-child {
    margin-block-start: 0px;
}
.lkvxQx {
    color: rgb(173, 173, 173);
    font-size: 14px;
    font-weight: 400;
    margin: 10px 0px 12px;
    text-align: center;
    background: rgb(68, 71, 86);
    padding: 10px 20px;
    border-radius: 4px;
}

.hEzbsI {
    width: 100%;
    display: flex;
    flex-direction: column;
    opacity: 1;
}

.BTKFv {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.fvArrq + .sc-pYZzC {
    background-image: url(./promo.png);
    background-repeat: no-repeat;
    background-position: right bottom;
    margin: 12px 0px 0px;
}

.fvArrq {
    position: relative;
    width: 100%;
    background: rgb(68, 71, 86);
    color: rgb(129, 129, 131);
    padding: 20px;
    border-radius: 4px;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    overflow: hidden;
    height: auto;
    max-height: 100%;
    -webkit-box-flex: 1;
    flex-grow: 1;
}

.iDgZBz {
    text-transform: uppercase;
    color: rgb(170, 170, 172);
    font-size: 12px;
    font-weight: 700;
}

.hFCtia {
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-weight: 700;
    margin: 8px 0px;
    text-align: center;
}

.egHtzZ {
    color: rgb(3, 255, 120);
    font-size: 14px;
    font-weight: 400;
}

.hFCtia {
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-weight: 700;
    margin: 8px 0px;
    text-align: center;
}

.zvFNV {
    color: rgb(170, 170, 172);
    font-size: 14px;
    font-weight: 400;
    position: relative;
    width: 100%;
}

.fpUlcQ {
    position: relative;
    left: 8px;
    width: 40px;
    min-width: 40px;
    height: 40px;
    padding: 0px;
}

@media (min-width: 768px) {
    .fvArrq + .sc-pYZzC {
        margin: 0px 0px 0px 16px;
    }
    .fpUlcQ {
        width: 32px;
        min-width: 32px;
        height: 32px;
    }
    .BTKFv {
        flex-direction: row;
        width: calc(200% + 16px);
    }
    .hEzbsI {
        width: 50%;
        padding: 8px;
    }
    .lkvxQx {
        display: none;
    }
    .bSSBze {
        flex-flow: row wrap;
        margin: -8px;
        margin-block-start: 4px;
    }
    .IjpeF {
        display: block;
        position: absolute;
        inset-block-start: 16px;
        left: 0px;
    }
    .koRqLQ {
        height: 52px;
    }
    .ieepbn {
        border-block-start: unset;
        border-inline-start: 1px solid rgb(53, 54, 57);
        margin-block-start: 0px;
        width: auto;
        height: 46px;
        padding: 0px 0px 0px 22px;
    }
    .fhbxkc {
        flex: 2 1 0%;
        margin-block-end: 0px;
    }
    .eDKWmR {
        flex: 2 1 0%;
        margin-block-end: 0px;
    }
}

@media (min-width: 1200px) {
    .hEzbsI {
        width: 33.3333%;
        max-width: 328px;
    }
}