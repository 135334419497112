*,
:after,
:before {
    box-sizing: border-box;
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    /* -webkit-tap-highlight-color: rgba(0,0,0,0); */
}

body {
    background-color: rgb(37, 38, 48);
    overflow-x: hidden;
    scroll-behavior: smooth;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    /*background-color: #fff;*/
}

 ::-webkit-scrollbar {
    height: 0px;
    width: 6px;
}

 ::-webkit-scrollbar-thumb {
    background: rgb(74, 74, 75);
    border-radius: 4px;
}

 ::-webkit-scrollbar-track {
    background: transparent;
}

.dhpicN {
    overflow: hidden;
    margin-block-end: 24px;
    margin-inline: -12px;
    position: relative;
}

.hsEOVq {
    color: rgb(255, 255, 255);
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    margin: 0px;
}


.cTPtwP {
    -webkit-box-align: center;
    align-items: center;
    background-color: rgb(25, 28, 36);
    display: flex;
    height: 54px;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 0px 14px 0px 10px;
    position: fixed;
    top: 0px;
    width: 100vw;
    z-index: 1030;
}

.kzImXb {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    height: inherit;
    padding: 5px 0px;
}

.hzmonU {
    height: auto;
    margin-right: 16px;
    max-height: calc(44px);
    max-width: 100px;
    object-fit: contain;
}

.dCFDnZ {
    display: none;
}

.hxajKn {
    padding: 4px 8px;
    font-size: 14px;
    font-weight: 400;
    display: -webkit-box;
    white-space: normal;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}

.kzImXb {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    height: inherit;
    padding: 5px 0px;
}

.gEuIiR {
    color: rgb(3, 255, 120);
    font-weight: 700;
    background-color: transparent;
    padding: 0px;
    border: none;
    text-decoration: none;
    appearance: none;
}

.euRtzk.styled-button--sm {
    padding: 6px 12px;
}

.cusCDC {
    margin-right: 4px;
}

.euRtzk {
    color: rgb(53, 56, 69);
    font-size: 14px;
    font-weight: 700;
    background-color: rgb(3, 255, 120);
    border-color: rgb(3, 255, 120);
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
    padding: 9px 20px;
    min-width: 64px;
    display: block;
    text-align: center;
}

.fGKAcm {
    font-size: 16px;
    margin: 0px -12px 0px 0px;
    padding: 12px;
}

.gEuIiR {
    color: rgb(3, 255, 120);
    font-weight: 700;
    background-color: transparent;
    padding: 0px;
    border: none;
    text-decoration: none;
    appearance: none;
}

[class*=" icon-"],
[class^=icon-] {
    font-family: icomoon!important;
    speak: never;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.kzImXb {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    height: inherit;
    padding: 5px 0px;
}

.jtkiol {
    display: none;
    margin-right: 8px;
}

.dXJFwL {
    width: auto;
}

a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}

.kqUecW {
    background-color: rgb(25, 28, 36);
    width: 300px;
}

.cTCRYH {
    margin: 15px 20px 32px;
}

.clNTYs {
    margin-bottom: 15px;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

.gboDcw {
    -webkit-box-pack: start;
    justify-content: flex-start;
    display: flex;
}

.cuVunp {
    display: flex;
    font-size: 20px;
    padding: 7px;
    margin: auto 0px;
}

.fsDlzf {
    color: rgb(255, 255, 255);
}

.lgzisM {
    font-size: 14px;
    margin-bottom: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.jXsoYI {
    display: flex;
    margin-bottom: 10px;
    -webkit-box-align: baseline;
    align-items: baseline;
}

.dddoem {
    font-size: 32px;
    font-weight: 700;
    margin-right: 8px;
}

.cwcHPe {
    font-size: 16px;
}

.BQzfJ {
    width: 100%;
}

.bPuXMY {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.jZzMAV {
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-weight: 700;
}

.fKUCHC {
    color: rgb(170, 170, 172);
    font-size: 12px;
}

.fDLXsh {
    margin: 10px 0px;
}

.epYDpx {
    -webkit-box-align: center;
    align-items: center;
    box-sizing: border-box;
    color: rgb(255, 255, 255);
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-weight: 400;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 9px 20px;
    text-decoration: none;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    width: 100%;
}

.bennJD {
    display: flex;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    align-items: center;
}

.epYDpx i {
    font-size: 16px;
    margin-right: 20px;
}

.ZaJio {
    margin-right: 5px;
}

.epYDpx.active {
    background-color: rgb(53, 56, 69);
}

.lhycLQ {
    display: block;
    height: 100%;
    transform: translateX(100%);
    width: 300px;
}

.gHRhfp {
    position: fixed;
    inset: 0px;
    z-index: 1030;
    display: none;
}

.fkeTwg {
    display: block;
    box-sizing: border-box;
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 1030;
    width: 300px;
    transform: translateX(100%);
    transition: transform 0.2s ease-out 0s;
    overflow-x: hidden;
    background-color: rgb(26, 26, 30);
    box-shadow: rgb(37 38 48) -10px 0px 10px;
}

.kqUecW {
    background-color: rgb(25, 28, 36);
    width: 300px;
}

.gVWNsJ {
    -webkit-box-flex: 1;
    flex-grow: 1;
    padding-top: 54px;
    width: 100%;
}

.fEecXl {
    padding: 12px;
}

.eHYSPs {
    color: rgb(255, 255, 255);
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    margin: 0px;
}

.fSKWpA {
    overflow: hidden;
    margin-bottom: 24px;
    margin-left: -12px;
    margin-right: -12px;
    position: relative;
}

.buYgjE {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
}

.bBExAN.active {
    color: rgb(255, 255, 255);
    border-bottom-width: 2px;
    border-bottom-color: rgb(192, 49, 40);
}

.bBExAN {
    color: rgb(170, 170, 172);
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 15px 0px;
    border-bottom: 1px solid rgb(74, 74, 75);
    white-space: nowrap;
}

.cSXqrT {
    width: 24px;
    min-width: 24px;
    padding: 15px 0px;
    border-bottom: 1px solid rgb(74, 74, 75);
}

.buYgjE::before,
.buYgjE::after {
    content: "";
    display: block;
    width: 12px;
    min-width: 12px;
}

.deqtnH {
    display: flex;
    flex-direction: column;
    max-width: 768px;
}

.eCayqY {
    width: 100%;
}

.ftqKqw {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.jCAIhp {
    display: flex;
    position: relative;
    flex-direction: column;
    padding-bottom: 20px;
}

.jAvigo {
    position: relative;
    flex-direction: column;
    width: fit-content;
}

.cgjEkh {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 100%;
}

.laDKIn {
    display: flex;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    align-items: center;
    white-space: pre;
    padding-right: 10px;
    color: rgb(170, 170, 172);
    font-weight: 700;
}

.kcNpsK {
    width: 100%;
}

.esYTne {
    cursor: text;
    border-radius: 8px;
    background: rgb(53, 56, 69);
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    padding: 0px 12px;
    color: rgb(255, 255, 255);
}

.eOAiE:disabled {
    color: rgb(255, 255, 255);
    -webkit-text-fill-color: rgb(255, 255, 255);
    background-color: rgb(53, 56, 69);
}

.eOAiE,
.eOAiE:focus {
    outline: none;
    background-color: transparent;
    box-shadow: none;
    color: rgb(255, 255, 255);
}

.eOAiE {
    width: 100%;
    min-width: 0px;
    height: 48px;
    font-weight: 400;
    padding: 8px 0px;
    border: none;
}

.ggANzV {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 40px 0px 0px;
}

.ffJLJW {
    position: relative;
    display: flex;
    flex-direction: column;
}

.ctixlE {
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    color: rgb(255, 255, 255);
    opacity: 0.65;
    margin-bottom: 23px;
}

.iEtcgz {
    margin-bottom: 21px;
}

.iZizXh {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

.fyKYpg {
    color: rgb(255, 255, 255);
    font-weight: 400;
    font-size: 16px;
    margin: 0px;
    white-space: nowrap;
    cursor: pointer;
}

.inEdMe {
    margin-left: 10px;
}

.hFtwTf {
    padding-left: 30px;
    overflow: hidden;
}

.custom-switch {
    padding-left: 2.25rem;
}

.custom-control {
    position: relative;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
}

.bCCWCd {
    -webkit-box-align: center;
    align-items: center;
    background-color: rgb(25, 28, 36);
    display: flex;
    height: 54px;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 0px 14px 0px 10px;
    position: fixed;
    inset-block-start: 0px;
    width: 100vw;
    z-index: 1030;
}

.cyRGxT {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    height: inherit;
    padding: 5px 0px;
}

.jIpIlW {
    background-color: rgb(25, 28, 36);
    width: 300px;
}
.cqsFgi {
    margin: 15px 20px 32px;
}
.kvpgjy {
    margin-block-end: 15px;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
}
.iOWyev {
    color: rgb(255, 255, 255);
}
.jzOxXE {
    font-size: 14px;
    margin-block-end: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.iqIHyv {
    display: none;
    margin-inline-end: 8px;
}
.gwNWYc.styled-button--sm {
    padding: 6px 12px;
}

.hxdFHN {
    display: none;
}
.eFMCar {
    color: rgb(3, 255, 120);
    font-weight: 700;
    background-color: transparent;
    padding: 0px;
    border: none;
    text-decoration: none;
    appearance: none;
}
.dxrHjA {
    padding: 4px 8px;
    font-size: 14px;
    font-weight: 400;
    display: -webkit-box;
    white-space: normal;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}
.VkQBT {
    display: flex;
    -webkit-box-align: baseline;
    align-items: baseline;
    gap: 8px;
    margin-block-end: 10px;
}

.PNtMq i {
    font-size: 16px;
    margin-inline-end: 20px;
}
.PNtMq.active {
    background-color: rgb(53, 56, 69);
}

.gZgDfc {
    display: flex;
    -webkit-box-align: baseline;
    align-items: baseline;
}
.bOoBAE {
    font-size: 32px;
    font-weight: 700;
    margin-inline-end: 8px;
}
.ktHqpt {
    font-size: 16px;
}
.AfVto {
    display: none;
}
.eftFOV {
    width: 100%;
}
.cTTObP {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}
.ejgoSR {
    color: rgb(170, 170, 172);
    font-size: 12px;
}
.jNhATa {
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-weight: 700;
}
.epsrXo {
    width: 100%;
    margin-block-start: 10px;
}
.jcWMUb {
    width: 100%;
}
.crymUo {
    width: auto;
}
/*.jXbvUm {
    border-radius: 50%;
    font-size: 22px;
    padding: 0px 5px;
    min-width: auto;
}*/

.yTaSA {
    position: relative;
    width: 100%;
    height: 0px;
}

.kRxexy {
    background-color: rgb(68, 71, 86);
    border-color: rgb(68, 71, 86);
    border-style: solid;
    border-width: 1px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    -webkit-box-pack: start;
    justify-content: flex-start;
    line-height: 20px;
    overflow-y: visible;
    right: 0px;
    top: 10px;
    width: 200px;
}

.eupXoq {
    position: absolute;
    z-index: 1000;
    width: 100%;
    border-radius: 8px;
    inset-block-start: 5px;
    min-width: 0px;
    max-height: 250px;
    overflow-y: auto;
    background-color: rgb(68, 71, 86);
}

.bIZXlm:first-of-type {
    margin-block-start: 12px;
}

.bIZXlm {
    min-width: 0px;
    width: 100%;
}

.foqpsW {
    width: auto;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    pointer-events: none;
}

.gZOZQz {
    margin: 14px 0px;
}

.ifBgbV {
    height: 1px;
    background-color: rgb(74, 74, 75);
}

.eZopsr {
    padding: 12px 24px;
}

.jhvISy {
    color: rgb(255, 255, 255);
    cursor: pointer;
    overflow: hidden;
    padding: 8px 12px;
    white-space: normal;
    font-weight: 400;
}

.eZopsr:last-of-type {
    margin-block-end: 12px;
}

.jhvISy:last-of-type {
    margin-block-end: 10px;
}

.eZopsr {
    padding: 12px 24px;
}

.jhvISy {
    color: rgb(255, 255, 255);
    cursor: pointer;
    overflow: hidden;
    padding: 8px 12px;
    white-space: normal;
    font-weight: 400;
}

.bIZXlm .sc-fznOgF {
    padding: 12px 24px;
    width: 100%;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

.gUKcSs {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    pointer-events: all;
}

.fUWEas {
    color: rgb(41, 121, 255);
    font-weight: 700;
    background-color: transparent;
    padding: 0px;
    border: none;
    text-decoration: none;
    appearance: none;
}

.gUKcSs .sc-fzoKki {
    margin-inline-start: 6px;
    color: inherit;
}

.kgdacT {
    font-size: 6px;
    color: rgb(74, 74, 75);
    cursor: inherit;
}

.wPaCf {
    padding: 12px;
}
.XfiFP {
    color: rgb(255, 255, 255);
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    margin: 0px;
}

.jOdohT {
    display: flex;
    flex-direction: column;
    -webkit-box-align: stretch;
    align-items: stretch;
}

.DwQUP {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    flex-direction: column;
    gap: 0px 16px;
}


.jQYxiV {
    color: rgb(74, 74, 75);
    font-size: 14px;
    font-weight: 400;
}

.fexoHx {
    overflow: hidden;
    margin-block-end: 24px;
    margin-inline: -12px;
    position: relative;
}
.fexoHx::before, .fexoHx::after {
    content: "";
    position: absolute;
    inset-block: 0px;
    transition: width 300ms linear 0s;
    pointer-events: none;
}

.huymOp {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
}
.huymOp::before, .huymOp::after {
    content: "";
    display: block;
    width: 12px;
    min-width: 12px;
}

.fmvNGy {
    color: rgb(170, 170, 172);
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 15px 0px;
    border-block-end: 1px solid rgb(74, 74, 75);
    white-space: nowrap;
}
.fmvNGy.active {
    color: rgb(255, 255, 255);
    border-block-end-width: 2px;
    border-block-end-color: rgb(3, 255, 120);
}

.iITJLX {
    width: 24px;
    min-width: 24px;
    padding: 15px 0px;
    border-block-end: 1px solid rgb(74, 74, 75);
}
.iITJLX:last-child {
    width: 100%;
    min-width: auto;
}
.gMRrhS {
    display: block;
    height: 100%;
    transform: translateX(100%);
    width: 300px;
}
.NgPCC {
    position: fixed;
    inset: 0px;
    z-index: 1030;
    display: none;
}
.gLGlek {
    display: block;
    box-sizing: border-box;
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 1030;
    width: 300px;
    transform: translateX(100%);
    transition: transform 0.2s ease-out 0s;
    overflow-x: hidden;
    background-color: rgb(26, 26, 30);
    box-shadow: rgb(37 38 48) -10px 0px 10px;
}
.STczF {
    -webkit-box-flex: 1;
    flex-grow: 1;
    padding-block-start: 54px;
    width: 100%;
}
.dKXoRo {
    color: rgb(129, 129, 131);
}
.oIXxo {
    position: relative;
    width: 100%;
    height: 0px;
}
.fSLeWe {
    background-color: rgb(68, 71, 86);
    border-color: rgb(68, 71, 86);
    border-style: solid;
    border-width: 1px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    -webkit-box-pack: start;
    justify-content: flex-start;
    line-height: 20px;
    overflow-y: visible;
    right: 0px;
    top: 10px;
    width: 200px;
}
.ileypS {
    position: absolute;
    z-index: 1000;
    width: 100%;
    border-radius: 8px;
    inset-block-start: 5px;
    min-width: 0px;
    max-height: 250px;
    overflow-y: auto;
    background-color: rgb(68, 71, 86);
}
.iVqJhS {
    width: auto;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    pointer-events: none;
}
.kqzcYq {
    min-width: 0px;
    width: 100%;
}
.kqzcYq:first-of-type {
    margin-block-start: 12px;
}
.kqzcYq .sc-fznOgF {
    padding: 12px 24px;
    width: 100%;
    -webkit-box-pack: justify;
    justify-content: space-between;
}
.fKOEnE {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    pointer-events: all;
}
.kKGPbU {
    color: rgb(41, 121, 255);
    font-weight: 700;
    background-color: transparent;
    padding: 0px;
    border: none;
    text-decoration: none;
    appearance: none;
}
.fKOEnE .sc-fzoKki {
    margin-inline-start: 6px;
    color: inherit;
}
.LEObH {
    font-size: 6px;
    color: rgb(74, 74, 75);
    cursor: inherit;
}
.gulvWb {
    margin: 14px 0px;
}
.ezzqlh {
    height: 1px;
    background-color: rgb(74, 74, 75);
}
.fEGcwr {
    padding: 12px 24px;
}
.cxAFNm {
    color: rgb(255, 255, 255);
    cursor: pointer;
    overflow: hidden;
    padding: 8px 12px;
    white-space: normal;
    font-weight: 400;
}
.fEGcwr:last-of-type {
    margin-block-end: 12px;
}
.cxAFNm:last-of-type {
    margin-block-end: 10px;
}
.fEGcwr {
    padding: 12px 24px;
}
.cxAFNm {
    color: rgb(255, 255, 255);
    cursor: pointer;
    overflow: hidden;
    padding: 8px 12px;
    white-space: normal;
    font-weight: 400;
}
.gSaNTA {
    width: 100vw;
}

.kvpgjy {
    margin-block-end: 15px;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
}
.iOWyev {
    color: rgb(255, 255, 255);
}
.jzOxXE {
    font-size: 14px;
    margin-block-end: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.VkQBT {
    display: flex;
    -webkit-box-align: baseline;
    align-items: baseline;
    gap: 8px;
    margin-block-end: 10px;
}
.gZgDfc {
    display: flex;
    -webkit-box-align: baseline;
    align-items: baseline;
}
.bOoBAE {
    font-size: 32px;
    font-weight: 700;
    margin-inline-end: 8px;
}
.ktHqpt {
    font-size: 16px;
}
.hVRHtV {
    display: flex;
    flex-direction: column;
    -webkit-box-align: stretch;
    align-items: stretch;
}
.hEzbsI {
    width: 100%;
    display: flex;
    flex-direction: column;
    opacity: 1;
}
.kpHTMc {
    -webkit-box-flex: 1;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    background: rgb(68, 71, 86);
    color: rgb(129, 129, 131);
    padding: 20px;
    border-radius: 4px;
    font-size: 12px;
    border-style: solid;
    border-width: 2px;
    border-color: rgb(3, 255, 120);
}
.iwgITg {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    cursor: pointer;
}
.jNctbv {
    font-size: 16px;
    font-weight: 700;
    color: rgb(170, 170, 172);
}
.kfNapK label {
    font-size: 14px;
    font-weight: 400;
    text-align: right;
    margin-inline-end: 10px;
}

.gwNWYc {
    color: rgb(53, 56, 69);
    font-size: 14px;
    font-weight: 700;
    background-color: rgb(3, 255, 120);
    border-color: rgb(3, 255, 120);
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
    padding: 9px 20px;
    min-width: 64px;
    display: block;
    text-align: center;
}
.NCsjD {
    margin-block-start: 24px;
    width: 100%;
}
.bdwbES {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
}
.cZbeF {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}
.jNctbv {
    font-size: 16px;
    font-weight: 700;
    color: rgb(170, 170, 172);
}
.fvBYkk {
    font-size: 32px;
    font-weight: 700;
    color: rgb(255, 255, 255);
}
.juycGB {
    color: rgb(170, 170, 172);
    font-size: 24px;
    margin-inline-start: 8px;
}
.dVCcaN {
    width: 100%;
}
.fjYmBJ {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}
.cZdMdl {
    color: rgb(255, 255, 255);
    font-weight: 700;
    font-size: 14px;
}
.cpJTLT {
    font-size: 14px;
}

.igMKMI {
    color: rgb(129, 129, 131);
}

.dhpicN::before {
    width: 0px;
    left: 0px;
    background-image: linear-gradient(90deg, rgb(37, 38, 48) 0%, transparent 100%);
}

.dhpicN::after {
    width: 0px;
    right: 0px;
    background-image: linear-gradient(270deg, rgb(37, 38, 48) 0%, transparent 100%);
}

.iaxKdj {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
}

.eUpOXi {
    color: rgb(170, 170, 172);
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 15px 0px;
    border-block-end: 1px solid rgb(74, 74, 75);
    white-space: nowrap;
}

.eUpOXi.active {
    color: rgb(255, 255, 255);
    border-block-end-width: 2px;
    border-block-end-color: rgb(3, 255, 120);
}

.ccNeVQ {
    width: 24px;
    min-width: 24px;
    padding: 15px 0px;
    border-block-end: 1px solid rgb(74, 74, 75);
}

.hFgneF {
    display: flex;
    flex-direction: column;
    width: 100%;
}


.dJstnP {
    display: flex;
    position: relative;
    flex-flow: row wrap;
    padding-block-end: 20px;
    row-gap: 12px;
}
.gAazBt {
    position: relative;
    flex-direction: column;
    width: fit-content;
}

.fqnRrA {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 100%;
}

.gLwzZr {
    display: flex;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    align-items: center;
    white-space: pre;
    padding-inline-end: 10px;
    color: rgb(170, 170, 172);
    font-weight: 700;
}
.jlQgbc {
    margin-inline-start: auto;
    padding: 0px;
}

.ccdkcr {
    width: 100%;
}

.dfwHgI {
    font-size: 16px;
    margin: 0px -12px 0px 0px;
    padding: 12px;
}

.wMpnx {
    cursor: text;
    border-radius: 8px;
    background: rgb(53, 56, 69);
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    padding: 0px 12px;
    color: rgb(255, 255, 255);
}

.cbYJr {
    width: 100%;
    min-width: 0px;
    height: 48px;
    font-weight: 400;
    padding: 8px 0px;
    border: none;
}

.cbYJr, .cbYJr:focus {
    outline: none;
    background-color: transparent;
    box-shadow: none;
    color: rgb(255, 255, 255);
}

.cbYJr:disabled {
    color: rgb(255, 255, 255);
    -webkit-text-fill-color: rgb(255, 255, 255);
    background-color: rgb(53, 56, 69);
}

.jpiuPt {
    position: absolute;
    inset-block: 0px;
    inset-inline: 0px;
    background: transparent;
    pointer-events: none;
    border-radius: 8px;
    border: none;
}

.fILiwf {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 40px 0px 0px;
}

.dCPVan {
    position: relative;
    display: flex;
    flex-direction: column;
}
.oAiyL {
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    color: rgb(255, 255, 255);
    opacity: 0.65;
    margin-block-end: 23px;
}

.fnYpQw {
    color: rgb(255, 255, 255);
    font-weight: 400;
    font-size: 16px;
    margin: 0px;
    white-space: nowrap;
    cursor: pointer;
}

.inCcPL {
    margin-inline-start: 10px;
}

.cnVwsg {
    padding-inline-start: 30px;
    overflow: hidden;
}

.cDPkGX {
    left: 8px;
    cursor: pointer;
}


.gjRtmf {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

.dMsCgO {
    margin-block-end: 21px;
}

.dMsCgO > label {
    white-space: pre-wrap;
    flex: 1 1 0%;
    overflow: hidden;
    text-overflow: ellipsis;
}


.iaxKdj::before, .iaxKdj::after {
    content: "";
    display: block;
    width: 12px;
    min-width: 12px;
}

.gwNWYc:disabled {
    opacity: 0.5;
}

.icon {
    font-family: icomoon!important;
    speak: never;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.bdYFba {
    cursor: text;
    border-radius: 8px;
    background: transparent;
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    padding: 0px 12px;
    color: rgb(255, 255, 255);
}

.hNONtA {
    position: absolute;
    inset-block: 0px;
    inset-inline: 0px;
    background: transparent;
    pointer-events: none;
    border-radius: 8px;
    border: 1px solid rgb(53, 56, 69);
}


.htmcOf {
    display: flex;
    margin-inline-start: 12px;
}

.lfeDbm {
    display: flex;
}

.lfeDbm i {
    margin: auto;
}

.bCkCTr {
    width: 100%;
    display: flex;
    flex-direction: column;
    opacity: 1;
}
img, svg {
    vertical-align: middle;
}

@media (min-width: 768px) {
    .huymOp::before, .huymOp::after {
        width: 24px;
        min-width: 24px;
    }
    .bCkCTr {
        width: 50%;
        padding: 8px;
    }
    .jOdohT {
        flex-flow: row wrap;
        margin: -8px;
    }
    .fILiwf {
        margin: 24px 0px 0px;
    }
    .hSorki {
        align-self: flex-end;
        margin-block-start: 12px;
    }
    .iaxKdj::before, .iaxKdj::after {
        width: 24px;
        min-width: 24px;
    }
    .cbYJr {
        height: 40px;
    }
    .ccdkcr {
        max-width: 360px;
        -webkit-box-flex: 2;
        flex-grow: 2;
    }
    .jlQgbc {
        margin-inline-end: 16px;
    }
    .gAazBt {
        flex-direction: row;
        margin-block-end: 0px;
        flex-shrink: 2;
    }
    .dJstnP {
        -webkit-box-pack: justify;
        justify-content: space-between;
        flex-wrap: nowrap;
        row-gap: unset;
    }
    .dhpicN {
        margin-inline: -24px;
    }
    .DwQUP {
        flex-direction: row;
    }
    .hEzbsI {
        width: 50%;
        padding: 8px;
    }
    .hVRHtV {
        flex-flow: row wrap;
        margin: -8px;
    }
    .fexoHx {
        margin-inline: -24px;
    }
    .wPaCf {
        padding: 24px;
    }
    .hxdFHN {
        display: initial;
        border-inline-start: 2px solid;
        border-color: rgba(255, 255, 255, 0.08);
        height: 20px;
        margin: 0px 10px;
    }
    .cTPtwP {
        padding: 0px 24px 0px 16px;
    }
    .hzmonU {
        margin-right: 0px;
    }
    .dCFDnZ {
        display: initial;
        border-left: 2px solid rgba(255, 255, 255, 0.08);
        border-top-color: rgba(255, 255, 255, 0.08);
        border-right-color: rgba(255, 255, 255, 0.08);
        border-bottom-color: rgba(255, 255, 255, 0.08);
        height: 20px;
        margin: 0px 10px;
    }
    .dCFDnZ {
        display: initial;
        border-left: 2px solid rgba(255, 255, 255, 0.08);
        border-top-color: rgba(255, 255, 255, 0.08);
        border-right-color: rgba(255, 255, 255, 0.08);
        border-bottom-color: rgba(255, 255, 255, 0.08);
        height: 20px;
        margin: 0px 10px;
    }
    .fEecXl {
        padding: 24px;
    }
    .fSKWpA {
        margin-left: -24px;
        margin-right: -24px;
    }
    .buYgjE::before,
    .buYgjE::after {
        width: 24px;
        min-width: 24px;
    }
    .jCAIhp {
        flex-direction: row;
        -webkit-box-pack: justify;
        justify-content: space-between;
    }
    .jAvigo {
        flex-direction: row;
        margin-bottom: 0px;
        flex-shrink: 2;
    }
    .kcNpsK {
        max-width: 360px;
        -webkit-box-flex: 2;
        flex-grow: 2;
    }
    .eOAiE {
        height: 40px;
    }
    .ggANzV {
        margin: 24px 0px 0px;
    }
    .bCCWCd {
        padding: 0px 24px 0px 16px;
    }
}

@media (min-width: 1200px) {
    .bCkCTr {
        width: 33.3333%;
        max-width: 328px;
    }
    .iCntqh {
        max-width: 1300px;
    }
    .fILiwf {
        margin: 0px 0px 0px 40px;
        max-width: 276px;
        flex-shrink: 2;
    }
    .fjBVSK {
        max-width: 980px;
    }
    .hEzbsI {
        width: 33.3333%;
        max-width: 328px;
    }
    .jIfkRp {
        max-width: 980px;
    }
    .jzOxXE {
        font-size: 20px;
    }
    .kvpgjy {
        display: none;
    }
    .cqsFgi {
        margin-block-start: 30px;
    }
    .jIpIlW {
        inset-block: 60px 0px;
        left: 0px;
        min-width: 270px;
        overflow: hidden auto;
        position: fixed;
        width: 270px;
        z-index: 1025;
    }
    .gSaNTA {
        display: flex;
    }
    .STczF {
        padding-block-start: 60px;
        padding-inline-start: 270px;
    }
    .gMRrhS {
        display: none;
    }
    .ezOWoG {
        max-width: 1300px;
    }
    .epsrXo {
        margin-block-start: 20px;
    }
    .AfVto {
        display: flex;
    }
    .iqIHyv {
        display: initial;
    }
    .jzOxXE {
        font-size: 20px;
    }
    .kvpgjy {
        display: none;
    }
    .cTPtwP {
        height: 60px;
    }
    .hzmonU {
        max-width: 200px;
        max-height: calc(50px);
    }
    .eZaagY {
        display: none;
    }
    .ljlLCW {
        display: block;
    }
    .jtkiol {
        display: initial;
    }
    .ljlLCW {
        display: block;
    }
    .kqUecW {
        bottom: 0px;
        left: 0px;
        min-width: 270px;
        overflow: hidden auto;
        position: fixed;
        top: 60px;
        width: 270px;
        z-index: 1025;
    }
    .cTCRYH {
        margin-top: 30px;
    }
    .clNTYs {
        display: none;
    }
    .cuVunp {
        display: none;
    }
    .lgzisM {
        font-size: 20px;
    }
    .dkQEAA {
        display: flex;
    }
    .eMTTQA {
        margin-top: 20px;
    }
    .lhycLQ {
        display: none;
    }
    .kqUecW {
        bottom: 0px;
        left: 0px;
        min-width: 270px;
        overflow: hidden auto;
        position: fixed;
        top: 60px;
        width: 270px;
        z-index: 1025;
    }
    .gVWNsJ {
        padding-top: 60px;
        padding-left: 270px;
    }
    .dNGVbd {
        max-width: 980px;
    }
    .deqtnH {
        flex-direction: row;
    }
    .eCayqY {
        max-width: 640px;
    }
    .ggANzV {
        margin: 0px 0px 0px 40px;
        max-width: 276px;
        flex-shrink: 2;
    }
    .bCCWCd {
        height: 60px;
    }
    .gSaNTA {
        display: flex;
    }
}


