.vip,
.vip>* {
    z-index: 1
}

.vip__banner {
    position: relative;
    text-align: center;
    padding: 32px 0 50px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover
}

.vip__banner-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    -o-object-fit: cover;
    object-fit: cover;
    z-index: -1
}

.vip__banner-img--desktop {
    display: none
}

@media(min-width:720px) {
    .vip__banner-img--mobile {
        display: none
    }
    .vip__banner-img--desktop {
        display: block
    }
}

@media(min-width:960px) {
    .vip__banner {
        padding: 38px 0 52px
    }
}

@media(min-width:1248px) {
    .vip__banner {
        padding: 80px 0 86px
    }
}

@media(min-width:1632px) {
    .vip__banner {
        padding: 88px 0 102px
    }
}

.vip__banner-new {
    margin-bottom: 8px
}

.vip__banner-heading {
    display: block;
    max-width: 306px;
    margin: 0 auto 44px
}

@media(min-width:720px) {
    .vip__banner-heading {
        max-width: 554px;
        margin-bottom: 52px
    }
}

@media(min-width:1248px) {
    .vip__banner-heading {
        max-width: 680px;
        margin-bottom: 64px
    }
}

@media(min-width:1632px) {
    .vip__banner-heading {
        max-width: 776px
    }
}

.vip__banner-timer-text {
    margin-bottom: 12px;
    color: #fff
}

@media(min-width:720px) {
    .vip__banner-timer-text {
        margin-bottom: 16px
    }
}

.vip__banner-timer {
    margin: 0 auto
}

.vip__loyalty p {
    margin: 20px 0;
    padding-bottom: 0!important
}

.vip__loyalty ul {
    color: hsla(0, 0%, 100%, .65)
}

@media(max-width:375px) {
    .vip__loyalty ul {
        font-size: 14px;
        line-height: 20px
    }
}

.vip__loyalty li {
    margin-top: 10px
}

.vip__content {
    position: relative;
    padding-top: 32px;
    font-size: 16px
}

@media(min-width:1248px) {
    .vip__content {
        padding-top: 72px
    }
}

.vip__content:before {
    position: absolute;
    content: "";
    top: 0;
    width: 100%;
    height: 680px;
    background-image: /*url(url(../assets/background.93776600.png);*/
    url('');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    z-index: 0
}

@media(min-width:720px) {
    .vip__content:before {
        top: -50px;
        height: 720px
    }
}

@media(min-width:1248px) {
    .vip__content:before {
        height: 820px
    }
}

.vip__content-inner {
    display: flex!important;
    flex-direction: column;
    z-index: 1
}

.vip__content-inner>* {
    z-index: 1
}

.vip__content-info {
    margin-bottom: 8px
}

@media(min-width:1248px) {
    .vip__content-info {
        max-width: 776px;
        text-align: center;
        margin: 0 auto 8px
    }
}

.vip__content-rules {
    display: inline-block;
    color: #fff
}

.vip__content-rules-wrapper {
    margin-bottom: 32px
}

@media(min-width:1248px) {
    .vip__content-rules-wrapper {
        text-align: center;
        margin-bottom: 80px
    }
}

.vip__loyalty {
    margin-top: 100px;
    color: #fff
}

.vip__loyalty h6 {
    margin-left: 24px;
    font-size: 20px;
    font-weight: 900
}

.vip__loyalty p {
    margin: 24px;
    font-size: 16px
}

.vip__loyalty li {
    margin: 0 0 24px 50px;
    font-size: 16px;
    color: hsla(0, 0%, 100%, .65)
}