::-webkit-scrollbar {
    height: 0px;
    width: 6px;
}

::-webkit-scrollbar-thumb {
    background: rgb(74, 74, 75);
    border-radius: 4px;
}

::-webkit-scrollbar-track {
    background: transparent;
}

body {
    font-family: Roboto, sans-serif;
    background-color: rgb(37, 38, 48);
    overflow-x: hidden;
    scroll-behavior: smooth;
}

@media (min-width: 1200px) {
    .gsYpZY {
        height: 60px;
    }
}

@media (min-width: 768px) {
    .gsYpZY {
        padding: 0px 24px 0px 16px;
    }
}

.klFZGY {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    height: inherit;
    padding: 5px 0px;
}

.gsYpZY {
    -webkit-box-align: center;
    align-items: center;
    background-color: rgb(25, 28, 36);
    display: flex;
    height: 54px;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 0px 14px 0px 10px;
    position: fixed;
    top: 0px;
    width: 100vw;
    z-index: 1030;
}

.gEuIiR {
    color: rgb(3, 255, 120);
    font-weight: 700;
    background-color: transparent;
    padding: 0px;
    border: none;
    text-decoration: none;
    appearance: none;
}

@media (min-width: 1200px) {
    .dJJYFb {
        max-width: 200px;
        max-height: calc(50px);
    }
}

@media (min-width: 768px) {
    .dJJYFb {
        margin-right: 0px;
    }
}

.dJJYFb {
    height: auto;
    margin-right: 16px;
    max-height: calc(44px);
    max-width: 100px;
    object-fit: contain;
}

@media (min-width: 768px) {
    .krnhMh {
        display: initial;
        border-left: 2px solid rgba(255, 255, 255, 0.08);
        border-top-color: rgba(255, 255, 255, 0.08);
        border-right-color: rgba(255, 255, 255, 0.08);
        border-bottom-color: rgba(255, 255, 255, 0.08);
        height: 20px;
        margin: 0px 10px;
    }
}

.krnhMh {
    display: none;
}

.iDaPVT {
    padding: 4px 8px;
    font-size: 14px;
    font-weight: 400;
    display: -webkit-box;
    white-space: normal;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}

@media (min-width: 1200px) {
    .ljlLCW {
        display: none;
    }
}

.ljlLCW {
    display: block;
}

@media (min-width: 1200px) {
    .eZaagY {
        display: none;
    }
}

.eZaagY {
    display: none;
}

.bXLOkd.styled-button--sm {
    padding: 6px 12px;
}

.ZRMIk {
    margin-right: 4px;
}

.bXLOkd {
    color: rgb(53, 56, 69);
    font-size: 14px;
    font-weight: 700;
    background-color: rgb(3, 255, 120);
    border-color: rgb(3, 255, 120);
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
    padding: 9px 20px;
    min-width: 64px;
    display: block;
    text-align: center;
}

.gcNeKl {
    font-size: 16px;
    margin: 0px -12px 0px 0px;
    padding: 12px;
}

.gEuIiR {
    color: rgb(3, 255, 120);
    font-weight: 700;
    background-color: transparent;
    padding: 0px;
    border: none;
    text-decoration: none;
    appearance: none;
}

@media (min-width: 1200px) {
    .kxIaBf {
        display: flex;
    }
}

.kxIaBf {
    width: 100vw;
}

@media (min-width: 1200px) {
    .kqUecW {
        bottom: 0px;
        left: 0px;
        min-width: 270px;
        overflow: hidden auto;
        position: fixed;
        top: 60px;
        width: 270px;
        z-index: 1025;
    }
}

.kqUecW {
    background-color: rgb(25, 28, 36);
    width: 300px;
}

@media (min-width: 1200px) {
    .cTCRYH {
        margin-top: 30px;
    }
}

.cTCRYH {
    margin: 15px 20px 32px;
}

@media (min-width: 1200px) {
    .clNTYs {
        display: none;
    }
}

.clNTYs {
    margin-bottom: 15px;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

.gboDcw {
    -webkit-box-pack: start;
    justify-content: flex-start;
    display: flex;
}

@media (min-width: 1200px) {
    .cuVunp {
        display: none;
    }
}

.cuVunp {
    display: flex;
    font-size: 20px;
    padding: 7px;
    margin: auto 0px;
}

.fsDlzf {
    color: rgb(255, 255, 255);
}

@media (min-width: 1200px) {
    .lgzisM {
        font-size: 20px;
    }
}

.lgzisM {
    font-size: 14px;
    margin-bottom: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.wAEHL {
    display: flex;
    -webkit-box-align: baseline;
    align-items: baseline;
    gap: 8px;
    margin-bottom: 10px;
}

.bUcTJl {
    display: flex;
    -webkit-box-align: baseline;
    align-items: baseline;
}

.dddoem {
    font-size: 32px;
    font-weight: 700;
    margin-right: 8px;
}

.cwcHPe {
    font-size: 16px;
}

@media (min-width: 1200px) {
    .dkQEAA {
        display: flex;
    }
}

.dkQEAA {
    display: block;
}

.BQzfJ {
    width: 100%;
}

.bPuXMY {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.jZzMAV {
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-weight: 700;
}

.fKUCHC {
    color: rgb(170, 170, 172);
    font-size: 12px;
}

.BQzfJ {
    width: 100%;
}

@media (min-width: 1200px) {
    .eMTTQA {
        margin-top: 20px;
    }
}

.eMTTQA {
    width: 100%;
    margin-top: 10px;
}

.fDLXsh {
    margin: 10px 0px;
}

.epYDpx {
    -webkit-box-align: center;
    align-items: center;
    box-sizing: border-box;
    color: rgb(255, 255, 255);
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-weight: 400;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 9px 20px;
    text-decoration: none;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    width: 100%;
}

.bennJD {
    display: flex;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    align-items: center;
}

.ZaJio {
    margin-right: 5px;
}

.epYDpx.active {
    background-color: rgb(53, 56, 69);
}

.lecdpJ {
    height: 1px;
    background-color: rgb(74, 74, 75);
}

.bKSHje {
    width: 100%;
}

.hodZqw {
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
}

@media (min-width: 1200px) {
    .lhycLQ {
        display: none;
    }
}

.lhycLQ {
    display: block;
    height: 100%;
    transform: translateX(100%);
    width: 300px;
}

.gHRhfp {
    position: fixed;
    inset: 0px;
    z-index: 1030;
    display: none;
}

.fkeTwg {
    display: block;
    box-sizing: border-box;
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 1030;
    width: 300px;
    transform: translateX(100%);
    transition: transform 0.2s ease-out 0s;
    overflow-x: hidden;
    background-color: rgb(26, 26, 30);
    box-shadow: rgb(37 38 48) -10px 0px 10px;
}

@media (min-width: 1200px) {
    .gVWNsJ {
        padding-top: 60px;
        padding-left: 270px;
    }
}

.gVWNsJ {
    -webkit-box-flex: 1;
    flex-grow: 1;
    padding-top: 54px;
    width: 100%;
}

@media (min-width: 768px) {
    .fEecXl {
        padding: 24px;
    }
}

.fEecXl {
    padding: 12px;
}

.kbehjG {
    color: rgb(255, 255, 255);
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    margin: 0px;
}

@media (min-width: 768px) {
    .dEZpeU {
        margin-left: -24px;
        margin-right: -24px;
    }
}

.dEZpeU {
    overflow: hidden;
    margin-bottom: 24px;
    margin-left: -12px;
    margin-right: -12px;
    position: relative;
}

.VtZXE {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
}

.htgJZr.active {
    color: rgb(255, 255, 255);
    border-bottom-width: 2px;
    border-bottom-color: rgb(192, 49, 40);
}

.htgJZr {
    color: rgb(170, 170, 172);
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 15px 0px;
    border-bottom: 1px solid rgb(74, 74, 75);
    white-space: nowrap;
}

.cfErFJ {
    width: 24px;
    min-width: 24px;
    padding: 15px 0px;
    border-bottom: 1px solid rgb(74, 74, 75);
}

.cfErFJ:last-child {
    width: 100%;
    min-width: auto;
}

@media (min-width: 1200px) {
    .jAHLPW {
        max-width: 980px;
    }
}

@media (min-width: 1200px) {
    .dPOoWx {
        flex-direction: row;
    }
}

.dPOoWx {
    display: flex;
    flex-direction: column;
    max-width: 768px;
}

@media (min-width: 1200px) {
    .eOjNEX {
        max-width: 640px;
    }
}

.eOjNEX {
    width: 100%;
}

.hOsmnk {
    display: flex;
    flex-direction: column;
    width: 100%;
}

@media (min-width: 768px) {
    .cftqiI {
        flex-direction: row;
        -webkit-box-pack: justify;
        justify-content: space-between;
    }
}

.cftqiI {
    display: flex;
    position: relative;
    flex-direction: column;
    padding-bottom: 20px;
}

@media (min-width: 768px) {
    .dWnuTu {
        flex-direction: row;
        margin-bottom: 0px;
        flex-shrink: 2;
    }
}

.dWnuTu {
    position: relative;
    flex-direction: column;
    width: fit-content;
}

.jUVzlo {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 100%;
}

.jpzwg {
    display: flex;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    align-items: center;
    white-space: pre;
    padding-right: 10px;
    color: rgb(170, 170, 172);
    font-weight: 700;
}

.fUuaiR {
    width: 100%;
}

.bXLOkd:disabled {
    opacity: 0.5;
}

.hkVClM {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 40px 0px 0px;
}

.hMcLXB {
    position: relative;
    display: flex;
    flex-direction: column;
}

.HTDiN {
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    color: rgb(255, 255, 255);
    opacity: 0.65;
    margin-bottom: 23px;
}

.fJMeie {
    margin-bottom: 21px;
}

.kueTPp {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

.fJMeie>label {
    white-space: pre-wrap;
    flex: 1 1 0%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.hQYEzE {
    color: rgb(255, 255, 255);
    font-weight: 400;
    font-size: 16px;
    margin: 0px;
    white-space: nowrap;
    cursor: pointer;
}

.eOVAak {
    margin-left: 10px;
}

.dcxBEq {
    padding-left: 30px;
    overflow: hidden;
}

.dyZWoB {
    left: 8px;
    cursor: pointer;
}

.jcpBLN {
    border-radius: 50%;
    font-size: 22px;
    padding: 0px 5px;
    min-width: auto;
}

.eUxSyJ {
    width: auto;
}

.gREDDQ {
    width: 100%;
}
.jjBxRN {
    display: none;
    margin-right: 8px;
}

.zarjR {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 4px;
    overflow: initial;
    min-height: 200px;
}
.SFNnV {
    position: relative;
    width: 100%;
    padding: 10px;
}

.eNwPYJ {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: rgb(25, 28, 36);
    padding: 12px;
    max-width: 700px;
}

.jMVzQu {
    height: 1px;
    background-color: rgb(74, 74, 75);
    margin: 10px 0px;
}

.kJRhyJ {
    height: 1px;
    background-color: rgb(74, 74, 75);
    margin: 10px 0px;
}

.fATaWD {
    height: 100%;
}

.gReyLM {
    display: flex;
    flex-wrap: wrap;
    margin: -5px -5px 5px;
}

.gReyLM:last-child {
    margin-block-end: 0px;
}

.gQbOlY {
    position: relative;
    min-width: 100px;
    flex-basis: 50%;
    padding-block-start: 30%;
}
.jxLnWu {
    position: absolute;
    inset-block: 5px;
    inset-inline: 5px;
    padding: 5px 10px 20px;
    cursor: pointer;
    background: rgb(255, 255, 255);
    border: 2px solid rgb(3, 255, 120);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    -webkit-box-align: center;
    align-items: center;
    overflow: hidden;
}

.khVFdb {
    position: absolute;
    inset-block: 5px;
    inset-inline: 5px;
    padding: 5px 10px 20px;
    cursor: pointer;
    background: rgb(255, 255, 255);
    border: 2px solid transparent;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    -webkit-box-align: center;
    align-items: center;
    overflow: hidden;
}

.hGiEja {
    width: 100%;
    max-height: 100%;
    font-size: 12px;
    color: rgb(0, 0, 0);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.kxXIM {
    position: absolute;
    inset-block-end: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: rgb(170, 170, 172);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.02px;
    line-height: 16px;
    height: 16px;
}

.etIydt {
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
}
.hmzAMd {
    font-size: 20px;
    color: rgb(255, 255, 255);
    font-weight: 700;
    margin: auto 0px;
}
.FUYfz {
    background: rgb(255, 255, 255);
    width: 100px;
    border-radius: 4px;
    height: 50px;
}

.eolVMk {
    background: rgb(255, 255, 255);
    width: 100px;
    border-radius: 4px;
    height: 50px;
    margin-inline-start: 10px;
}

.kNfayl {
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    align-items: center;
    margin-block-start: 24px;
}
.hLFLDZ {
    width: auto;
    margin-inline-end: 10px;
}
.cQOOlC {
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    margin: 0px;
}
.cschYM {
    color: rgb(129, 129, 131);
    font-size: 12px;
}
.eNFwlF {
    color: rgb(129, 129, 131);
    font-size: 12px;
    font-weight: 400;
    white-space: nowrap;
}

.eNFwlF:last-child {
    padding-inline-start: 5px;
}

@media (min-width: 404px) {
    .gQbOlY {
    flex-basis: 33.33%;
    padding-block-start: 20%;
    }
}


@media (min-width: 589px) {
    .gQbOlY {
        flex-basis: 25%;
        padding-block-start: 15%;
    }
}

@media (min-width: 768px) {
    .fUuaiR {
        max-width: 360px;
        -webkit-box-flex: 2;
        flex-grow: 2;
    }

    .hkVClM {
        margin: 24px 0px 0px;
    }
    .fHgVXS {
        align-self: flex-end;
        margin-top: 12px;
    }
    .zarjR {
        flex-direction: row;
    }
    .SFNnV {
        background: rgb(25, 28, 36);
        max-width: 230px;
    }
    .eNwPYJ {
        padding: 24px;
    }
    .gReyLM {
        width: 220px;
    }
    .gQbOlY {
        flex-basis: 50%;
        padding-block-start: 30%;
    }

}

@media (min-width: 1200px) {
    .jjBxRN {
        display: initial;
    }
    .hkVClM {
        margin: 0px 0px 0px 40px;
        max-width: 276px;
        flex-shrink: 2;
    }
    .zarjR {
        max-width: 739px;
    }
}

/* ------------------------------------------------- */

.STczF {
    -webkit-box-flex: 1;
    flex-grow: 1;
    padding-block-start: 54px;
    width: 100%;
}

.XfiFP {
    color: rgb(255, 255, 255);
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    margin: 0px;
}


.zarjR {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 4px;
    overflow: initial;
    min-height: 200px;
}

.fkEASd {
    width: 100%;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    align-items: center;
}

.fkEASd:first-child {
    margin-inline-end: 10px;
}

.jMVzQu {
    height: 1px;
    background-color: rgb(74, 74, 75);
    margin: 10px 0px;
}

.fjzKDd {
    width: 100%;
    margin-block-end: 5px;
    display: flex;
    flex-wrap: inherit;
    -webkit-box-pack: justify;
    justify-content: space-between;
    align-items: flex-end;
}

.fjzKDd:only-child, .fjzKDd:last-child {
    margin-block-end: 0px;
}

.fZfAPM {
    color: rgb(129, 129, 131);
    font-size: 12px;
    font-weight: 400;
}

.jcUjWI {
    width: fit-content;
    display: flex;
    -webkit-box-pack: end;
    justify-content: flex-end;
}

.eInVrx {
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-weight: 400;
    padding-inline-end: 5px;
}

.iVqJhS {
    width: auto;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    pointer-events: none;
}

.iuRCcK {
    width: fit-content;
    font-size: 16px;
}

.iuRCcK .sc-fznOgF {
    color: rgb(173, 173, 173);
    font-weight: 400;
}

.bVrjan {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    pointer-events: none;
}

.kKGPbU {
    color: rgb(41, 121, 255);
    font-weight: 700;
    background-color: transparent;
    padding: 0px;
    border: none;
    text-decoration: none;
    appearance: none;
}

.hlCgrY {
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

.FUYfz {
    background: rgb(255, 255, 255);
    width: 100px;
    border-radius: 4px;
    height: 50px;
}

.eolVMk {
    background: rgb(255, 255, 255);
    width: 100px;
    border-radius: 4px;
    height: 50px;
    margin-inline-start: 10px;
}

.dUtWVM {
    width: auto;
    margin-inline-end: 10px!important;
}

.eaGQxL {
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    margin: 0px;
}

.llENjE {
    margin-block-start: 12px;
}

.gTHzsp {
    width: 100%;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
}

.eyBwhO:first-child {
    border-radius: 8px 0px 0px 8px;
}

.hmzAMd {
    font-size: 20px;
    color: rgb(255, 255, 255);
    font-weight: 700;
    margin: auto 0px;
}

.eNwPYJ {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: rgb(25, 28, 36);
    padding: 12px;
    max-width: 700px;
}

.eyBwhO {
    cursor: pointer;
    flex: 1 1 0%;
    text-align: center;
    color: rgb(255, 255, 255);
    background-color: rgb(1, 150, 69);
    margin-inline-end: 1px;
    line-height: 48px;
}

.gJliDQ {
    margin-block-start: 12px;
}

.Obgst {
    cursor: pointer;
    flex: 1 1 0%;
    text-align: center;
    color: rgb(53, 56, 69);
    background-color: rgb(3, 255, 120);
    margin-inline-end: 1px;
    line-height: 48px;
}

.eyBwhO {
    cursor: pointer;
    flex: 1 1 0%;
    text-align: center;
    color: rgb(255, 255, 255);
    background-color: rgb(1, 150, 69);
    margin-inline-end: 1px;
    line-height: 48px;
}

.jYWViF {
    color: rgb(129, 129, 131);
    text-transform: uppercase;
}

.hnsfIt {
    height: 0px;
    opacity: 0;
    overflow: hidden;
    transition-property: height, opacity;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.cQZWEv {
    display: flex;
}

.hIuxOY {
    width: 100%;
}

.gcoVoV {
    font-size: 12px;
    font-weight: 400;
    color: rgb(206, 44, 32);
}

.dwguNF.entered {
    height: 854px;
    opacity: 1;
    overflow: unset;
}

.dwguNF {
    height: 0px;
    opacity: 0;
    overflow: hidden;
    transition-property: height, opacity;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.WxSjL {
    margin-block-start: 15px;
}

.eOBlZj {
    margin: 10px 0px;
    width: 100%;
}

.hvFmha {
    color: rgb(129, 129, 131);
    font-size: 13px;
    margin-block-end: 5px;
}

.gFfxwA {
    color: rgb(206, 44, 32);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}

.kcDUHz, .kcDUHz:focus {
    outline: none;
    background-color: transparent;
    box-shadow: none;
    color: rgb(255, 255, 255);
}

.kcDUHz {
    width: 100%;
    min-width: 0px;
    height: 48px;
    font-weight: 400;
    padding: 8px 0px;
    border: none;
}

.liedto {
    position: absolute;
    inset-block: 0px;
    inset-inline: 0px;
    background: transparent;
    pointer-events: none;
    border-radius: 8px;
    border: 1px solid rgb(244, 67, 54);
}

.Swkst {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    margin-block-start: 24px;
}

.kTgfJL {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin: 0px 0px 0px 16px;
}

.ghyfcs {
    margin-inline-end: 12px;
    color: rgb(129, 129, 131);
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
}

.dzHhcY {
    padding-inline-start: 30px;
    overflow: hidden;
}

.eSzeEZ.entered {
    height: 67px;
    opacity: 1;
    overflow: unset;
}

.eSzeEZ {
    height: 0px;
    opacity: 0;
    overflow: hidden;
    transition-property: height, opacity;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.gwUVsP {
    display: flex;
    gap: 5px;
    padding: 12px 0px 0px;
}

.dCPVan {
    padding: 6px 12px;
    min-width: unset;
    text-transform: uppercase;
}

.lfmvhT {
    margin-block-start: 15px;
    color: rgb(129, 129, 131);
}

.cFGHMd {
    height: 0px;
    opacity: 0;
    overflow: hidden;
    transition-property: height, opacity;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.huUVOH {
    display: flex;
    flex-wrap: wrap;
    margin: -4px;
    padding-block-start: 12px;
}

.dSiTFp.entered {
    height: 37px;
    opacity: 1;
    overflow: unset;
}

.dSiTFp {
    height: 0px;
    opacity: 0;
    overflow: hidden;
    transition-property: height, opacity;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.cQkhkf {
    color: rgb(129, 129, 131);
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    margin-block-start: 16px;
}

.hTNLrh.entered {
    height: 65px;
    opacity: 1;
    overflow: unset;
}

.hTNLrh {
    height: 0px;
    opacity: 0;
    overflow: hidden;
    transition-property: height, opacity;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.hzoIVR {
    width: 100%;
    margin-block-start: 24px;
}

.hjQhUF {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.lkcIyl {
    height: 0px;
    opacity: 0;
    overflow: hidden;
    transition-property: height, opacity;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.fvSYHW {
    color: rgb(173, 173, 173);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}

.payment-button__bonus {
    color: rgb(53, 56, 69) !important;
}

.lhmOkY {
    color: rgb(170, 170, 172);
}

.lhmOkY > * {
    display: flex;
}

.lhmOkY > * > * {
    margin-inline-end: 10px;
}

.lhmOkY > * :last-child {
    margin-inline-end: 0px;
}

.gRkgdV {
    height: 0px;
    opacity: 0;
    overflow: hidden;
    transition-property: height, opacity;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.kOcQHD {
    margin-block-start: 24px;
}

.hpykXp {
    color: rgb(206, 44, 32);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}

.lhmOkY img {
    max-height: 100px;
    max-width: 100px;
}
.kTbGNa {
    display: none;
}

.gMRrhS {
    display: block;
    height: 100%;
    transform: translateX(100%);
    width: 300px;
}
.gSaNTA {
    width: 100vw;
}

.bCCWCd {
    -webkit-box-align: center;
    align-items: center;
    background-color: rgb(25, 28, 36);
    display: flex;
    height: 54px;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 0px 14px 0px 10px;
    position: fixed;
    inset-block-start: 0px;
    width: 100vw;
    z-index: 1030;
}

.XfiFP {
    color: rgb(255, 255, 255);
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    margin: 0px;
}
.dDvBNb.dDvBNb:checked {
    background-position: right center;
}
.dDvBNb.dDvBNb {
    margin-inline-start: -1.5em;
    background-position: left center;
}
.dDvBNb {
    inset-inline-start: 0px;
}

.WFtZN {
    inset-inline-start: 8px;
    cursor: pointer;
}
.WFtZN.WFtZN::after {
    background-color: rgb(68, 71, 86);
}
.WFtZN::before {
    background-color: transparent;
    border-color: rgb(68, 71, 86);
}
.dDvBNb:checked ~ .sc-pYA-dN::before {
    border-color: rgb(68, 71, 86);
    background-color: rgb(68, 71, 86);
}

@media (min-width: 768px) {
    .bCCWCd {
        padding: 0px 24px 0px 16px;
    }
    .Obgst {
        line-height: 40px;
    }
    .kcDUHz {
        height: 40px;
    }
    .eyBwhO {
        line-height: 40px;
    }
    .eNwPYJ {
        padding: 24px;
    }
    .zarjR {
        flex-direction: row;
    }
}

@media (min-width: 1200px) {
    .bCCWCd {
        height: 60px;
    }
    .gSaNTA {
        display: flex;
    }
    .gMRrhS {
        display: none;
    }
    .kTbGNa {
        display: block;
    }
    .zarjR {
        max-width: 739px;
    }
    .STczF {
        padding-block-start: 60px;
        padding-inline-start: 270px;
    }
}

