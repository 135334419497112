@media(min-width:720px) {
    .sign-up-modal .modal__content {
        max-width: 480px
    }
}

@media(min-width:960px) {
    .sign-up-modal .modal__content {
        max-width: 828px
    }
}

#search-modal .modal__content {
    display: block;
    padding: 0;
    background: transparent;
    border-radius: unset;
    width: 100%;
    height: unset
}

@media(min-width:960px) {
    #search-modal .modal__content {
        margin-top: 128px!important;
        max-width: 600px!important
    }
}

#search-modal.modal .modal__close-button {
    height: 56px;
    position: absolute;
    right: 0;
    top: 0;
    padding: 0 22px
}

.notifications-modal .modal__content {
    display: flex
}

.notifications-modal__page {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column
}

@media(min-width:720px) {
    .limits-modal.modal .modal__close-button {
        right: 15px;
        top: 15px
    }
}

.email-modal .user-page-container {
    padding: 32px 16px
}

@media(min-width:720px) {
    .email-modal .user-page-container {
        padding: 40px 32px
    }
}

.email-modal .heading5 {
    font-size: 24px;
    line-height: 32px;
    font-family: Roboto, Arial, sans-serif;
    margin-bottom: 8px
}

.welcome-modal-wrapper.modal .modal__content {
    border-radius: 4px;
    overflow: hidden
}

.welcome-modal-wrapper.modal .modal__close-button {
    right: 7px;
    top: 7px;
    font-size: 14px;
    color: #fff
}

@media(min-width:960px) {
    .welcome-modal-wrapper.modal .modal__close-button {
        right: 14px;
        top: 14px
    }
}

.welcome-modal-wrapper.modal .modal__close-button:hover {
    color: #999
}

.layout__footer {
    background-color: #1c1e26
}

@media(max-width:720px) {
    .layout__footer .container {
        overflow: visible
    }
}

.layout__main {
    background-color: #1c1e26;
    max-width: 100vw;
    overflow: hidden
}

.header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    position: sticky;
    top: 0;
    width: 100%;
    padding: 0 16px;
    margin: 0;
    height: 56px;
    z-index: 100;
    transition: .3s ease
}

@media(min-width:720px) {
    .header {
        padding: 0 24px
    }
}

@media(min-width:1248px) {
    .header {
        padding: 0 32px;
        height: 80px
    }
}

.header__back {
    color: #fff;
    font-size: 22px
}

.header--bg {
    background-color: #262933
}

.header--hide {
    display: none
}

.header .header__logo {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: auto;
    padding: 0;
    margin: 0
}

.header .header__logo img {
    width: 60px;
    margin-bottom: 4px
}

@media(min-width:720px) {
    .header .header__logo img {
        width: 90px;
        margin-bottom: 4px
    }
}

@media(min-width:960px) {
    .header .header__logo img {
        width: 90px;
        margin-bottom: 6px
    }
}

@media(min-width:1248px) {
    .header .header__logo img {
        width: 90px;
        margin-bottom: 6px
    }
}

.header .header__menu {
    display: none
}

@media(min-width:960px) {
    .header .header__menu {
        display: inline-flex;
        margin-left: 40px;
        width: auto
    }
}

.header .header__locales {
    margin-right: -12px
}

.header .header__locales .select-language-icons-with-code__icon {
    width: 24px
}

.header .header__locales .select-language-icons-with-code__button .select-language-icons-with-code__caret,
.header .header__locales .select-language-icons-with-code__button .select-language-icons-with-code__code {
    display: none
}

@media(min-width:720px) {
    .header .header__locales {
        display: inline-flex
    }
}

.header .header__search {
    display: none;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: auto;
    padding: 10px 5px;
    margin: 0 auto 4px 10px;
    font-size: 24px;
    color: #fff
}

@media(min-width:960px) {
    .header .header__search {
        display: inline-flex
    }
}

.header .header__notification-center {
    display: none
}

@media(min-width:960px) {
    .header .header__notification-center {
        display: inline-flex;
        margin-left: 12px
    }
}

.header__user-menu-balance {
    display: inline-flex
}

.header__user-menu-select {
    display: none
}

@media(min-width:720px) {
    .header__user-menu-select {
        display: inline-flex
    }
}

.header .header__button-deposit {
    margin: 0 0 0 8px
}

@media(min-width:1248px) {
    .header .header__button-deposit {
        margin: 0 12px
    }
}

.header .header__button {
    display: inline-flex;
    margin: 0 0 0 12px;
    font-size: 14px
}

.header .header__button.btn--outline {
    background: transparent;
    min-width: 71px
}

.header .header__button.btn--secondary {
    min-width: 71px
}

@media(max-width:720px) {
    .header .header__button {
        margin: 0 0 0 8px;
        min-width: 71px
    }
}

.header__right {
    display: flex;
    align-items: center;
    margin-left: auto
}

.top-menu {
    display: flex;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none
}

.top-menu__link {
    position: relative;
    white-space: nowrap;
    line-height: 24px;
    margin-right: 24px;
    text-decoration: none;
    outline: none;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    transition: color .3s ease, background-color .3s ease
}

.top-menu__link:active,
.top-menu__link:focus,
.top-menu__link:hover {
    color: hsla(0, 0%, 100%, .8)
}

@media(min-width:720px) {
    .top-menu__link {
        justify-content: center
    }
}

@media(min-width:960px) {
    .top-menu__link {
        margin-right: 24px
    }
}

@media(min-width:1248px) {
    .top-menu__link {
        font-weight: 600;
        border-radius: 0;
        border-bottom: 2px solid transparent;
        transition: color .3s ease, border-color .3s ease
    }
}

@media(min-width:1632px) {
    .top-menu__link {
        font-size: 16px
    }
}

.top-menu__link--current,
.top-menu__link--current:active,
.top-menu__link--current:focus,
.top-menu__link--current:hover {
    color: #fff;
    border-bottom: 2px solid #3ecdf1
}

@media(min-width:1248px) {
    .top-menu__link--current,
    .top-menu__link--current:active,
    .top-menu__link--current:focus,
    .top-menu__link--current:hover {
        border-bottom-color: #32CCF2;
        background-color: transparent
    }
}

.top-menu__link:last-child {
    margin-right: 0
}

.top-menu__beta {
    color: #48a902;
    text-transform: uppercase;
    font-size: 9px;
    line-height: 9px;
    position: absolute;
    bottom: 100%;
    left: 0;
    font-family: Rubik
}

.top-menu__name {
    display: inline-block;
    vertical-align: top
}

.footer-menu {
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none
}

@media(min-width:720px) {
    .footer-menu {
        text-align: left
    }
}

.footer-menu__title {
    padding-bottom: 0!important;
    margin: 0 0 12px;
    color: #fff
}

.footer-menu__item:not(:last-child) {
    margin-bottom: 8px
}

.footer-menu__link {
    display: inline-block;
    color: hsla(0, 0%, 100%, .4)
}

.footer-menu__link--current,
.footer-menu__link--current:active,
.footer-menu__link--current:focus,
.footer-menu__link--current:hover,
.footer-menu__link:hover {
    color: #fff
}

.menu-mobile {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between
}

.menu-mobile__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: calc(50% - 4px);
    background-color: #2e313d;
    border-radius: 8px;
    color: #fff;
    padding: 12px 16px;
    margin-bottom: 8px
}

.menu-mobile__item:last-child {
    margin-bottom: 0
}

@media(min-width:720px) {
    .menu-mobile__item {
        flex-direction: row;
        font-size: 16px;
        align-items: center;
        padding: 16px;
        margin-bottom: 12px;
        width: calc(50% - 6px)
    }
}

.menu-mobile__icon {
    font-size: 24px;
    margin-bottom: 5px;
    color: #3ecdf1
}

@media(min-width:720px) {
    .menu-mobile__icon {
        margin-bottom: 0;
        margin-right: 18px
    }
}

.menu-mobile__green-color {
    color: #33d672
}

.footer-logos-slider.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent
}

.footer-logos-slider.slick-slider .slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0
}

.footer-logos-slider.slick-slider .slick-list:focus {
    outline: none
}

.footer-logos-slider.slick-slider .slick-list.dragging {
    cursor: pointer;
    cursor: hand
}

.footer-logos-slider.slick-slider .slick-list,
.footer-logos-slider.slick-slider .slick-track {
    transform: translateZ(0)
}

.footer-logos-slider.slick-slider .slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto
}

.footer-logos-slider.slick-slider .slick-track:after,
.footer-logos-slider.slick-slider .slick-track:before {
    content: "";
    display: table
}

.footer-logos-slider.slick-slider .slick-track:after {
    clear: both
}

.slick-loading .footer-logos-slider.slick-slider .slick-track {
    visibility: hidden
}

.footer-logos-slider.slick-slider .slick-slide {
    padding: 0 4px;
    float: left;
    height: 100%;
    min-height: 1px;
    display: none
}

[dir=rtl] .footer-logos-slider.slick-slider .slick-slide {
    float: right
}

.footer-logos-slider.slick-slider .slick-slide img:not(.image--hidden) {
    display: block;
    margin: 0 auto
}

.footer-logos-slider.slick-slider .slick-slide.slick-loading img {
    display: none
}

.footer-logos-slider.slick-slider .slick-slide.dragging img {
    pointer-events: none
}

.footer-logos-slider.slick-slider.slick-initialized .slick-slide {
    display: block
}

.footer-logos-slider.slick-slider.slick-loading .slick-slide {
    visibility: hidden
}

.footer-logos-slider.slick-slider.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent
}

.footer-logos-slider.slick-slider .slick-arrow.slick-hidden {
    display: none
}

.footer-logos-slider:before {
    left: 0;
    transform: matrix(-1, 0, 0, 1, 0, 0)
}

.footer-logos-slider:after,
.footer-logos-slider:before {
    content: "";
    z-index: 2;
    position: absolute;
    top: 0;
    height: 120px;
    width: 116px;
    background: linear-gradient(270deg, #1c1e26, rgba(28, 30, 38, 0))
}

.footer-logos-slider:after {
    right: 0
}

.footer-logos-slider__NextArrow,
.footer-logos-slider__PrevArrow {
    display: none
}

.footer-logos-slider:hover .footer-logos-slider__NextArrow {
    position: absolute;
    left: 0;
    top: 9px;
    display: block;
    height: 40px;
    width: 40px;
    z-index: 2;
    background: #2e313d;
    border-radius: 20px;
    text-align: center;
    padding: 13px;
    cursor: pointer
}

@media(max-width:960px) {
    .footer-logos-slider:hover .footer-logos-slider__NextArrow {
        display: none
    }
}

.footer-logos-slider:hover .footer-logos-slider__PrevArrow {
    position: absolute;
    right: 0;
    top: 9px;
    display: block;
    height: 40px;
    width: 40px;
    z-index: 3;
    background: #2e313d;
    border-radius: 20px;
    text-align: center;
    padding: 11px;
    cursor: pointer
}

@media(max-width:960px) {
    .footer-logos-slider:hover .footer-logos-slider__PrevArrow {
        display: none
    }
}

.footer-logos-slider__item {
    background-color: transparent;
    border: none;
    font-size: 30px;
    text-shadow: 0 0 8px #0075ff, 0 0 16px #0f8cff, 0 0 32px #0075ff, 0 0 4px #1890ff, 0 0 2px #0085ff, 0 4px 24px #0075ff;
    color: #e2faff;
    opacity: .45;
    transition: all .3s ease;
    cursor: pointer;
    width: 148px;
    height: 148px
}

.footer-logos-slider__item:hover {
    opacity: 1
}

.footer-logos-slider__item {
    outline: none;
    margin-bottom: 10px
}

.collapse-menu__toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer
}

.collapse-menu__toggle:hover {
    color: #fff
}

.collapse-menu__title {
    margin-right: 20px
}

.collapse-menu__arrow-down {
    color: hsla(0, 0%, 100%, .4);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    transform-origin: center;
    transform: rotate(0deg);
    transition: transform .3s
}

.collapse-menu__arrow-down--open {
    transform: rotate(180deg)
}

.footer-logos .slick-track,
.footer-logos__item {
    display: flex!important;
    align-items: center
}

.footer-logos__item {
    padding: 0 12px;
    margin: 12px;
    justify-content: center;
    filter: grayscale(1);
    opacity: .3;
    transition: opacity .3s ease
}

@media(min-width:960px) {
    .footer-logos__item {
        margin: 14px 20px
    }
}

.footer-logos__item:hover {
    opacity: 1
}

.footer-logos__image {
    max-width: 100%;
    max-height: 38px;
    -o-object-fit: contain;
    object-fit: contain;
    -o-object-position: center;
    object-position: center
}

.game-search-mobile__wrap {
    min-height: 100vh;
    background-color: #262933;
    padding: 24px 17px
}

.game-search-mobile__wrap:before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    height: 240px;
    width: 100%;
    /*background: url(/public/images/pages/bg.png);*/
    box-shadow: inset 0 -45px 45px 0 #262933
}

.game-search-mobile__input-search {
    margin: 24px 0 32px
}

.games-search-for-modal-root {
    height: 100%;
    padding: 24px;
    overflow-y: auto
}

@media(min-width:960px) {
    .games-search-for-modal-root {
        padding: 0
    }
}

.games-search-for-modal-root__title {
    margin-bottom: 24px;
    display: inline-block
}

@media(min-width:960px) {
    .games-search-for-modal-root__title {
        display: none
    }
}

.mobile-menu {
    display: flex;
    flex-direction: column;
    padding: 24px 16px;
    z-index: 0
}

@media(min-width:720px) {
    .mobile-menu {
        padding: 24px
    }
}

.mobile-menu__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    z-index: 1
}

.mobile-menu__user {
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    z-index: 1
}

.mobile-menu__info {
    margin: 16px 0 32px;
    z-index: 1
}

@media(min-width:720px) {
    .mobile-menu__info {
        margin-bottom: 40px
    }
}

.mobile-menu__menu {
    margin-bottom: 26px;
    z-index: 1
}

@media(min-width:720px) {
    .mobile-menu__menu {
        margin-bottom: 36px
    }
}

.mobile-menu__items {
    display: flex;
    flex-direction: column;
    z-index: 1;
    margin-bottom: 24px
}

.mobile-menu__item {
    width: 100%;
    padding: 12px 0;
    color: #fff
}

@media(min-width:720px) {
    .mobile-menu__item {
        font-size: 16px
    }
}

.mobile-menu__collapse {
    margin-bottom: 30px;
    z-index: 1
}

.mobile-menu__collapse .collapse-menu__toggle .collapse-menu__title {
    padding: 12px 0;
    font-size: 14px
}

@media(min-width:720px) {
    .mobile-menu__collapse .collapse-menu__toggle .collapse-menu__title {
        font-size: 16px
    }
}

.mobile-menu__divider {
    width: 100%;
    height: 1px;
    background-color: hsla(0, 0%, 100%, .12);
    flex-shrink: 0;
    z-index: 1
}

.mobile-menu__button {
    display: flex
}

.mobile-menu__button--icon {
    font-size: 22px;
    padding-right: 11px
}

.mobile-notifications {
    display: flex;
    flex-direction: column;
    padding: 24px 16px;
    z-index: 0
}

@media(min-width:720px) {
    .mobile-notifications {
        padding: 24px
    }
}

.mobile-notifications__title {
    font-family: Montserrat, Arial, sans-serif;
    font-weight: 900;
    z-index: 1;
    margin-right: auto;
    margin-bottom: 34px
}

.mobile-notifications .notification-center-card {
    padding-left: 0;
    padding-right: 0
}

.notification-center-mobile {
    display: flex;
    flex-direction: column;
    position: relative;
    flex: 1 1 auto
}

.notification-center-mobile .notification-center__empty {
    margin: auto
}

.notifications-count {
    width: auto;
    padding: 3px;
    border-radius: 13px;
    background-color: #3ecdf1;
    border: 2px solid #2e313d;
    color: #fff;
    text-align: center;
    font-size: 10px;
    line-height: 7px;
    display: flex;
    justify-content: center;
    align-items: center
}

.notifications-count .notifications-count--empty {
    display: none
}

.notifications-count .notifications-count--new {
    background-color: #32CCF2;
    border-color: #32CCF2
}

.panel {
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background-color: #2e313d
}

@media(min-width:960px) {
    .panel {
        display: none
    }
}

.panel__list {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    justify-content: space-around;
    width: 100%;
    margin: 0;
    height: 56px
}

.panel__link {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1 1 25%;
    padding: 0;
    margin: 0;
    color: hsla(0, 0%, 100%, .65);
    font-size: 10px;
    line-height: 12px;
    letter-spacing: .015em
}

@media(min-width:720px) {
    .panel__link {
        flex-direction: row;
        font-size: 12px;
        line-height: 18px
    }
}

.panel__link--current,
.panel__link:focus,
.panel__link:hover {
    color: #fff
}

.panel__link--current .panel__icon,
.panel__link:focus .panel__icon,
.panel__link:hover .panel__icon {
    color: #32CCF2
}

.panel__notifications {
    position: relative
}

.panel__count {
    position: absolute;
    top: 4px;
    left: 27px
}

.panel__icon {
    font-size: 23px;
    margin-bottom: 6px
}

@media(min-width:720px) {
    .panel__icon {
        margin-right: 8px;
        margin-bottom: 0
    }
}

.select__dropdown {
    scrollbar-color: #fff;
    scrollbar-width: thin
}

.select__dropdown::-webkit-scrollbar {
    width: 2px;
    height: 2px
}

.select__dropdown::-webkit-scrollbar-track {
    border-radius: 2px;
    background: hsla(0, 0%, 60%, .5)
}

.select__dropdown::-webkit-scrollbar-thumb {
    background: #fff;
    border-radius: 2px;
    margin: 0
}

.select__dropdown::-webkit-scrollbar-thumb:hover {
    background: #fff
}

.select,
.select-games-search-for-modal-mobile {
    display: block;
    position: relative;
    width: 100%;
    padding: 0;
    margin: 0;
    font-size: 14px;
    line-height: 1.2;
    text-align: left;
    color: #fff;
    font-weight: 600
}

.select-games-search-for-modal-mobile .input__native:hover,
.select .input__native:hover {
    background-color: #373a47
}

.select__option {
    min-height: 40px;
    font-weight: 400;
    font-size: 16px;
    min-height: 48px
}

.select__option:not(:first-child) {
    margin-top: 1px
}

.select__bottom-line {
    position: relative;
    width: 100%;
    height: 0
}

.select__dropdown {
    display: block;
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    width: 100%;
    padding: 0;
    margin: 0;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    background: #2e313d none;
    border: 0 solid transparent;
    border-radius: 8px;
    text-transform: none;
    max-height: 200px;
    overflow: hidden;
    overflow-y: auto;
    z-index: 100;
    box-shadow: 0 6px 12px rgba(0, 0, 0, .25)
}

.select__arrow-icon {
    font-size: 20px;
    display: block
}

.select-games-search-for-modal-mobile__arrow-icon-wrapper,
.select__arrow-icon-wrapper {
    position: absolute;
    right: 15px;
    top: 50%;
    cursor: pointer;
    font-size: 8px;
    color: hsla(0, 0%, 100%, .4);
    transform-origin: center;
    transform: rotate(0deg);
    transition: transform .3s;
    transform: translateY(-50%) rotate(0deg)
}

.select-games-search-for-modal-mobile__arrow-icon-wrapper--open,
.select__arrow-icon-wrapper--open {
    color: hsla(0, 0%, 100%, .65);
    transform: translateY(-50%) rotate(180deg)
}

.select-games-search-for-modal-mobile__dropdown {
    display: block;
    list-style: none;
    width: 100%;
    padding: 0;
    margin-top: 16px;
    border-radius: 8px
}

@media(min-width:960px) {
    .select-games-search-for-modal-mobile__dropdown {
        background-color: #2e313d;
        padding: 16px 0
    }
}

.select-games-search-for-modal-mobile__dropdown--hide {
    display: none
}

.select-games-search-for-modal-mobile__option-image {
    width: 100px;
    height: 50px;
    margin-right: 30px
}

.select-games-search-for-modal-mobile .select-games-search-for-modal-mobile__option {
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    cursor: pointer
}

.select-games-search-for-modal-mobile__option-link {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 24px;
    margin-left: -16px;
    margin-right: -16px;
    text-decoration: none;
    color: #999;
    font-size: 12px;
    line-height: 1.2;
    text-align: left;
    font-weight: 600;
    letter-spacing: .12px;
    text-transform: uppercase;
    border-radius: 4px;
    transition: background-color .3s ease, color .3s ease
}

@media(min-width:720px) {
    .select-games-search-for-modal-mobile__option-link {
        font-size: 16px
    }
}

@media(min-width:960px) {
    .select-games-search-for-modal-mobile__option-link {
        padding: 16px 40px;
        margin: unset
    }
}

@media(min-width:1248px) {
    .select-games-search-for-modal-mobile__option-link {
        padding: 8px 24px
    }
}

.select-games-search-for-modal-mobile__option-link:hover {
    background-color: #373a47;
    color: #fff
}

.select-games-search-for-modal-mobile__option-title {
    display: block;
    text-transform: none;
    color: #fff
}

.select-games-search-for-modal-mobile__option-description {
    display: block;
    text-transform: none;
    color: hsla(0, 0%, 100%, .4)!important
}

.select-games-search-for-modal-mobile__option-image {
    display: inline-block;
    vertical-align: top;
    width: 56px;
    height: auto;
    border-radius: 8px;
    margin: 0 16px 0 0
}

@media(min-width:720px) {
    .select-games-search-for-modal-mobile__option-image {
        width: 64px
    }
}

@media(min-width:320px) {
    .select-games-search-for-modal-mobile .search-list__recomended {
        margin: 20px 0;
        padding-left: 0;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #fff
    }
}

.select-language-icons-with-label__list {
    scrollbar-color: #fff;
    scrollbar-width: thin
}

.select-language-icons-with-label__list::-webkit-scrollbar {
    width: 2px;
    height: 2px
}

.select-language-icons-with-label__list::-webkit-scrollbar-track {
    border-radius: 2px;
    background: hsla(0, 0%, 60%, .5)
}

.select-language-icons-with-label__list::-webkit-scrollbar-thumb {
    background: #fff;
    border-radius: 2px;
    margin: 0
}

.select-language-icons-with-label__list::-webkit-scrollbar-thumb:hover {
    background: #fff
}

.select-language-icons-with-label {
    position: relative;
    padding: 0 12px;
    width: 160px;
    background-color: rgba(77, 82, 100, .4);
    border-radius: 8px;
    cursor: pointer
}

.select-language-icons-with-label__button {
    display: inline-flex;
    justify-content: space-between;
    width: 100%
}

.select-language-icons-with-label__button-inner {
    display: flex;
    align-items: center
}

.select-language-icons-with-label__arrow-down-wrapper {
    display: inline-flex;
    align-items: center;
    font-size: 16px;
    margin: 0 0 0 4px
}

.select-language-icons-with-label__arrow-down {
    color: hsla(0, 0%, 100%, .4);
    transform-origin: center;
    transform: rotate(0deg);
    transition: transform .3s
}

.select-language-icons-with-label__arrow-down--open {
    transform: rotate(180deg)
}

.select-language-icons-with-label__container {
    position: absolute;
    bottom: 110%;
    left: 0;
    width: auto;
    min-width: 100%;
    display: none;
    vertical-align: top;
    padding: 0;
    margin-top: 4px;
    border-radius: 8px;
    background-color: #2e313d;
    z-index: 1
}

.select-language-icons-with-label--open .select-language-icons-with-label__container {
    display: inline-block
}

.select-language-icons-with-label__list {
    display: inline-flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    vertical-align: top;
    padding: 8px 0;
    margin: 0;
    width: 216px;
    min-width: 100%;
    overflow: hidden;
    overflow-y: auto
}

.select-language-icons-with-label__item {
    display: block;
    width: 100%;
    padding: 0;
    margin: 0
}

.select-language-icons-with-label__link {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 7px 14px;
    height: 40px;
    margin: 0;
    text-decoration: none;
    outline: none;
    background-color: transparent;
    transition: background-color .3s ease;
    cursor: pointer
}

.select-language-icons-with-label__link.option-link {
    padding: 8px 12px;
    border-radius: 0
}

.select-language-icons-with-label__link.option-link>div {
    align-items: center
}

.select-language-icons-with-label__link.option-link:first-of-type,
.select-language-icons-with-label__link.option-link:last-of-type {
    margin: 0
}

.select-language-icons-with-label__link:active,
.select-language-icons-with-label__link:focus,
.select-language-icons-with-label__link:hover {
    text-decoration: none;
    outline: none
}

.select-language-icons-with-label__link:hover {
    background-color: #373a47
}

.select-language-icons-with-label__button .select-language-icons-with-label__link {
    padding: 0
}

.select-language-icons-with-label__button .select-language-icons-with-label__link:hover {
    background-color: transparent
}

.select-language-icons-with-label__icon {
    display: flex;
    align-items: center;
    vertical-align: top;
    width: 22px
}

.select-language-icons-with-label__img {
    display: inline-block;
    vertical-align: top;
    width: 26px;
    border-radius: 4px
}

.select-language-icons-with-label__code {
    display: inline-block!important;
    vertical-align: top;
    margin: 3px 0 0 12px;
    color: #fff
}

.select-language-icons-with-label__link:hover .select-language-icons-with-label__code {
    color: #fff
}

.select-language-icons-with-label__button .select-language-icons-with-label__code {
    display: none;
    font-size: 14px;
    color: #fff
}

@media(min-width:720px) {
    .select-language-icons-with-label__button .select-language-icons-with-label__code {
        display: inline-block
    }
}

.menu-balance-select .select-game-currency__arrow-down-wrapper {
    display: none
}

@media(min-width:1248px) {
    .menu-balance-select .select-game-currency__arrow-down-wrapper {
        display: inline-flex
    }
}

.required-fields-modal__content {
    padding: 24px
}

.required-fields-modal .required-fields-modal__form {
    background-color: transparent
}

@media(min-width:320px) {
    .required-fields-modal .required-fields-modal__form {
        padding: 16px
    }
}

.required-fields-modal__reject-button {
    text-transform: uppercase;
    margin: 10px 0 0
}

@media(min-width:320px) {
    .required-fields-modal__reject-button {
        margin: 0 16px 0 0
    }
}

.required-fields-modal__submit-button {
    text-transform: uppercase
}

.footer {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 56px 0 110px;
    background-color: #1c1e26
}

.footer>* {
    z-index: 1
}

@media(min-width:720px) {
    .footer {
        padding: 80px 0 105px
    }
}

@media(min-width:960px) {
    .footer {
        padding: 90px 0 80px
    }
}

@media(min-width:1248px) {
    .footer {
        padding-bottom: 110px
    }
}

@media(min-width:1632px) {
    .footer {
        padding-bottom: 70px
    }
}

.footer__seo {
    margin-bottom: 80px
}

.footer__seo>* {
    z-index: 1
}

@media(min-width:1248px) {
    .footer__seo-wrapper {
        display: flex;
        justify-content: space-between
    }
}

.footer__seo-block {
    width: 100%;
    color: hsla(0, 0%, 100%, .65)
}

@media(min-width:1248px) {
    .footer__seo-block:not(:last-child) {
        margin-right: 52px
    }
}

.footer__seo-text {
    transition: .3s ease-in-out;
    margin: 0 0 24px
}

.footer__divider {
    border-top: 1px solid hsla(0, 0%, 100%, .12);
    width: 100%;
    margin-bottom: 48px
}

.footer__divider-mobile {
    margin: 16px 0
}

.footer__sections {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between
}

@media(min-width:960px) {
    .footer__sections {
        flex-direction: row
    }
}

.footer__section-license {
    display: flex;
    flex-direction: column-reverse
}

@media(min-width:960px) {
    .footer__section-license {
        flex-direction: column
    }
}

.footer__license-wrapper {
    display: flex;
    margin-bottom: 32px
}

.footer__license-content-wrapper {
    width: 100%
}

@media(min-width:960px) {
    .footer__license-content-wrapper {
        max-width: 239px;
        margin-right: 60px
    }
}

@media(min-width:1248px) {
    .footer__license-content-wrapper {
        max-width: 324px;
        margin-right: 116px
    }
}

@media(min-width:1632px) {
    .footer__license-content-wrapper {
        max-width: 449px;
        margin-right: 247
    }
}

.footer__license-title {
    padding-bottom: 0!important;
    margin: 0 0 12px;
    color: #fff
}

.footer__license-content {
    color: hsla(0, 0%, 100%, .4)
}

.footer__logo-wrapper {
    margin-right: 16px
}

.footer__section-menu {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    margin-bottom: 40px
}

@media(min-width:720px) {
    .footer__section-menu {
        flex-direction: row;
        justify-content: space-between;
        padding-right: 75px
    }
}

@media(min-width:960px) {
    .footer__section-menu {
        padding-right: 54px;
        margin-bottom: 0
    }
}

@media(min-width:1248px) {
    .footer__section-menu {
        padding-right: 87px
    }
}

@media(min-width:1632px) {
    .footer__section-menu {
        padding-right: 105px;
        max-width: 812px
    }
}

@media(min-width:720px) {
    .footer__menu-col {
        max-width: 158px
    }
}

.footer__locales-wrapper {
    margin-bottom: 32px
}

@media(min-width:960px) {
    .footer__locales-wrapper {
        margin-left: 44px;
        margin-bottom: 0
    }
}

.notification-center-modal .modal__content {
    padding: 30px;
    max-width: 600px;
    overflow: hidden
}

.notification-center-modal .freespin__timer {
    margin-left: auto;
    margin-right: auto
}

.notification-center-modal-content-bonus .bonus-game {
    margin-bottom: 10px
}

.notification-center-modal-content-bonus .bonus-game:last-child {
    margin-bottom: 0
}

@media(min-width:720px) {
    .notification-center-modal-content-bonus .bonus-game {
        margin-right: 20px;
        margin-bottom: 0
    }
    .notification-center-modal-content-bonus .bonus-game:last-child {
        margin-right: 0
    }
}

.bonus__title {
    margin-bottom: 20px;
    font-size: 40px;
    font-weight: 900
}

.bonus__name {
    margin-bottom: 10px
}

.bonus__buttons {
    display: flex;
    justify-content: center
}

.bonus__activate {
    margin-left: 20px
}

.bonus__date {
    text-align: center;
    margin: 10px 0
}

.bonus__description {
    margin-bottom: 10px
}

.bonus-games__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 15px
}

@media(min-width:720px) {
    .bonus-games__list {
        flex-direction: row
    }
}

.bonus-games__show-more {
    display: block;
    margin: 10px auto
}

.bonus-games__modal-content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center
}

.bonus-games__modal-content .bonus-game {
    margin: 0 10px 10px
}

.bonus-games__modal .modal__content {
    padding: 0 20px 20px
}

.bonus-games__modal-title {
    display: block;
    padding: 10px 0;
    font-weight: 900;
    text-align: center
}

.bonus-game {
    position: relative;
    cursor: pointer
}

.bonus-game:hover .bonus-game__menu {
    display: flex
}

@media(min-width:960px) {
    .bonus-game:hover .bonus-game__title {
        display: block
    }
}

.bonus-game__menu {
    top: 0;
    bottom: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center
}

.bonus-game__menu,
.bonus-game__title {
    position: absolute;
    right: 0;
    left: 0;
    display: none
}

.bonus-game__title {
    bottom: 5px;
    text-align: center
}

.bonus-game__image {
    width: 150px
}

@media(min-width:720px) {
    .bonus-game__image {
        width: 200px
    }
}

.bonus-game__play-button {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0
}

@media(min-width:960px) {
    .bonus-game__play-button {
        position: static;
        opacity: 1
    }
}

.bonus--jackpots {
    position: relative
}

.bonus--jackpots .bonus__bg-wrap {
    width: 120%;
    position: absolute;
    top: -72px;
    left: 50%;
    transform: translateX(-50%)
}

.bonus--jackpots .bonus__confetti {
    position: absolute;
    top: -40px;
    left: -40px;
    width: calc(100% + 40px)
}

.bonus--jackpots .bonus__bg {
    width: auto;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0
}

.bonus--jackpots .bonus__content {
    position: relative;
    z-index: 2
}

.bonus--jackpots .bonus__title {
    margin-bottom: 0;
    margin-top: 12px;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #fff;
    text-align: center;
    text-transform: uppercase
}

@media(min-width:720px) {
    .bonus--jackpots .bonus__title {
        margin-bottom: 24px;
        font-size: 32px;
        line-height: 38px
    }
}

.bonus--jackpots .bonus__name {
    margin-top: 16px;
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: .01em;
    text-transform: uppercase;
    color: #fff
}

@media(min-width:720px) {
    .bonus--jackpots .bonus__name {
        font-size: 16px;
        line-height: 24px
    }
}

.bonus--jackpots .bonus__pool {
    font-family: Montserrat, Arial, sans-serif;
    margin-bottom: 8px;
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    text-align: center;
    color: #fff;
    text-transform: uppercase
}

@media(min-width:720px) {
    .bonus--jackpots .bonus__pool {
        font-size: 48px;
        line-height: 57px
    }
}

.bonus--jackpots .bonus__wager {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: hsla(0, 0%, 100%, .65);
    text-align: center;
    text-transform: capitalize
}

.bonus--jackpots .bonus__block-icon {
    display: flex;
    justify-content: center;
    width: 100%
}

.bonus--jackpots .bonus__block-icon img {
    max-width: 160px
}

@media(min-width:720px) {
    .bonus--jackpots .bonus__block-icon img {
        max-width: 100%
    }
}

.bonus--jackpots .bonus__buttons {
    margin-top: 24px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap
}

@media(min-width:720px) {
    .bonus--jackpots .bonus__buttons {
        margin-top: 32px;
        flex-wrap: nowrap
    }
}

@media(max-width:720px) {
    .bonus--jackpots .bonus__buttons .btn {
        width: 100%;
        margin: 8px 0
    }
}

.bonus--jackpots .bonus__activate {
    padding: 12px 24px;
    margin-left: 20px
}

.bonus--jackpots .bonus__forfeit {
    padding: 12px 24px;
    background-color: #2e313d;
    background-image: none;
    box-shadow: none
}

.bonus--jackpots .bonus__date {
    text-align: center;
    margin: 24px auto 10px
}

.bonus--jackpots .bonus__description {
    margin-bottom: 10px
}

.bonus--jackpots .tourn-timer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center
}

.bonus--jackpots .tourn-timer__title {
    position: relative;
    padding-left: 26px;
    font-family: Montserrat, Arial, sans-serif;
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    color: #fff
}

.bonus--jackpots .tourn-timer__title:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    /*background: url(url(../assets/clock.8535c24a.svg) 50% no-repeat*/
}

.bonus--jackpots .tourn-timer .inline-timer {
    min-height: inherit;
    margin-top: 0;
    background-color: transparent;
    width: auto;
    padding-top: 0;
    padding-bottom: 0
}

.bonus--jackpots .tourn-timer .tourn-timer__title {
    width: auto;
    white-space: nowrap
}

.bonus--jackpots .tourn-timer .inline-timer__label,
.bonus--jackpots .tourn-timer .inline-timer__text,
.bonus--jackpots .tourn-timer .inline-timer__value {
    font-size: 12px;
    line-height: 15px;
    color: #fff;
    font-family: Montserrat, Arial, sans-serif;
    text-transform: none
}

.notification-center-modal-content-freespin .bonus-game {
    margin-bottom: 10px
}

.notification-center-modal-content-freespin .bonus-game:last-child {
    margin-bottom: 0
}

@media(min-width:720px) {
    .notification-center-modal-content-freespin .bonus-game {
        margin-right: 20px;
        margin-bottom: 0
    }
    .notification-center-modal-content-freespin .bonus-game:last-child {
        margin-right: 0
    }
}

.freespin__games {
    display: flex;
    justify-content: center;
    margin-top: 15px
}

.freespin__total {
    margin-top: 10px;
    text-align: center
}

.freespin__title {
    text-align: center;
    font-weight: 900
}

.freespin__game {
    position: relative;
    cursor: pointer;
    margin-right: 20px
}

.freespin__game:last-child {
    margin-right: 0
}

.freespin__game:hover .freespin__game-menu {
    display: flex
}

.freespin__game:hover .freespin__game-title {
    display: block
}

.freespin__game-menu {
    top: 0;
    bottom: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center
}

.freespin__game-menu,
.freespin__game-title {
    position: absolute;
    right: 0;
    left: 0;
    display: none
}

.freespin__game-title {
    bottom: 5px;
    text-align: center
}

.freespin__game-image {
    width: 200px
}

.freespin__activate-until {
    text-align: center;
    margin: 15px 0 5px
}

.freespin__timer {
    text-align: center;
    margin-bottom: 20px
}

.freespin__button-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap
}

.freespin__button {
    margin: 10px 10px 0
}

.notification-center-modal-content-level__title {
    margin-bottom: 20px;
    font-size: 40px;
    font-weight: 900
}

.notification-center-modal-content-level__level {
    margin-bottom: 10px;
    font-size: 32px
}

.notification-center-modal-content-level__level-name {
    font-weight: 900
}

.lootbox__valid-until {
    margin: 10px 0;
    text-align: center
}

.lootbox__cancel {
    display: block;
    margin: 20px auto 0
}

.lootbox__timer {
    display: block;
    text-align: center
}

.lootbox__title {
    margin: 0 0 20px;
    font-weight: 900
}

.lootbox__boxes {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 0;
    margin: 0
}

.lootbox__box {
    display: flex;
    text-align: center;
    margin-right: 20px;
    flex-direction: column;
    align-self: flex-end;
    cursor: pointer;
    width: 70px
}

.lootbox__box--disabled {
    filter: grayscale(1);
    opacity: .6;
    pointer-events: none
}

.lootbox__box--selected {
    pointer-events: none
}

.lootbox__box:last-child {
    margin-right: 0
}

@media(min-width:480px) {
    .lootbox__box {
        width: 90px
    }
}

@media(min-width:720px) {
    .lootbox__box {
        width: 150px
    }
}

@media(min-width:1248px) {
    .lootbox__box {
        width: auto
    }
}

.lootbox__show-all-boxes {
    margin: 20px auto;
    display: block
}

.notification-cookies-policy {
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    left: 0;
    right: 0;
    bottom: 0;
    background: #222226;
    padding: 20px;
    z-index: 100
}

@media(min-width:720px) {
    .notification-cookies-policy {
        flex-direction: row
    }
}

.notification-cookies-policy__title {
    font-size: 14px;
    word-break: break-word;
    line-height: 32px;
    text-align: center;
    font-weight: 600;
    margin-bottom: 10px
}

@media(min-width:720px) {
    .notification-cookies-policy__title {
        margin-bottom: 0;
        font-size: 16px;
        margin-right: 20px;
        text-align: left
    }
}