.vHQtY {
    padding: 8px;
    position: relative;
    width: 100%;
}

.fFhMWf {
    font-size: 20px;
    color: rgb(255, 255, 255);
    font-weight: 700;
    line-height: 30px;
    margin: 5px 0px 15px;
}

.hQCeKK {
    display: block;
}
.kTbGNa {
    display: none;
}

.fuOviU {
    border-block-end: unset;
    border-color: rgb(53, 56, 69);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    color: rgb(255, 255, 255);
}

.fizfxj {
    border-block-end: 1px solid rgb(53, 56, 69);
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 10px;
}



.kWpLvW {
    align-items: flex-end;
    display: flex;
    flex-basis: 180px;
    -webkit-box-pack: end;
    justify-content: flex-end;
    word-break: normal;
}

.clqQLz {
    color: rgb(3, 255, 120);
    font-size: 14px;
    font-weight: 400;
    margin: 0px;
    text-align: end;
}

.hlCgrY {
    display: flex;
    -webkit-box-pack: start;
    justify-content: flex-start;
    align-items: flex-start;
    word-break: break-word;
}
.eEsVrS {
    margin: auto 10px auto 0px;
}
.iVQzHs {
    width: 3em;
}

.hDroiJ {
    display: flex;
    margin: 16px 0px 20px;
}

.flJfFJ {
    margin-inline-end: 10px;
}

.dXMMtz {
    position: relative;
    display: flex;
    background: rgb(255, 255, 255);
    border-radius: 4px;
    width: 90px;
    height: 90px;
}

.bSxwqI {
    line-height: 16px;
    letter-spacing: 0.02px;
}

.dpRpSU {
    display: flex;
}

.dLRiFN {
    margin-block-start: 10px;
}

.eFMCar:disabled {
    opacity: 0.5;
}

.cJAnTr {
    margin-block-end: 10px;
}

.iSLOvX {
    margin-block-end: 15px;
}

.gwNWYc:disabled {
    opacity: 0.5;
}

.bOFhRf {
    display: flex;
    margin-inline-end: 12px;
}

.cPgVAk {
    display: inline;
    margin-inline-start: 5px;
    color: rgb(255, 255, 255);
}

.bCkCTr + .sc-pJUVA {
    margin: 12px 0px 0px;
}
.cmtDhN {
    display: inline;
    margin-inline-start: 5px;
    color: rgb(255, 255, 255);
}
.dEINTn {
    align-items: flex-start;
    color: rgb(129, 129, 131);
    display: flex;
    font-size: 14px;
    font-weight: 700;
    -webkit-box-pack: end;
    justify-content: flex-end;
    margin: 0px;
}

.fFVsCw {
    align-items: flex-end;
    display: flex;
    -webkit-box-pack: end;
    justify-content: flex-end;
    word-break: normal;
}
.jWzMjE {
    flex-wrap: wrap;
    font-weight: 700;
    word-break: break-word;
}

@media (min-width: 768px) {
    .dpRpSU {
        display: none;
    }
    .bCkCTr + .sc-pJUVA {
        margin: 0px;
    }
}

@media (min-width: 1200px) {
    .kTbGNa {
        display: block;
    }
    .jWzMjE {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: end;
        justify-content: flex-end;
        -webkit-box-flex: 2;
        flex-grow: 2;
        margin-block-end: 0px;
        position: relative;
        padding-inline-end: 16px;
    }
    .APDAW {
        padding: 0px 10px;
    }
    .fFVsCw {
        flex-basis: 15%;
        min-width: 15%;
    }
    .cmtDhN {
        margin-block-end: 0px;
    }
    .cPgVAk {
        position: absolute;
        inset-inline-end: 0px;
        margin-block-end: 0px;
    }
    .hQCeKK {
        display: none;
    }
    .fizfxj {
        padding: 10px 20px;
    }
    .clqQLz {
        margin: auto 0px;
    }
    .eEsVrS {
        width: 7em;
        margin-inline-end: 0px;
    }
    .iVQzHs {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
    }
}