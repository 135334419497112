.payments {
    padding-top: 24px
}

@media(min-width:960px) {
    .payments {
        padding-top: 32px
    }
}

@media(min-width:1248px) {
    .payments {
        padding-top: 40px
    }
}

.payments__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 1
}

.payments__wrapper>* {
    z-index: 1
}

.payments__page-title {
    margin-bottom: 16px
}

.payments__text {
    color: hsla(0, 0%, 100%, .65);
    margin-bottom: 40px;
    font-size: 14px
}

@media(min-width:720px) {
    .payments__text {
        font-size: 16px
    }
}

@media(min-width:960px) {
    .payments__text {
        max-width: 758px
    }
}

@media(min-width:1248px) {
    .payments__text {
        max-width: 838px
    }
}

.payments__tabs {
    width: 100%;
    display: flex;
    z-index: 1
}

.payments__tabs>* {
    z-index: 1
}

@media(min-width:720px) {
    .payments__tabs {
        display: none
    }
}