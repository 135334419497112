.cdPYxs {
    color: rgb(255, 255, 255);
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    margin: 0px;
    margin-block-end: 12px;
    margin-inline-end: 20px;
}

.bhgzma {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    flex-wrap: wrap;
}

.form-switch {
    padding-left: 2.5em;
}

.form-switch .form-check-input:checked {
    background-position: 100%;
    background-image: url(./checkbox-icon.svg);
}
.form-check-input:checked[type=checkbox] {
    background-image: url(./checkbox-icon.svg);
}

.form-switch .form-check-input {
    width: 2em;
    margin-left: -2.5em;
    background-image: url(./checkbox-icon.svg);
    background-position: 0;
    border-radius: 2em;
    -webkit-transition: background-position .15s ease-in-out;
    transition: background-position .15s ease-in-out;
}

.form-check {
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5em;
    margin-bottom: 0.125rem;
}

.iKYzMi.iKYzMi:checked {
    background-position: right center;
}

.iKYzMi.iKYzMi {
    margin-inline-start: -1.5em;
    background-position: left center;
}

.cpnchg {
    width: 100%;
    opacity: 1;
    display: flex;
    flex-direction: column;
}

.ctdubV {
    -webkit-box-align: center;
    align-items: center;
    display: flex;
}
.hfnMAq {
    color: rgb(255, 255, 255);
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    margin: 0px;
    margin-block: 8px 12px;
}

.fuDsDw {
    -webkit-box-flex: 1;
    flex-grow: 1;
    position: relative;
    width: 100%;
    background: rgb(68, 71, 86);
    color: rgb(129, 129, 131);
    border-radius: 4px;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    padding: 16px;
    min-height: 188px;
}

.TzEMb {
    -webkit-box-align: center;
    align-items: center;
    color: rgb(129, 129, 131);
    display: flex;
    -webkit-box-flex: 1;
    flex-grow: 1;
    font-size: 12px;
    font-weight: 400;
    -webkit-box-pack: center;
    justify-content: center;
    line-height: 16px;
    margin-block-end: 16px;
    text-align: center;
    width: 100%;
}
.iSZzsj {
    font-size: 20px;
    color: rgb(255, 255, 255);
    margin-inline-end: 10px;
}

.hKuSPA {
    display: flex;
    margin-block-end: 10px;
}

.pSXhU {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.ZoaOR {
    width: calc(100% - 16px);
    margin: 8px;
}

.dJnMCc {
    width: calc(100% - 16px);
    margin: 8px;
}

.Swkst {
    margin-block-start: 8px;
}

.eJRpSg {
    display: flex;
    -webkit-box-align: stretch;
    align-items: stretch;
    flex-wrap: wrap;
    margin: -8px;
}

.fPYnfT {
    margin-block-start: 8px;
}

.ePgKnE {
    -webkit-box-flex: 1;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    background: rgb(68, 71, 86);
    color: rgb(129, 129, 131);
    padding: 20px;
    border-radius: 4px;
    font-size: 12px;
}
.jZwtAa {
    margin-block-end: 10px;
}

.gGtRPw {
    display: flex;
}

.jdUsXu {
    width: calc(100% - 105px);
}
.jnAXQp {
    padding-inline-start: 10px;
    display: flex;
    overflow: hidden;
}

.dUSfMf {
    width: 95px;
}
.hUryWa {
    color: rgb(244, 67, 54);
    padding-block-start: 5px;
}

.iIvoLq {
    color: rgb(129, 129, 131);
    font-size: 12px;
    font-weight: 400;
}

.iydyIy {
    margin-block-start: auto;
    width: 100%;
}
.TzEMb {
    -webkit-box-align: center;
    align-items: center;
    color: rgb(129, 129, 131);
    display: flex;
    -webkit-box-flex: 1;
    flex-grow: 1;
    font-size: 12px;
    font-weight: 400;
    -webkit-box-pack: center;
    justify-content: center;
    line-height: 16px;
    margin-block-end: 16px;
    text-align: center;
    width: 100%;
}
.fuDsDw {
    -webkit-box-flex: 1;
    flex-grow: 1;
    position: relative;
    width: 100%;
    background: rgb(68, 71, 86);
    color: rgb(129, 129, 131);
    border-radius: 4px;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    padding: 16px;
    min-height: 188px;
}

.fdmwwf {
    display: flex;
    -webkit-box-align: stretch;
    align-items: stretch;
    flex-wrap: wrap;
    /*flex-direction: column;*/
    margin: -12px;
    margin-block-start: 8px;
}

@media (min-width: 768px) {
    .fdmwwf {
        flex-wrap: column;
    }
    .ZoaOR {
        width: calc(50% - 16px);
        margin: 8px;
    }
    
    .dJnMCc {
        width: calc(50% - 16px);
        margin: 8px;
    }
    .cpnchg {
        width: 50%;
        padding: 8px;
    }
}

@media (min-width: 1200px) {
    .cpnchg {
        width: 33.3333%;
        max-width: 328px;
    }
}