.contact-form .contact-form__submit-button {
    width: 100%;
    margin-top: 12px
}

@media(min-width:720px) {
    .contact-form .contact-form__submit-button {
        margin-left: auto;
        width: auto
    }
}

@media(min-width:960px) {
    .contact-form .contact-form__submit-button {
        margin-left: 0;
        margin-right: auto
    }
}

.image--hidden {
    display: none
}

.link:hover {
    text-decoration: none
}

.link-no-styles:link,
.link-no-styles:visited {
    text-decoration: inherit;
    color: inherit;
    cursor: pointer
}

.btn {
    position: relative;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    align-items: center;
    vertical-align: middle;
    padding: 12px 16px;
    min-height: 40px;
    min-width: 100px;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.2;
    outline: none;
    transition: background-color .3s ease, box-shadow .3s ease, border-color .3s ease;
    color: #fff;
    text-shadow: none;
    border-radius: 8px;
    background-color: #181920;
    margin: 0
}

.btn,
.btn:not(:disabled) {
    cursor: pointer
}

.btn:disabled {
    opacity: .4
}

.btn:active,
.btn:focus,
.btn:hover {
    text-decoration: none;
    outline: none;
    color: #fff
}

.btn--block {
    width: 100%
}

.btn--extra {
    min-height: 56px;
    font-size: 16px
}

.btn--large {
    min-height: 48px;
    font-size: 16px
}

.btn--medium {
    min-height: 40px
}

.btn--small {
    min-height: 36px
}

.btn--outline {
    border: 1px solid hsla(0, 0%, 100%, .12)
}

.btn--outline:active,
.btn--outline:focus,
.btn--outline:hover {
    border: 1px solid hsla(0, 0%, 100%, .2)
}

.btn--primary {
    border: none
}

.btn--primary,
.btn--primary:hover {
    background: linear-gradient(180deg, #47db79, #138f53)
}

.btn--primary:hover {
    box-shadow: 0 12px 24px -8px rgba(51, 214, 114, .2)
}

.btn--primary:active,
.btn--primary:focus {
    box-shadow: none
}

.btn--danger {
    border: none
}

.btn--danger,
.btn--danger:hover {
    background: linear-gradient(180deg, #47db79, #138f53)
}

.btn--danger:hover {
    box-shadow: 0 12px 24px -8px rgba(255, 98, 98, .35)
}

.btn--danger:active,
.btn--danger:focus {
    box-shadow: none
}

.btn--secondary {
    background: #2e313d;
    border: none
}

.btn--secondary:active,
.btn--secondary:focus,
.btn--secondary:hover {
    background: #373a47
}

.link-btn {
    text-decoration: none
}

.link-btn.link-btn--disabled {
    opacity: .4
}

.link-btn.link-btn--disabled:hover {
    box-shadow: none;
    cursor: auto
}

.link-text-btn {
    display: inline-block;
    vertical-align: top;
    padding: 0;
    margin: 0;
    border: none;
    box-shadow: none;
    text-decoration: none;
    line-height: 1;
    color: hsla(0, 0%, 100%, .65);
    background: transparent none;
    outline: none;
    transition: color .3s ease
}

.link-text-btn:not(:disabled) {
    cursor: pointer
}

.link-text-btn:active,
.link-text-btn:focus,
.link-text-btn:hover {
    color: #fff;
    background: transparent none;
    outline: none;
    text-decoration: none
}

.link-text-btn:disabled {
    opacity: .5
}

.landing-game-slider.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent
}

.landing-game-slider.slick-slider .slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0
}

.landing-game-slider.slick-slider .slick-list:focus {
    outline: none
}

.landing-game-slider.slick-slider .slick-list.dragging {
    cursor: pointer;
    cursor: hand
}

.landing-game-slider.slick-slider .slick-list,
.landing-game-slider.slick-slider .slick-track {
    transform: translateZ(0)
}

.landing-game-slider.slick-slider .slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto
}

.landing-game-slider.slick-slider .slick-track:after,
.landing-game-slider.slick-slider .slick-track:before {
    content: "";
    display: table
}

.landing-game-slider.slick-slider .slick-track:after {
    clear: both
}

.slick-loading .landing-game-slider.slick-slider .slick-track {
    visibility: hidden
}

.landing-game-slider.slick-slider .slick-slide {
    padding: 0 4px;
    float: left;
    height: 100%;
    min-height: 1px;
    display: none
}

[dir=rtl] .landing-game-slider.slick-slider .slick-slide {
    float: right
}

.landing-game-slider.slick-slider .slick-slide img:not(.image--hidden) {
    display: block;
    margin: 0 auto
}

.landing-game-slider.slick-slider .slick-slide.slick-loading img {
    display: none
}

.landing-game-slider.slick-slider .slick-slide.dragging img {
    pointer-events: none
}

.landing-game-slider.slick-slider.slick-initialized .slick-slide {
    display: block
}

.landing-game-slider.slick-slider.slick-loading .slick-slide {
    visibility: hidden
}

.landing-game-slider.slick-slider.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent
}

.landing-game-slider.slick-slider .slick-arrow.slick-hidden {
    display: none
}

.landing-game-slider__button {
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 30px;
    text-shadow: 0 0 8px #0075ff, 0 0 16px #0f8cff, 0 0 32px #0075ff, 0 0 4px #1890ff, 0 0 2px #0085ff, 0 4px 24px #0075ff;
    color: #e2faff;
    opacity: .45;
    transition: all .3s ease;
    cursor: pointer
}

.landing-game-slider__button:hover {
    opacity: 1
}

.landing-game-slider__item {
    outline: none;
    margin-bottom: 10px
}

.player-level {
    display: flex;
    align-items: center;
    width: 100%
}

.player-level__levelPlayer-img {
    width: 80px;
    margin-right: 8px
}

@media(min-width:720px) {
    .player-level__levelPlayer-img {
        width: 96px
    }
}

@media(min-width:1248px) {
    .player-level__levelPlayer-img {
        width: 128px
    }
}

.player-level__info-container {
    flex: 1 1 auto
}

.player-level__current-level {
    color: #fff;
    margin-bottom: 8px
}

@media(min-width:720px) {
    .player-level__current-level {
        font-size: 16px;
        line-height: 24px
    }
}

@media(min-width:1248px) {
    .player-level__current-level {
        margin-bottom: 12px
    }
}

.player-level__progress-bar-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 8px
}

@media(min-width:1248px) {
    .player-level__progress-bar-wrapper {
        margin-bottom: 12px
    }
}

.player-level__progress-bar {
    background-color: rgba(26, 28, 36, .8)!important
}

.player-level__progress-bar>div {
    height: 6px;
    background-color: #fff
}

@media(min-width:720px) {
    .player-level__progress-bar>div {
        height: 8px
    }
}

.player-level__next-level-arrow {
    margin: 0 8px;
    width: 8px
}

@media(min-width:720px) {
    .player-level__next-level-arrow {
        width: 10px
    }
}

@media(min-width:1248px) {
    .player-level__next-level-arrow {
        margin: 0 12px
    }
}

.player-level__points {
    color: hsla(0, 0%, 100%, .65)
}

@media(min-width:1248px) {
    .player-level__points {
        font-size: 14px;
        line-height: 20px
    }
}

.player-level__current-points {
    color: #fff
}

.player-level__next-level-wrap {
    position: relative;
    margin-top: 5px
}

.player-level__next-level-number {
    position: absolute;
    top: 17%;
    left: 31%;
    font-size: 20px;
    font-weight: 700
}

@media(min-width:720px) {
    .player-level__next-level-number {
        top: 30%;
        left: 31%;
        font-size: 24px
    }
}

@media(min-width:1248px) {
    .player-level__next-level-number {
        top: 20%;
        left: 34%;
        font-size: 32px
    }
}

.player-level .player-level__next-level-img {
    width: 32px
}

@media(min-width:320px) {
    .player-level .player-level__next-level-img {
        width: 32px
    }
}

@media(min-width:720px) {
    .player-level .player-level__next-level-img {
        margin-top: 8px;
        width: 40px
    }
}

@media(min-width:1248px) {
    .player-level .player-level__next-level-img {
        margin: 0;
        width: 56px
    }
}

.progress-bar {
    background-color: #4a4a4b;
    border-radius: 4px;
    width: 100%
}

.progress-bar__track {
    background-color: #2869ff;
    border-radius: 4px;
    height: 4px;
    transition: width .3s
}

.vip-page__title {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 12px
}

@media(min-width:720px) {
    .vip-page__title {
        font-size: 20px;
        line-height: 30px;
        font-weight: 900
    }
}

.vip-page__block {
    border-radius: 8px;
    background: hsla(0, 0%, 100%, .04);
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px)
}

@media(min-width:1248px) {
    .vip-page__block-cashback-img,
    .vip-page__block-prize-img {
        height: 106px
    }
}

@media(min-width:720px) {
    .vip-page__info {
        display: flex;
        margin-bottom: 40px
    }
}

@media(min-width:1248px) {
    .vip-page__info {
        min-width: 64px
    }
}

.vip-page__level {
    margin-bottom: 24px
}

@media(min-width:720px) {
    .vip-page__level {
        margin: 0 12px 0 0;
        width: 100%
    }
}

.vip-page__level>div {
    padding: 16px 16px 16px 8px
}

@media(min-width:720px) {
    .vip-page__level>div {
        display: flex;
        align-items: center;
        width: 100%;
        height: 152px
    }
}

@media(min-width:1248px) {
    .vip-page__level>div {
        height: 196px
    }
}

.vip-page__prizes {
    margin-bottom: 24px
}

@media(min-width:720px) {
    .vip-page__prizes {
        margin-bottom: 0
    }
}

.vip-page__prizes-wrapper,
.vip-page__prizes-wrapper>div {
    display: flex;
    justify-content: space-between
}

.vip-page__prizes-wrapper>div {
    flex-direction: column;
    align-items: center;
    padding: 20px 32px;
    min-width: 168px;
    height: 152px;
    width: 100%
}

@media(min-width:960px) {
    .vip-page__prizes-wrapper>div {
        min-width: 221px
    }
}

@media(min-width:1248px) {
    .vip-page__prizes-wrapper>div {
        min-width: 288px;
        height: 196px;
        min-width: 318px
    }
}

.vip-page__prizes-wrapper>div:last-child {
    margin-left: 8px
}

.vip-page__all-levels {
    margin-bottom: 64px
}

@media(min-width:720px) {
    .vip-page__all-levels {
        margin-bottom: 70px
    }
}

@media(min-width:960px) {
    .vip-page__all-levels {
        margin-bottom: 72px
    }
}

@media(min-width:1248px) {
    .vip-page__all-levels {
        margin-bottom: 102px
    }
}

.vip-page__slide {
    width: 168px!important;
    outline: none!important
}

@media(min-width:720px) {
    .vip-page__slide {
        width: 162px!important
    }
}

@media(min-width:960px) {
    .vip-page__slide {
        width: 123px!important
    }
}

@media(min-width:1248px) {
    .vip-page__slide {
        width: 162px!important
    }
}

@media(min-width:1632px) {
    .vip-page__slide {
        width: 179px!important
    }
}

.vip-page__slide-top {
    padding: 10px 0 12px;
    margin-bottom: 8px;
    text-align: center;
    border-radius: 8px;
    background: hsla(0, 0%, 100%, .04);
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px)
}

.vip-page__slide-top--active {
    background: hsla(0, 0%, 100%, .08)
}

.vip-page__slide-level {
    color: #fff;
    font-size: 16px;
    line-height: 32px;
    font-weight: 900
}

.vip-page__slide-text {
    color: hsla(0, 0%, 100%, .4);
    font-size: 14px;
    line-height: 20px;
    font-weight: 400
}

.vip-page__slide-info {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 0 57px;
    min-height: 362px;
    /*background-image: url(url(../assets/bg.a0438354.svg);*/
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    border-radius: 8px
}

.vip-page__slide-info--active:before {
    content: "";
    top: 0;
    position: absolute;
    width: 100%;
    height: 98%;
    background: linear-gradient(180deg, rgba(0, 143, 255, .0001) .13%, rgba(0, 143, 255, .121886) 45.1%, rgba(0, 143, 255, .0001))
}

.vip-page__slide-prize-img {
    margin-bottom: 8px
}

.vip-page__slide-divider {
    margin: 24px 0 28px;
    width: 100%;
    height: 1px;
    background: linear-gradient(270deg, hsla(0, 0%, 100%, .0001), hsla(0, 0%, 100%, .12) 49.49%, hsla(0, 0%, 100%, .0001))
}

.vip-page__slide-cashback-img {
    margin-bottom: 12px
}

.vip-page__maxlevel-wrap {
    position: relative;
    width: 58%;
    margin: 0 auto;
    display: grid;
    justify-items: center;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: " boxleft  main boxRight "
}

@media(max-width:1248px) {
    .vip-page__maxlevel-wrap {
        width: 84%
    }
}

@media(max-width:960px) {
    .vip-page__maxlevel-wrap {
        width: 100%;
        grid-template-areas: " main main" "boxRight boxleft";
        grid-template-columns: 1fr 1fr
    }
}

.vip-page__maxlevel-boxRight {
    margin-top: 50%;
    position: relative
}

@media(max-width:960px) {
    .vip-page__maxlevel-boxRight {
        margin-top: -60%;
        grid-area: boxRight
    }
}

.vip-page__maxlevel-boxleft {
    position: relative;
    margin-top: 50%
}

@media(max-width:960px) {
    .vip-page__maxlevel-boxleft {
        margin-top: -60%;
        grid-area: boxleft
    }
}

@media(max-width:960px) {
    .vip-page__maxlevel-img {
        height: 165px
    }
}

.vip-page__maxlevel-mainPrize {
    grid-area: main
}

.vip-page__maxlevel-text {
    position: absolute;
    bottom: 50px;
    font-weight: 900;
    font-size: 20px;
    line-height: 30px
}

@media(max-width:960px) {
    .vip-page__maxlevel-text {
        top: 0;
        bottom: auto
    }
}

.vip-page__maxlevel-prizeWrap {
    position: absolute;
    text-align: center;
    top: 40px;
    left: 33px;
    font-size: 14px
}

@media(max-width:960px) {
    .vip-page__maxlevel-prizeWrap {
        top: 24px;
        left: 19px
    }
}

.vip-page__maxlevel-prizeWrapLeft {
    position: absolute;
    text-align: center;
    top: 43px;
    left: 29px;
    font-size: 14px
}

@media(max-width:960px) {
    .vip-page__maxlevel-prizeWrapLeft {
        top: 24px;
        left: 16px
    }
}

.option--highlighted,
.option--selected {
    color: #fff
}

.filter-item {
    display: block;
    width: 100%;
    padding: 8px 0;
    margin: 0;
    line-height: 1
}

.filter-item:first-child {
    padding-top: 0
}

.filter-item__name {
    flex: 1;
    width: 100%;
    display: inline-block;
    vertical-align: top;
    overflow: hidden
}

.filter-item__link {
    display: inline-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: auto;
    max-width: 100%;
    min-height: 16px;
    padding: 0 5px 0 0;
    margin: 0;
    text-decoration: none;
    color: #999;
    font-size: 12px;
    line-height: 1.2;
    text-align: left;
    font-weight: 600;
    letter-spacing: .12px;
    text-transform: uppercase;
    transition: color .3s ease
}

.filter-item__link:active,
.filter-item__link:focus,
.filter-item__link:hover {
    color: #999;
    cursor: pointer;
    outline: none;
    text-decoration: none
}

.filter-item--checked .filter-item__link,
.filter-item__link:hover,
.option {
    color: #fff
}

.option {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 8px 16px;
    margin: 0;
    text-shadow: none;
    outline: none;
    text-decoration: none;
    transition: color .3s ease, background-color .3s ease
}

.option:first-of-type {
    margin-top: 0
}

.option:last-of-type {
    margin-bottom: 0
}

.option:only-child {
    margin: 0
}

.option--highlighted,
.option--selected,
.option-link--highlighted,
.option-link--selected {
    color: #fff
}

.option-link {
    color: #fff;
    font-weight: 400;
    cursor: pointer;
    overflow: hidden;
    padding: 12px 16px;
    white-space: normal;
    border-radius: 4px;
    display: flex;
    align-items: center
}

.option-link:first-of-type {
    margin-top: 10px
}

.option-link:last-of-type {
    margin-bottom: 10px
}

.option-link:only-child {
    margin: 10px 0
}

.checkbox,
.option-link__content {
    display: flex
}

.checkbox {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding: 0;
    margin: 0
}

.checkbox__input {
    position: absolute;
    opacity: 0;
    height: 0;
    margin: 0;
    display: none
}

.checkbox__block {
    display: inline-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    width: auto;
    padding: 0;
    margin: 0;
    cursor: pointer
}

.checkbox__block:focus {
    outline: none
}

.checkbox__point {
    display: inline-flex;
    position: relative;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
    border-radius: 2px;
    overflow: hidden;
    padding: 0;
    margin: 0 12px 0 0;
    background: transparent;
    border: 2px solid #373a47;
    transition: background-color .3s ease, border-color .3s ease, color .3s ease;
    box-shadow: none;
    text-shadow: none
}

.checkbox__block:hover .checkbox__point {
    background: tranparent none;
    border-color: #4d5264
}

.checkbox__point:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /*background-image: url(url(../assets/check.f8240628.svg);*/
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: auto 10px;
    transition: opacity .3s ease;
    opacity: 0
}

.checkbox__label {
    display: inline-flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    width: 100%;
    padding: 0;
    margin: 0;
    font-size: 14px;
    line-height: 20px;
    color: hsla(0, 0%, 100%, .65);
    font-weight: 500;
    text-transform: none;
    text-align: left;
    transition: color .3s ease
}

.checkbox__block:hover .checkbox__label {
    color: #fff
}

.checkbox__label a {
    font-size: 14px
}

.checkbox__label sup {
    top: 0;
    color: #33d672
}

.checkbox__input:checked+.checkbox__block .checkbox__point {
    background: #33d672 none;
    border-color: #33d672
}

.checkbox__input:checked+.checkbox__block .checkbox__point:before {
    opacity: 1
}

.checkbox__input:checked+.checkbox__block .checkbox__label {
    color: #fff
}

.checkbox__input:checked+.checkbox__block:hover .checkbox__point {
    background: #33d672 none;
    border-color: #33d672
}

.checkbox__input:checked+.checkbox__block:hover .checkbox__label {
    color: #fff
}

.checkbox--disabled,
.checkbox[disabled] {
    cursor: not-allowed
}

.checkbox--disabled .checkbox__block,
.checkbox[disabled] .checkbox__block {
    pointer-events: none
}

.checkbox--disabled .checkbox__point,
.checkbox[disabled] .checkbox__point {
    background: tranparent none;
    border-color: #373a47
}

.checkbox--disabled .checkbox__label,
.checkbox[disabled] .checkbox__label {
    color: #999
}

.checkbox--disabled .checkbox__input:checked+.checkbox__block .checkbox__point,
.checkbox[disabled] .checkbox__input:checked+.checkbox__block .checkbox__point {
    background: rgba(51, 214, 114, .8) none;
    border-color: #33d672
}

.checkbox--disabled .checkbox__input:checked+.checkbox__block .checkbox__label,
.checkbox[disabled] .checkbox__input:checked+.checkbox__block .checkbox__label {
    color: #999
}

.input {
    position: relative;
    margin: auto;
    width: 100%;
    border-radius: 8px;
    background-color: #2e313d;
    overflow: hidden;
    display: flex;
    align-items: center
}

.input input,
.input textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    border: 0;
    font-family: inherit;
    padding: 16px 16px 0;
    height: 56px;
    font-size: 16px;
    font-weight: 400;
    background: #2e313d;
    color: #fff;
    transition: all .15s ease
}

.input input:hover,
.input textarea:hover {
    background: rgba(46, 49, 61, .04)
}

.input input:not(:-moz-placeholder-shown)+.input__label,
.input textarea:not(:-moz-placeholder-shown)+.input__label {
    transform: translate3d(0, -6px, 0);
    font-size: 12px
}

.input input:not(:-ms-input-placeholder)+.input__label,
.input textarea:not(:-ms-input-placeholder)+.input__label {
    transform: translate3d(0, -6px, 0);
    font-size: 12px
}

.input input:not(:placeholder-shown)+.input__label,
.input textarea:not(:placeholder-shown)+.input__label {
    transform: translate3d(0, -6px, 0);
    font-size: 12px
}

.input input:focus,
.input textarea:focus {
    background: rgba(46, 49, 61, .05);
    outline: none
}

.input input:focus+.input__label,
.input textarea:focus+.input__label {
    transform: translate3d(0, -6px, 0);
    font-size: 12px
}

.input input:focus+.input__label+.input__focus,
.input textarea:focus+.input__label+.input__focus {
    transform: scaleX(1);
    transition: all .1s ease
}

.input input:-webkit-autofill,
.input textarea:-webkit-autofill {
    box-shadow: inset 0 0 0 100px #2e313d!important;
    color: #fff!important;
    -webkit-text-fill-color: #fff!important;
    background: #2e313d!important;
    border: none!important;
    border-radius: 0!important;
    -webkit-transition: color .3s ease, background-color .3s ease, border-color .3s ease!important;
    transition: color .3s ease, background-color .3s ease, border-color .3s ease!important;
    background-clip: content-box!important
}

.input,
.input-search {
    position: relative;
    margin: auto;
    width: 100%;
    border-radius: 8px;
    background-color: #2e313d;
    overflow: hidden;
    display: flex;
    align-items: center
}

.input__label {
    position: absolute;
    top: 16px;
    left: 16px;
    font-size: 16px;
    color: hsla(0, 0%, 100%, .4);
    font-weight: 400;
    transform-origin: 0 0;
    transform: translateZ(0);
    transition: all .2s ease;
    pointer-events: none
}

.input__focus {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(46, 49, 61, .05);
    z-index: -1;
    transform: scaleX(0);
    transform-origin: left
}

.input-search input,
.input-search textarea,
.input input,
.input textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    border: 0;
    font-family: inherit;
    padding: 16px 16px 0;
    height: 56px;
    font-size: 16px;
    font-weight: 400;
    background: #2e313d;
    color: #fff;
    transition: all .15s ease
}

.input-search input:hover,
.input-search textarea:hover,
.input input:hover,
.input textarea:hover {
    background: rgba(46, 49, 61, .04)
}

.input-search input:not(:-moz-placeholder-shown)+.input__label,
.input-search textarea:not(:-moz-placeholder-shown)+.input__label,
.input input:not(:-moz-placeholder-shown)+.input__label,
.input textarea:not(:-moz-placeholder-shown)+.input__label {
    transform: translate3d(0, -6px, 0);
    font-size: 12px
}

.input-search input:not(:-ms-input-placeholder)+.input__label,
.input-search textarea:not(:-ms-input-placeholder)+.input__label,
.input input:not(:-ms-input-placeholder)+.input__label,
.input textarea:not(:-ms-input-placeholder)+.input__label {
    transform: translate3d(0, -6px, 0);
    font-size: 12px
}

.input-search input:not(:placeholder-shown)+.input__label,
.input-search textarea:not(:placeholder-shown)+.input__label,
.input input:not(:placeholder-shown)+.input__label,
.input textarea:not(:placeholder-shown)+.input__label {
    transform: translate3d(0, -6px, 0);
    font-size: 12px
}

.input-search input:focus,
.input-search textarea:focus,
.input input:focus,
.input textarea:focus {
    background: rgba(46, 49, 61, .05);
    outline: none
}

.input-search input:focus+.input__label,
.input-search textarea:focus+.input__label,
.input input:focus+.input__label,
.input textarea:focus+.input__label {
    transform: translate3d(0, -6px, 0);
    font-size: 12px
}

.input-search input:focus+.input__label+.input__focus,
.input-search textarea:focus+.input__label+.input__focus,
.input input:focus+.input__label+.input__focus,
.input textarea:focus+.input__label+.input__focus {
    transform: scaleX(1);
    transition: all .1s ease
}

.input-search input:-webkit-autofill,
.input-search textarea:-webkit-autofill,
.input input:-webkit-autofill,
.input textarea:-webkit-autofill {
    box-shadow: inset 0 0 0 100px #2e313d!important;
    color: #fff!important;
    -webkit-text-fill-color: #fff!important;
    background: #2e313d!important;
    border: none!important;
    border-radius: 0!important;
    -webkit-transition: color .3s ease, background-color .3s ease, border-color .3s ease!important;
    transition: color .3s ease, background-color .3s ease, border-color .3s ease!important;
    background-clip: content-box!important
}

.input--phone {
    transition: background-color .15s ease
}

.input--phone:hover {
    background-color: #373a47
}

.input--phone .input__left-container {
    position: absolute;
    top: 30px;
    left: 16px;
    pointer-events: none
}

.input--phone .input__left-container img {
    display: block;
    width: 16px;
    height: 8px;
    min-width: 1px;
    -o-object-fit: cover;
    object-fit: cover
}

.input--phone .input__native {
    padding: 13px 0 0 40px;
    transition: inherit
}

.input--phone .input__native:hover {
    background-color: inherit
}

.input-search .input__native {
    padding: 0 16px 0 48px
}

@media(min-width:960px) {
    .input-search .input__native {
        padding: 0 56px
    }
}

.input-search__search-icon {
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
    z-index: 1;
    font-size: 24px
}

.input-v2 {
    cursor: text;
    border-radius: 8px;
    background: transparent;
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 12px;
    color: #fff
}

.input-v2__left-container {
    display: flex;
    margin-right: 12px
}

.input-v2__right-container {
    display: flex;
    margin-left: 12px
}

.input-v2--default .input-v2__native+.input-v2__focus-container {
    border: 1px solid #292a2e
}

.input-v2--default .input-v2__native:focus+.input-v2__focus-container {
    border: 1px solid #4a4a4b
}

.input-v2--error .input-v2__native+.input-v2__focus-container {
    border: 1px solid #b02f0d
}

.input-v2--error .input-v2__native:focus+.input-v2__focus-container {
    border: 1px solid #f05b33
}

.input-v2--warning .input-v2__native+.input-v2__focus-container {
    border: 1px solid #995900
}

.input-v2--warning .input-v2__native:focus+.input-v2__focus-container {
    border: 1px solid #edd3b0
}

.input-v2--success .input-v2__native+.input-v2__focus-container {
    border: 1px solid #1d4401
}

.input-v2--success .input-v2__native:focus+.input-v2__focus-container {
    border: 1px solid #48a902
}

.input-v2__native {
    width: 100%;
    min-width: 0;
    height: 48px;
    font-weight: 400;
    padding: 8px 0;
    border: none;
    font-size: 14px;
    -moz-appearance: textfield
}

.input-v2__native,
.input-v2__native:focus {
    outline: none;
    background-color: transparent;
    box-shadow: none;
    color: #fff
}

.input-v2__focus-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    pointer-events: none;
    border-radius: 4px;
    transition: .3s
}

.input-v2--disabled {
    color: #818183;
    background: #1a1a1e
}

.select {
    display: block;
    position: relative;
    width: 100%;
    padding: 0;
    margin: 0;
    font-size: 14px;
    line-height: 1.2;
    text-align: left;
    color: #fff;
    font-weight: 600
}

.select .input__native:hover {
    background-color: #373a47
}

.select__arrow-icon-wrapper {
    position: absolute;
    right: 15px;
    top: 50%;
    cursor: pointer;
    font-size: 8px;
    color: hsla(0, 0%, 100%, .4);
    transform-origin: center;
    transform: rotate(0deg);
    transition: transform .3s;
    transform: translateY(-50%) rotate(0deg)
}

.select__arrow-icon-wrapper--open {
    color: hsla(0, 0%, 100%, .65);
    transform: translateY(-50%) rotate(180deg)
}

.select-game-currency__list {
    scrollbar-color: #fff;
    scrollbar-width: thin
}

.select-game-currency__list::-webkit-scrollbar {
    width: 2px;
    height: 2px
}

.select-game-currency__list::-webkit-scrollbar-track {
    border-radius: 2px;
    background: hsla(0, 0%, 60%, .5)
}

.select-game-currency__list::-webkit-scrollbar-thumb {
    background: #fff;
    border-radius: 2px;
    margin: 0
}

.select-game-currency__list::-webkit-scrollbar-thumb:hover {
    background: #fff
}

.select-game-currency {
    position: relative;
    padding: 0 12px;
    background-color: transparent;
    border-radius: 8px;
    cursor: pointer;
    color: #fff;
    z-index: 50
}

.select-game-currency__button {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%
}

.select-game-currency__arrow-down-wrapper {
    display: inline-flex;
    align-items: center;
    font-size: 16px;
    margin: 0 0 0 4px
}

.select-game-currency__arrow-down {
    color: hsla(0, 0%, 100%, .4);
    transform-origin: center;
    transform: rotate(0deg);
    transition: transform .3s
}

.select-game-currency__arrow-down--open {
    transform: rotate(180deg)
}

.select-game-currency__container {
    position: absolute;
    top: 100%;
    right: 0;
    min-width: 100px;
    width: auto;
    display: none;
    vertical-align: top;
    padding: 0;
    margin-top: 6px;
    border-radius: 8px;
    background-color: #2e313d;
    box-shadow: 0 6px 12px rgba(0, 0, 0, .25);
    z-index: 2
}

.select-game-currency--open .select-game-currency__container {
    display: inline-block
}

.select-game-currency__list {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    vertical-align: top;
    padding: 5px 0;
    min-width: 100%;
    overflow: hidden;
    overflow-y: auto
}

.select-game-currency__link,
.select-game-currency__list {
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin: 0;
    width: 100%
}

.select-game-currency__link {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    height: 40px;
    color: #fff;
    text-decoration: none;
    outline: none;
    background-color: transparent;
    transition: background-color .3s ease;
    white-space: nowrap;
    cursor: pointer
}

.select-game-currency__link.option-link {
    padding: 8px 16px;
    border-radius: 0
}

.select-game-currency__link.option-link>div {
    align-items: center
}

.select-game-currency__link.option-link:first-of-type,
.select-game-currency__link.option-link:last-of-type {
    margin: 0
}

.select-game-currency__link:active,
.select-game-currency__link:focus,
.select-game-currency__link:hover {
    text-decoration: none;
    outline: none
}

.select-game-currency__link:hover {
    background-color: #373a47;
    color: #fff
}

.select-game-currency__button .select-game-currency__link {
    padding: 0
}

.select-game-currency__button .select-game-currency__link:hover {
    background-color: transparent
}

.select-game-currency__link-select {
    font-size: 16px;
    font-weight: 700
}

.select-game-currency__icon-check {
    color: #33d672;
    margin-left: 6px
}

.select-game-currency .option-link--selected {
    font-weight: 700
}

.select-game-currency .option-link__content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    font-size: 14px;
    line-height: 20px
}

@media(min-width:720px) {
    .select-game-currency .option-link__content {
        font-size: 16px;
        line-height: 24px
    }
}

.game-features-select {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    position: relative;
    text-transform: uppercase;
    margin: 0;
    font-size: 12px;
    line-height: 1.2;
    text-align: left;
    font-weight: 600;
    letter-spacing: .12px;
    width: 100%
}

@media(min-width:960px) {
    .game-features-select {
        padding: 0;
        align-items: flex-start
    }
}

.game-features-select__button {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    cursor: pointer;
    white-space: nowrap;
    transition: color .3s;
    margin-right: auto;
    padding: 14px 13px 6px;
    width: 100%;
    height: 100%
}

.game-features-select__button,
.game-features-select__button:hover {
    color: #fff
}

@media(min-width:720px) {
    .game-features-select__button {
        display: flex
    }
}

@media(min-width:600px) {
    .game-features-select__button {
        flex-direction: row;
        align-items: center;
        padding-top: 6px
    }
}

@media(min-width:960px) {
    .game-features-select__button {
        display: inline-flex;
        flex-direction: column;
        margin: 0;
        color: hsla(0, 0%, 100%, .65);
        padding: 0
    }
}

@media(min-width:1632px) {
    .game-features-select__button {
        flex-direction: row
    }
}

.game-features-select__button>div {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center
}

.game-features-select__button-icon,
.game-features-select__button:hover .game-features-select__button-icon {
    color: #3ecdf1;
    opacity: 1
}

.game-features-select__button-icon {
    font-size: 24px;
    margin-right: 10px;
    transition: all .3s
}

@media(min-width:320px) {
    .game-features-select__button-icon {
        margin-bottom: 0
    }
}

@media(min-width:960px) {
    .game-features-select__button-icon {
        color: #3ecdf1;
        opacity: .65
    }
}

.game-features-select__button-inner {
    font-size: 16px;
    font-weight: 400;
    text-transform: capitalize
}

@media(min-width:320px) {
    .game-features-select__button-inner {
        padding-top: 6px
    }
}

@media(min-width:600px) {
    .game-features-select__button-inner {
        padding-top: 0
    }
}

@media(min-width:960px) {
    .game-features-select__button-inner {
        margin: 0;
        padding: 0;
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase
    }
}

@media(min-width:960px)and (min-width:1248px) {
    .game-features-select__button-inner {
        font-size: 14px
    }
}

@media(min-width:960px)and (min-width:1632px) {
    .game-features-select__button-inner {
        font-size: 16px
    }
}

.game-features-select__caret {
    display: none;
    color: hsla(0, 0%, 100%, .4)
}

@media(min-width:960px) {
    .game-features-select__caret {
        display: inline-block;
        vertical-align: top;
        font-size: 12px;
        width: auto;
        margin: 0 0 0 4px;
        transform-origin: center;
        transform: rotate(0deg);
        transition: all .3s ease
    }
}

.game-features-select__caret i {
    display: block
}

.game-features-select--open .game-features-select__caret {
    transform: rotate(180deg)
}

.game-features-select__content {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    width: 100%;
    margin: 8px 0 0;
    border-radius: 12px;
    overflow: hidden;
    background-color: #262933;
    z-index: 6
}

.game-features-select--open .game-features-select__content {
    display: block
}

.game-features-select__list {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    text-align: center
}

@media(min-width:960px) {
    .game-features-select__list {
        background-color: #262933;
        height: 100%
    }
}

.game-features-select__titleMobile {
    font-size: 24px;
    font-weight: 900;
    text-align: center;
    padding: 40px 0 36px
}

.game-features-select__item {
    display: inline-flex;
    align-items: center;
    vertical-align: top;
    width: 100%;
    margin: 0;
    line-height: 1
}

.game-features-select__icon-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
    width: 60px;
    height: 24px
}

.game-features-select__icon {
    max-width: 100%;
    max-height: 100%
}

.game-features-select__link {
    display: inline-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    width: auto;
    width: 100%;
    min-height: 16px;
    padding: 10px 15px;
    margin: 0;
    color: #fff;
    text-transform: none;
    cursor: pointer;
    transition: background-color .3s ease
}

@media(max-width:960px) {
    .game-features-select__link {
        font-size: 16px;
        padding: 10px 22px
    }
}

.game-features-select__link:active,
.game-features-select__link:focus,
.game-features-select__link:hover {
    text-decoration: none;
    color: #999;
    outline: none
}

.game-features-select__link:hover {
    color: #fff;
    background-color: #2e313d
}

.game-features-select__item--current .game-features-select__link {
    color: #fff
}

@media(max-width:961px) {
    .game-features-select--menu {
        padding: 16px;
        background-color: #2e313d;
        border-radius: 8px
    }
}

@media(max-width:961px) {
    .game-features-select--menu .game-features-select__button {
        padding: 0;
        flex-direction: row;
        align-items: center
    }
}

@media(max-width:601px) {
    .game-features-select--menu .game-features-select__button {
        flex-direction: column;
        align-items: flex-start
    }
}

.game-features-select--menu .game-features-select__button-icon {
    margin-right: 16px
}

@media(max-width:601px) {
    .game-features-select--menu .game-features-select__button-icon {
        margin-bottom: 6px
    }
}

@media(max-width:961px) {
    .game-features-select--menu .game-features-select__button-inner {
        font-weight: 400;
        padding-top: 0
    }
}

.game-features-modal {
    top: 0;
    bottom: 0;
    z-index: 105
}

.game-features-modal .modal__content {
    height: 100%
}

@media(min-width:720px) {
    .game-features-modal .modal__content {
        border-radius: 20px;
        height: 920px
    }
}

.game-features-modal .modal__content .text-btn {
    font-size: 24px;
    padding: 22px;
    color: #fff
}

@media(min-width:720px) {
    .modal__content {
        height: auto;
        border-radius: 20px;
        max-width: 480px;
        box-shadow: none
    }
}

.modal {
    position: fixed;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    z-index: 1050;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow: auto
}

.modal--content {
    z-index: 99
}

.modal--content,
.modal--content .modal__backdrop {
    top: 56px;
    bottom: 56px
}

.modal--content .modal__content {
    height: 100%;
    box-shadow: none;
    border-radius: 0;
    overflow: auto
}

.modal__backdrop {
    position: fixed;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: rgba(0, 0, 0, .85);
    opacity: 0;
    transition-property: opacity
}

.modal__content {
    position: relative;
    width: 100%;
    margin: auto;
    height: 100%;
    border-radius: 0;
    background-color: #1c1e26;
    box-shadow: 0 30px 60px rgba(0, 0, 0, .75);
    transform: translateY(-20px);
    transition-property: opacity, transform;
    overflow: auto
}

@media(min-width:720px) {
    .modal__content {
        max-width: 480px;
        height: auto;
        border-radius: 20px;
        box-shadow: none
    }
}

.modal .modal__close-button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 24px;
    font-size: 14px;
    color: #fff;
    transition: color .3s ease;
    z-index: 10
}

.modal .modal__close-button:hover {
    color: #fff
}

@media(min-width:720px) {
    .modal .modal__close-button {
        color: hsla(0, 0%, 100%, .4)
    }
}

.modal--entered .modal__backdrop {
    opacity: .7;
    transition-timing-function: ease-in
}

.modal--entered .modal__content {
    transform: translateY(0);
    opacity: 1;
    transition-timing-function: ease-in
}

.modal--exiting .modal__backdrop,
.modal--exiting .modal__content {
    opacity: 0;
    transition-timing-function: ease-out
}

.modal--exiting .modal__content {
    transform: translateY(20px)
}

.sign-menu .link:not(.top-menu__link--current) {
    color: hsla(0, 0%, 100%, .65);
    background-color: transparent
}

.sign-menu .link:not(.top-menu__link--current):hover {
    color: #fff
}

.game {
    --game-tile-offset: 28px;
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;
    border-radius: 8px;
    overflow: hidden;
    padding: 100% 0 0
}

.game--lg {
    --game-tile-offset: 32px
}

.game__wrap {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: var(--game-tile-offset)
}

.game--xs .game__wrap {
    padding: 12px
}

.game--sm .game__wrap {
    padding-left: 12px;
    padding-right: 12px
}

.game--md .game__wrap {
    padding-left: 16px;
    padding-right: 16px
}

.game__wrap>* {
    z-index: 1
}

.game__wrap:before {
    content: "";
    transition: opacity .3s ease;
    opacity: 0;
    border-radius: 8px
}

@media(min-width:720px) {
    .game__wrap:hover:before {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(24, 25, 32, .65), rgba(24, 25, 32, .95));
        z-index: 1;
        opacity: 1
    }
    .game__wrap:hover .game__action,
    .game__wrap:hover .game__content,
    .game__wrap:hover .game__label-list {
        opacity: 1
    }
}

@media(min-width:720px)and (min-width:1248px) {
    .game__wrap:hover .game__favorite {
        opacity: 1
    }
}

.game__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    -o-object-fit: cover;
    object-fit: cover;
    z-index: 0
}

.game__action {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    justify-content: space-between
}

.game__content {
    margin-bottom: 12px
}

.game--sm .game__content,
.game--xs .game__content {
    margin-bottom: 10px
}

.game__action,
.game__content,
.game__label-list {
    transition: opacity .3s ease;
    opacity: 0
}

.game__favorite {
    display: block;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 11px;
    right: 10px;
    border: none;
    box-shadow: none;
    text-shadow: none;
    outline: none;
    font-size: 20px;
    line-height: 1;
    color: #999;
    transition: color .3s ease, opacity .3s ease;
    opacity: 0;
    cursor: pointer
}

.game__favorite:active,
.game__favorite:focus,
.game__favorite:hover {
    outline: none
}

.game__favorite:hover {
    color: #fff
}

.game__favorite-active {
    background: linear-gradient(180deg, #47db79, #138f53);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: #ff5c5c
}

.game__label-list {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: auto;
    position: absolute;
    top: 8px;
    left: 8px;
    transition: opacity .3s ease;
    opacity: 1
}

@media(min-width:1632px) {
    .game__label-list {
        top: 16px;
        left: 16px
    }
}

.game:hover .game__label-list {
    opacity: 0
}

.game__label {
    display: inline-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: auto;
    min-width: 38px;
    min-height: 20px;
    background-color: #32CCF2;
    color: #fff;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    font-weight: 700;
    letter-spacing: .1px;
    text-transform: uppercase;
    padding: 0 5px;
    border-radius: 8px 2px
}

.game__label--top {
    background: linear-gradient(180deg, #47db79, #138f53)
}

.game__label--new {
    background: linear-gradient(189.19deg, #48b2ff 8.27%, #2e55df 94.34%)
}

.game__label+.game__label {
    margin-left: 8px
}

.game__title {
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-align: center;
    line-height: 1.5;
    font-weight: 700;
    letter-spacing: .01em;
    color: #fff;
    margin: 0 0 4px
}

.game--xs .game__title {
    font-size: 12px
}

.game--lg .game__title {
    font-size: 16px
}

.game__link {
    font-size: inherit;
    line-height: inherit
}

@media(max-width:721px) {
    .game__link:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%
    }
}

.game__provider {
    padding: 0;
    margin: 0;
    color: hsla(0, 0%, 100%, .65);
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    line-height: 1.33;
    letter-spacing: .01em;
    text-transform: uppercase
}

.game--sm .game__provider,
.game--xs .game__provider {
    display: none
}

.game__play {
    padding: 0;
    width: 42px;
    height: 42px
}

.game--lg .game__play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 57px;
    height: 57px
}

.game__demo {
    position: absolute;
    bottom: var(--game-tile-offset)
}

.game--sm .game__demo,
.game--xs .game__demo {
    font-size: 12px;
    padding: 6px 12px;
    bottom: 4px
}

.game--md .game__demo {
    bottom: 12px
}

.game__play {
    font: inherit;
    background: none;
    border: none
}

.game__button {
    cursor: pointer;
    transition: filter .3s ease;
    width: inherit;
    height: inherit
}

.game__button:hover {
    filter: drop-shadow(0 0 32px rgba(255, 27, 40, .45))
}

.game--user .game__button--demo {
    display: none
}

@media(min-width:1248px) {
    .game--user .game__button--demo {
        display: flex
    }
}

.game--banner {
    display: flex;
    width: 100%;
    max-height: 100%;
    align-self: stretch
}

.game--banner .game__img {
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center
}

.game--banner .game__favorite {
    display: none
}

.game-slider .game .game__title {
    font-size: 20px;
    max-height: 48px;
    padding: 0
}

@media(min-width:1632px) {
    .game-slider .game .game__title {
        font-size: 24px;
        max-height: 60px
    }
}

.game-slider .game .game__buttons {
    margin-top: 8px
}

@media(min-width:1632px) {
    .game-slider .game .game__buttons {
        margin-top: 38px
    }
}

.game-slider .game .game__favorite {
    font-size: 20px
}

@media(min-width:1632px) {
    .game-slider .game .game__favorite {
        top: 15px;
        right: 15px
    }
}

.game-slider .game .game__provider {
    margin-top: 4px;
    font-size: 10px;
    max-height: 12px
}

@media(min-width:1632px) {
    .game-slider .game .game__provider {
        margin-top: 7px;
        font-size: 12px;
        max-height: 16px
    }
}

.game-list__itemList {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 24px;
    margin-left: -16px;
    margin-right: -16px;
    text-decoration: none;
    color: #999;
    font-size: 12px;
    line-height: 1.2;
    text-align: left;
    font-weight: 400;
    letter-spacing: .12px;
    border-radius: 4px;
    transition: background-color .3s ease, color .3s ease
}

@media(min-width:720px) {
    .game-list__itemList {
        font-size: 16px
    }
}

@media(min-width:960px) {
    .game-list__itemList {
        margin: unset
    }
}

.game-list__itemList:hover {
    background-color: #373a47;
    color: #fff
}

.game-list__img {
    display: inline-block;
    vertical-align: top;
    width: 32px;
    height: auto;
    border-radius: 8px;
    margin: 0 16px 0 0
}

@media(max-width:720px) {
    .game-list__img {
        width: 56px
    }
}

@media(min-width:720px) {
    .game-list__img {
        width: 64px
    }
}

.game-list__title {
    color: #fff;
    font-size: 16px;
    text-transform: none
}

.game-list__provider {
    font-size: 14px;
    text-transform: none;
    text-align: left
}

.catalog {
    display: block;
    width: 100%;
    padding: 0;
    margin: 24px 0 0;
    overflow: hidden
}

.catalog__not-found {
    text-transform: uppercase;
    color: #999
}

.catalog__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px
}

@media(min-width:600px) {
    .catalog__list {
        grid-template-columns: repeat(3, 1fr)
    }
}

@media(min-width:720px) {
    .catalog__list {
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 12px
    }
}

@media(min-width:960px) {
    .catalog__list {
        grid-template-columns: repeat(6, 1fr)
    }
}

@media(min-width:1248px) {
    .catalog__list {
        grid-gap: 16px
    }
}

.catalog__list-item:first-child {
    grid-column-start: 1;
    grid-column-end: 3
}

@media(min-width:600px) {
    .catalog__list-item:first-child {
        grid-row-start: 1;
        grid-row-end: 3
    }
}

.catalog__item {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    width: 100%;
    padding: 0;
    margin: 0
}

.catalog__more {
    display: block;
    width: 100%;
    padding: 8px 0 0;
    margin: 0;
    text-align: center
}

@media(min-width:720px) {
    .catalog__more {
        padding-top: 16px
    }
}

@media(min-width:960px) {
    .catalog__more {
        padding-top: 24px
    }
}

@media(min-width:1632px) {
    .catalog__more {
        padding-top: 32px
    }
}

@media(max-width:719px) {
    .promo-catalog:not(.promo-catalog--without-banners) .catalog .catalog__item:nth-child(3),
    .promo-catalog:not(.promo-catalog--without-banners) .catalog .catalog__item:nth-child(4),
    .promo-catalog:not(.promo-catalog--without-banners) .catalog .catalog__item:nth-child(11),
    .promo-catalog:not(.promo-catalog--without-banners) .catalog .catalog__item:nth-child(12),
    .promo-catalog:not(.promo-catalog--without-banners) .catalog .catalog__item:nth-child(19),
    .promo-catalog:not(.promo-catalog--without-banners) .catalog .catalog__item:nth-child(20),
    .promo-catalog:not(.promo-catalog--without-banners) .catalog .catalog__item:nth-child(27),
    .promo-catalog:not(.promo-catalog--without-banners) .catalog .catalog__item:nth-child(28) {
        margin-top: calc(100% + 8px)
    }
}

@media(min-width:720px)and (max-width:959px) {
    .promo-catalog:not(.promo-catalog--without-banners) .catalog .catalog__item:first-child,
    .promo-catalog:not(.promo-catalog--without-banners) .catalog .catalog__item:nth-child(25) {
        grid-column: span 3;
        padding-left: calc(66.66667% + 5.33333px)
    }
    .promo-catalog:not(.promo-catalog--without-banners) .catalog .catalog__item:nth-child(24),
    .promo-catalog:not(.promo-catalog--without-banners) .catalog .catalog__item:nth-child(48) {
        grid-column: span 3;
        padding-right: calc(66.66667% + 5.33333px)
    }
}

@media(min-width:960px)and (max-width:1247px) {
    .promo-catalog:not(.promo-catalog--without-banners) .catalog .catalog__item:first-child,
    .promo-catalog:not(.promo-catalog--without-banners) .catalog .catalog__item:nth-child(25) {
        grid-column: span 3;
        padding-left: calc(66.66667% + 5.33333px)
    }
    .promo-catalog:not(.promo-catalog--without-banners) .catalog .catalog__item:nth-child(24),
    .promo-catalog:not(.promo-catalog--without-banners) .catalog .catalog__item:nth-child(48) {
        grid-column: span 3;
        padding-right: calc(66.66667% + 5.33333px)
    }
}

@media(min-width:1248px) {
    .promo-catalog:not(.promo-catalog--without-banners) .catalog .catalog__item:first-child,
    .promo-catalog:not(.promo-catalog--without-banners) .catalog .catalog__item:nth-child(25) {
        grid-column: span 3;
        padding-left: calc(66.66667% + 5.33333px)
    }
    .promo-catalog:not(.promo-catalog--without-banners) .catalog .catalog__item:nth-child(24),
    .promo-catalog:not(.promo-catalog--without-banners) .catalog .catalog__item:nth-child(48) {
        grid-column: span 3;
        padding-right: calc(66.66667% + 5.33333px)
    }
}

.dynamic-form {
    display: flex;
    padding: 16px
}

.dynamic-form .form-element__error {
    text-align: left
}

.dynamic-form__group-wrapper {
    display: flex;
    flex-basis: 33.3333333333%;
    flex-direction: column;
    width: 100%
}

.dynamic-form__group-wrapper+.dynamic-form__group-wrapper {
    margin-left: 16px
}

.dynamic-form__group-wrapper:only-child {
    flex-basis: 100%
}

.dynamic-form__group-title {
    margin-bottom: 16px
}

.dynamic-form__element+.dynamic-form__element {
    margin-top: 16px
}

.dynamic-form__label {
    margin-bottom: 16px
}

.dynamic-form__buttons-wrapper {
    margin-top: 16px;
    display: flex;
    justify-content: center;
    flex-direction: column-reverse;
    align-items: center
}

@media(min-width:320px) {
    .dynamic-form__buttons-wrapper {
        flex-direction: row
    }
}

.form {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%
}

.form__title {
    text-align: center
}

.form-element {
    display: block;
    width: 100%;
    padding: 0;
    margin-bottom: 12px
}

.form-element__error {
    margin-top: 4px;
    font-size: 12px;
    color: #3ecdf1;
    line-height: 18px
}

.collapse {
    height: 0;
    opacity: 0;
    overflow: hidden;
    transition-property: height, opacity;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1)
}

.collapse--entering {
    opacity: 1
}

.collapse--entered {
    opacity: 1;
    overflow: unset
}

.collapse--exiting {
    height: 0;
    opacity: 0
}

.collapse__content-wrapper {
    display: flex
}

.collapse__content {
    width: 100%
}

.radio {
    display: inline-flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    width: auto;
    max-width: calc(50% - 10px);
    padding: 0;
    margin: 0
}

.radio__input {
    position: absolute;
    opacity: 0;
    height: 0;
    margin: 0;
    display: none
}

.radio__block {
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    width: auto;
    margin: 0;
    cursor: pointer
}

.radio__block,
.radio__point {
    display: inline-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    padding: 0
}

.radio__point {
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 8px 0 0;
    background: tranparent none;
    border: 2px solid #999;
    transition: background-color .3s ease, border-color .3s ease, color .3s ease;
    box-shadow: none
}

.radio__point:before {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: block;
    background: transparent none;
    transition: background-color .3s ease
}

.radio__block:hover .radio__point {
    background: tranparent none;
    border-color: hsla(0, 0%, 100%, .8)
}

.radio__label {
    display: inline-flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    min-height: 16px;
    flex: 1;
    width: 100%;
    padding: 0;
    margin: 0;
    font-size: 12px;
    color: #999;
    font-weight: 600;
    text-transform: none;
    line-height: 1.2;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    word-break: break-all;
    transition: color .3s ease
}

.radio__block:hover .radio__label {
    color: hsla(0, 0%, 100%, .8)
}

.radio__input:checked+.radio__block .radio__point {
    background: tranparent none;
    border-color: #fff
}

.radio__input:checked+.radio__block .radio__point:before {
    background: #fff none
}

.radio__input:checked+.radio__block .radio__label {
    color: #fff
}

.radio__input:checked+.radio__block:hover .radio__point {
    background: tranparent none;
    border-color: #fff
}

.radio__input:checked+.radio__block:hover .radio__point:before {
    background: #fff none
}

.radio__input:checked+.radio__block:hover .radio__label {
    color: #fff
}

.radio--disabled,
.radio[disabled] {
    cursor: not-allowed
}

.radio--disabled .radio__item,
.radio[disabled] .radio__item {
    pointer-events: none
}

.radio--disabled .radio__point,
.radio[disabled] .radio__point {
    background: tranparent none;
    border-color: #999
}

.radio--disabled .radio__label,
.radio[disabled] .radio__label {
    color: #999
}

.radio--disabled .radio__input:checked+.radio__block .radio__point,
.radio[disabled] .radio__input:checked+.radio__block .radio__point {
    background: tranparent none;
    border-color: #999
}

.radio--disabled .radio__input:checked+.radio__block .radio__point:before,
.radio[disabled] .radio__input:checked+.radio__block .radio__point:before {
    background: #999 none
}

.radio--disabled .radio__input:checked+.radio__block .radio__label,
.radio[disabled] .radio__input:checked+.radio__block .radio__label {
    color: #999
}

.radio--error .radio__point {
    border-color: #32CCF2!important
}

.radio--error .radio__label {
    color: #32CCF2!important
}

.radio-group {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding: 0;
    margin: 0
}

.radio-group__radio:not(:first-child) {
    margin-top: 5px
}

.radio-group--inline {
    flex-direction: row
}

.radio-group--inline .radio-group__radio:not(:first-child) {
    margin-top: 0;
    margin-left: 26px
}

.simple-date {
    color: #fff;
    display: flex
}

.simple-date__day,
.simple-date__month {
    margin-right: 24px
}

.bonuses-cards {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%
}

@media(min-width:720px) {
    .bonuses-cards {
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between
    }
}

.bonuses-cards__card {
    position: relative;
    text-align: center;
    border-radius: 12px;
    margin-bottom: 72px
}

.bonuses-cards__card--first {
    background: radial-gradient(69.72% 49.19% at 50% 21.36%, rgba(45, 87, 255, .27) 0, rgba(45, 87, 255, 0) 100%)
}

.bonuses-cards__card--second {
    background: radial-gradient(69.72% 49.19% at 50% 21.36%, rgba(255, 183, 45, .27) 0, rgba(255, 108, 45, 0) 100%)
}

.bonuses-cards__card--third {
    background: radial-gradient(69.72% 49.19% at 50% 21.36%, rgba(45, 255, 129, .15) 0, rgba(45, 255, 116, 0) 100%)
}

.bonuses-cards__card--fourth {
    background: radial-gradient(69.72% 49.19% at 50% 21.36%, rgba(255, 45, 70, .15) 0, rgba(255, 45, 45, 0) 100%)
}

@media(min-width:720px) {
    .bonuses-cards__card {
        width: calc(50% - 6px);
        margin-bottom: 80px
    }
}

@media(min-width:960px) {
    .bonuses-cards__card {
        width: calc(25% - 6px);
        margin-bottom: 90px
    }
}

.bonuses-cards__bg {
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 146px 40px 40px;
    background: rgba(24, 25, 32, .5);
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    height: 100%
}

.bonuses-cards .bonuses-cards__info {
    display: block;
    position: absolute;
    padding: 10px;
    top: 8px;
    right: 8px;
    color: hsla(0, 0%, 100%, .2);
    transition: color .3s ease;
    cursor: pointer;
    font-size: 22px
}

.bonuses-cards .bonuses-cards__info:hover {
    color: hsla(0, 0%, 100%, .65)
}

.bonuses-cards__bgColor {
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    height: 100%;
    border-radius: 12px
}

.bonuses-cards__image {
    position: absolute;
    top: -48px;
    max-width: 195px;
    width: 100%;
    left: 50%;
    transform: translateX(-50%)
}

.bonuses-cards__title {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: .01em;
    text-transform: uppercase;
    color: hsla(0, 0%, 100%, .65);
    margin-bottom: 10px
}

.bonuses-cards__size {
    font-family: Montserrat, Arial, sans-serif;
    font-weight: 900;
    font-size: 40px;
    line-height: 52px
}

.bonuses-cards__description {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 24px
}

.bonuses-cards .bonuses-cards__btn {
    margin: 0 auto;
    width: 97px;
    font-size: 14px
}

.bonuses-items__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto
}

@media(min-width:720px) {
    .bonuses-items__items {
        flex-direction: row
    }
}

.bonuses-items__item {
    display: flex;
    flex-direction: column;
    background-color: #262933;
    border-radius: 12px;
    overflow: hidden;
    max-width: 100%;
    margin: 6px
}

@media(min-width:720px) {
    .bonuses-items__item {
        max-width: 380px
    }
}

.bonuses-items__item-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 16px;
    flex: 1 1 auto
}

.bonuses-items__item-image {
    width: 100%
}

.bonuses-items__item-title {
    white-space: pre-line;
    margin-bottom: 8px;
    font-size: 20px;
    line-height: 26px
}

.bonuses-items__item-description {
    margin-bottom: 24px;
    font-size: 14px;
    line-height: 20px
}

.bonuses-items__item-buttons {
    display: flex;
    align-items: center;
    margin-top: auto;
    font-size: 14px!important
}

.bonuses-items__item-btn {
    font-size: 14px!important
}

.bonuses-items__item .bonuses-items__item-link {
    min-height: 40px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    margin-left: 10px;
    white-space: nowrap;
    font-weight: 700;
    font-size: 14px
}

.collapse-block__toggle {
    display: flex;
    padding: 16px;
    border-radius: 8px;
    align-items: end;
    background-color: #2e313d;
    word-break: break-all;
    cursor: pointer;
    transition: .3s
}

.collapse-block__toggle--open {
    color: #33d672
}

.collapse-block__toggle:hover {
    background-color: #373a47
}

.collapse-block__icon {
    display: flex;
    font-size: 24px;
    margin-right: 16px;
    color: hsla(0, 0%, 100%, .4);
    transition: .3s
}

.collapse-block__icon--open {
    color: #33d672
}

.collapse-block__content {
    margin: 20px 0 32px
}

@media(min-width:960px) {
    .collapse-block__content {
        padding-right: 45px
    }
}

@media(min-width:1248px) {
    .collapse-block__content {
        padding-right: 0
    }
}

@media(min-width:1632px) {
    .collapse-block__content {
        padding-right: 55px
    }
}

.collapse-error {
    text-align: center;
    color: #818183
}

.collapse-error__toggle {
    cursor: pointer;
    font-weight: 700
}

.collapse-error__toggle:focus,
.collapse-error__toggle:hover {
    color: #fff
}

.collapse-error__content {
    margin-top: 16px
}

.container {
    display: block;
    overflow: hidden;
    width: 100%;
    padding: 0 16px;
    margin: 0 auto
}

.container.container--large {
    max-width: 1600px
}

@media(min-width:720px) {
    .container {
        max-width: 1332px;
        padding: 0 16px
    }
}

@media(min-width:960px) {
    .container {
        max-width: 1352px;
        padding: 0 26px;
        overflow: visible
    }
}

@media(min-width:1632px) {
    .container {
        max-width: 1620px;
        padding: 0 26px
    }
}

.container--small {
    max-width: 1336px;
    padding-left: 16px;
    padding-right: 16px
}

@media(min-width:720px) {
    .container--small {
        max-width: 1352px;
        padding-left: 24px;
        padding-right: 24px
    }
}

@media(min-width:960px) {
    .container--small {
        max-width: 1368px;
        padding-left: 32px;
        padding-right: 32px
    }
}

.container__wrapper {
    max-width: 1040px;
    margin: 0 auto
}

.container__wrapper_title {
    font-size: 24px;
    box-shadow: inset 0 -15px 0 #3ecdf1;
    margin-bottom: 10px
}

@media(min-width:720px) {
    .container__wrapper_title {
        font-size: 32px
    }
}

@media(min-width:960px) {
    .container__wrapper_title {
        font-size: 40px
    }
}

.container__wrapper_updated {
    margin-bottom: 40px;
    margin-top: 20px;
    color: hsla(0, 0%, 100%, .65)
}

.container__wrapper h2 {
    font-size: 20px;
    line-height: 30px;
    display: block;
    margin-bottom: 12px
}

.container__wrapper p {
    font-size: 16px;
    line-height: 24px;
    color: hsla(0, 0%, 100%, .65);
    font-family: Roboto, Arial, sans-serif;
    padding-bottom: 28px
}

.container__wrapper span {
    display: block
}

.container__wrapper ul {
    color: hsla(0, 0%, 100%, .65)
}

.page-title {
    margin: 0 0 20px;
    font-size: 40px;
    color: #fff;
    text-align: center;
    text-transform: capitalize
}

@media(max-width:576px) {
    .page-title {
        margin: 0 0 10px;
        font-size: 24px
    }
}

.textarea textarea {
    resize: none;
    padding-top: 28px;
    height: auto
}

.logo {
    display: inline-block;
    vertical-align: top
}

.logo,
.logo:active,
.logo:focus,
.logo:hover {
    text-decoration: none;
    outline: none
}

.logo__img {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    height: auto;
    border: none;
    max-height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    -o-object-position: center;
    object-position: center
}

.tel {
    display: grid;
    grid-template-columns: 35fr 65fr;
    gap: 5px;
    width: 100%
}

.menu-providers {
    align-self: stretch;
    padding: 0;
    color: #fff;
    font-size: 12px;
    line-height: 1.2;
    text-align: left;
    font-weight: 600;
    letter-spacing: .12px
}

.menu-providers,
.menu-providers__button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0
}

.menu-providers__button {
    flex-wrap: nowrap;
    min-height: 40px;
    border-radius: 8px;
    background-color: #2e313d;
    padding: 10px 16px;
    cursor: pointer
}

.menu-providers__button:hover {
    background-color: #373a47
}

.menu-providers--open .menu-providers__button {
    background-image: linear-gradient(180deg, #47db79, #138f53)
}

.menu-providers__caret {
    display: inline-block;
    vertical-align: top;
    font-size: 12px;
    width: auto;
    margin: 0 0 0 30px;
    transform-origin: center;
    transform: rotate(0deg);
    transition: all .3s ease
}

.menu-providers__caret i {
    display: block
}

.menu-providers--open .menu-providers__caret {
    transform: rotate(180deg)
}

.menu-providers__content {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    border-radius: 12px;
    background-color: #262933;
    z-index: 6
}

@media(min-width:320px) {
    .menu-providers__content {
        top: 0;
        left: 0;
        z-index: 100;
        width: 100%
    }
}

@media(min-width:720px) {
    .menu-providers__content {
        top: 48px;
        left: 0;
        margin: 0 auto;
        width: 480px;
        z-index: 99
    }
}

@media(min-width:960px) {
    .menu-providers__content {
        display: none;
        top: 100%;
        left: 0;
        width: 100%;
        z-index: 6
    }
}

@media(min-width:1920px) {
    .menu-providers__content {
        grid-template-columns: repeat(6, 1fr);
        grid-auto-rows: 48px
    }
}

.menu-providers--open .menu-providers__content {
    display: block
}

@media(min-width:720px) {
    .menu-providers--open .menu-providers__content {
        margin: 0 auto;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 101;
        top: auto
    }
}

@media(min-width:960px) {
    .menu-providers--open .menu-providers__content {
        width: 100%;
        top: auto;
        bottom: 0
    }
}

@media(min-width:1248px) {
    .menu-providers--open .menu-providers__content {
        width: 100%
    }
}

@media(min-width:1632px) {
    .menu-providers--open .menu-providers__content {
        bottom: -24px
    }
}

.menu-providers__list {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-auto-rows: 48px;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: 16px;
    border-radius: 12px;
    background-color: #262933;
    z-index: 6
}

@media(max-width:1632px) {
    .menu-providers__list {
        grid-template-columns: repeat(5, 1fr);
        grid-auto-rows: 48px
    }
}

@media(max-width:1248px) {
    .menu-providers__list {
        grid-template-columns: repeat(4, 1fr);
        top: 20px
    }
}

.menu-providers__list-mobile {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: 48px;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    padding: 16px;
    background-color: #262933;
    z-index: 6;
    overflow: scroll
}

.menu-providers__item {
    display: inline-flex;
    align-items: center;
    vertical-align: top;
    line-height: 1;
    margin: 0
}

.menu-providers__icon-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
    width: 60px;
    height: 24px
}

.menu-providers__icon {
    max-width: 100%;
    max-height: 100%
}

.menu-providers__img {
    width: 26px;
    margin-right: 18px
}

.menu-providers__link {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 8px 14px;
    text-decoration: none;
    color: #fff;
    font-size: 14px;
    text-align: left;
    font-weight: 400;
    letter-spacing: .12px;
    transition: background-color .3s;
    width: 100%;
    border-radius: 8px
}

@media(max-width:960px) {
    .menu-providers__link {
        font-size: 16px
    }
}

.menu-providers__link:active,
.menu-providers__link:focus,
.menu-providers__link:hover {
    text-decoration: none;
    color: #999;
    outline: none
}

.menu-providers__link:hover {
    color: #fff;
    background-color: #2e313d;
    width: 100%
}

.menu-providers__item--current .menu-providers__link {
    color: #fff
}

.menu-providers__mobile-title {
    padding-bottom: 18px;
    text-align: center;
    padding-top: 15px;
    font-size: 24px;
    font-weight: 900
}

.menu-providers__mobile-icon {
    position: absolute;
    top: -27px;
    right: 30px
}

.provider-modal {
    top: 0;
    bottom: 0;
    z-index: 100
}

.provider-modal .modal__content {
    height: 100%
}

@media(min-width:720px) {
    .provider-modal .modal__content {
        border-radius: 20px;
        height: 920px
    }
}

.provider-modal .modal__content .text-btn {
    font-size: 24px;
    padding: 22px;
    color: #fff
}

.password-input .password-input__visibility-button {
    padding: 0 16px;
    font-size: 16px;
    vertical-align: middle
}

.select,
.select-games-search-for-modal {
    display: block;
    position: relative;
    width: 100%;
    padding: 0;
    margin: 0;
    font-size: 14px;
    line-height: 1.2;
    text-align: left;
    color: #fff;
    font-weight: 600
}

.select-games-search-for-modal .input__native:hover,
.select .input__native:hover {
    background-color: #373a47
}

.select-games-search-for-modal__arrow-icon-wrapper,
.select__arrow-icon-wrapper {
    position: absolute;
    right: 15px;
    top: 50%;
    cursor: pointer;
    font-size: 8px;
    color: hsla(0, 0%, 100%, .4);
    transform-origin: center;
    transform: rotate(0deg);
    transition: transform .3s;
    transform: translateY(-50%) rotate(0deg)
}

.select-games-search-for-modal__arrow-icon-wrapper--open,
.select__arrow-icon-wrapper--open {
    color: hsla(0, 0%, 100%, .65);
    transform: translateY(-50%) rotate(180deg)
}

.select-games-search-for-modal__dropdown {
    display: block;
    list-style: none;
    width: 100%;
    padding: 0;
    margin-top: 16px;
    border-radius: 8px;
    background-color: #2e313d
}

@media(min-width:960px) {
    .select-games-search-for-modal__dropdown {
        padding: 16px 0
    }
}

.select-games-search-for-modal__dropdown--hide {
    display: none
}

.select-games-search-for-modal__option-image {
    width: 100px;
    height: 50px;
    margin-right: 30px
}

.select-games-search-for-modal .select-games-search-for-modal__option {
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    cursor: pointer
}

.select-games-search-for-modal__option-link {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 16px;
    margin-left: -16px;
    margin-right: -16px;
    text-decoration: none;
    color: #999;
    font-size: 12px;
    line-height: 1.2;
    text-align: left;
    font-weight: 600;
    letter-spacing: .12px;
    text-transform: uppercase;
    border-radius: 4px;
    transition: background-color .3s ease, color .3s ease
}

@media(min-width:720px) {
    .select-games-search-for-modal__option-link {
        font-size: 16px
    }
}

@media(min-width:960px) {
    .select-games-search-for-modal__option-link {
        padding: 16px 40px;
        margin: unset
    }
}

@media(min-width:1248px) {
    .select-games-search-for-modal__option-link {
        padding: 8px 24px
    }
}

.select-games-search-for-modal__option-link:hover {
    background-color: #373a47;
    color: #fff
}

.select-games-search-for-modal__option-title {
    display: block;
    text-transform: none;
    color: #fff
}

.select-games-search-for-modal__option-description {
    display: block;
    text-transform: none;
    color: hsla(0, 0%, 100%, .4)!important
}

.select-games-search-for-modal__option-image {
    display: inline-block;
    vertical-align: top;
    width: 32px;
    height: auto;
    border-radius: 8px;
    margin: 0 16px 0 0
}

@media(min-width:720px) {
    .select-games-search-for-modal__option-image {
        width: 64px
    }
}

.select-games-search-for-modal .search-list__not-found {
    width: 100%;
    padding-top: 24px;
    margin-bottom: 40px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: hsla(0, 0%, 100%, .4);
    font-weight: 400
}

.select-games-search-for-modal .search-list__recomended {
    margin-bottom: 12px;
    padding-left: 24px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #fff
}

.terms-acceptance-with-preview__content-wrapper {
    scrollbar-color: #fff;
    scrollbar-width: thin
}

.terms-acceptance-with-preview__content-wrapper::-webkit-scrollbar {
    width: 2px;
    height: 2px
}

.terms-acceptance-with-preview__content-wrapper::-webkit-scrollbar-track {
    border-radius: 2px;
    background: hsla(0, 0%, 60%, .5)
}

.terms-acceptance-with-preview__content-wrapper::-webkit-scrollbar-thumb {
    background: #fff;
    border-radius: 2px;
    margin: 0
}

.terms-acceptance-with-preview__content-wrapper::-webkit-scrollbar-thumb:hover {
    background: #fff
}

.terms-acceptance-with-preview__content-wrapper {
    display: flex;
    flex-direction: column;
    padding: 0;
    max-height: 250px;
    margin-bottom: 16px;
    overflow-y: auto;
    border-radius: 4px;
    color: #fff;
    background: unset;
    font-weight: 700
}

.terms-acceptance-with-preview__content-wrapper .page-cms__container {
    padding: 0
}

.terms-acceptance-with-preview__content-wrapper .page-cms__title {
    margin: 0
}

.terms-acceptance-with-preview__content-wrapper .page-cms__content h5 {
    margin: 10px 0
}

.terms-acceptance-with-preview .form-element__error {
    text-align: left
}

.terms-acceptance-with-preview__content:first-child {
    padding-bottom: 16px
}

.terms-acceptance-with-preview__content+.terms-acceptance-with-preview__content {
    padding-top: 16px;
    border-top: 2px solid #353639
}

.select-language-icons-with-code__list {
    scrollbar-color: #fff;
    scrollbar-width: thin
}

.select-language-icons-with-code__list::-webkit-scrollbar {
    width: 2px;
    height: 2px
}

.select-language-icons-with-code__list::-webkit-scrollbar-track {
    border-radius: 2px;
    background: hsla(0, 0%, 60%, .5)
}

.select-language-icons-with-code__list::-webkit-scrollbar-thumb {
    background: #fff;
    border-radius: 2px;
    margin: 0
}

.select-language-icons-with-code__list::-webkit-scrollbar-thumb:hover {
    background: #fff
}

.select-language-icons-with-code,
.select-language-icons-with-code__button {
    display: inline-flex;
    position: relative;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    padding: 0;
    margin: 0;
    width: auto
}

.select-language-icons-with-code__button {
    flex-wrap: nowrap
}

.select-language-icons-with-code__button .select-language-icons-with-code__icon {
    margin-right: 0
}

.select-language-icons-with-code__button .select-language-icons-with-code__link {
    padding: 0 12px
}

.select-language-icons-with-code__button .select-language-icons-with-code__link:hover {
    background-color: transparent
}

.select-language-icons-with-code__caret {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: auto;
    font-size: 5px;
    margin: 0 0 0 4px;
    color: #fff;
    transform-origin: center;
    transform: rotate(0deg);
    transition: transform .3s
}

.select-language-icons-with-code__caret--open {
    color: #adadad;
    transform: rotate(180deg)
}

.select-language-icons-with-code__container {
    position: absolute;
    top: 100%;
    right: 0;
    width: auto;
    min-width: 100%;
    display: none;
    vertical-align: top;
    padding: 0;
    margin-top: 6px;
    border-radius: 8px;
    background-color: #2e313d;
    box-shadow: 0 6px 12px rgba(0, 0, 0, .25);
    z-index: 1
}

.select-language-icons-with-code--open .select-language-icons-with-code__container {
    display: inline-block
}

.select-language-icons-with-code__list {
    display: inline-flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    vertical-align: top;
    padding: 8px 0;
    margin: 0;
    width: 216px;
    min-width: 100%;
    overflow: hidden;
    overflow-y: auto
}

.select-language-icons-with-code__item {
    display: block;
    width: 100%;
    padding: 0;
    margin: 0
}

.select-language-icons-with-code__link {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 7px 14px;
    height: 40px;
    margin: 0;
    text-decoration: none;
    outline: none;
    transition: background-color .3s ease, color .3s ease;
    cursor: pointer;
    color: #fff
}

.select-language-icons-with-code__link.option-link {
    padding: 8px 16px;
    border-radius: 0;
    font-size: 14px
}

.select-language-icons-with-code__link.option-link:first-of-type,
.select-language-icons-with-code__link.option-link:last-of-type {
    margin: 0
}

.select-language-icons-with-code__link:active,
.select-language-icons-with-code__link:focus,
.select-language-icons-with-code__link:hover {
    text-decoration: none;
    outline: none
}

.select-language-icons-with-code__link:hover {
    background-color: #373a47;
    color: #fff
}

.select-language-icons-with-code__icon {
    display: inline-flex;
    width: 22px;
    margin-right: 20px
}

.select-language-icons-with-code__img {
    display: inline-flex;
    width: 26px;
    border-radius: 4px
}

.select-language-icons-with-code__icon-check {
    color: #33d672;
    margin-left: auto
}

.select-language-icons-with-code .option-link--selected {
    font-weight: 700
}

.select-language-icons-with-code .option-link__content {
    width: 100%;
    display: flex;
    align-items: center
}

.registration-dynamic-form {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding: 0;
    margin: 0
}

@media(min-width:720px) {
    .registration-dynamic-form {
        flex-direction: row
    }
}

.registration-dynamic-form__col {
    display: inline-flex;
    position: relative;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    width: 100%;
    padding: 0;
    margin: 0
}

@media(min-width:720px) {
    .registration-dynamic-form__col {
        width: 50%;
        min-height: 600px
    }
}

.registration-dynamic-form__col--promo {
    display: none;
    padding: 76px 0 0
}

@media(min-width:720px) {
    .registration-dynamic-form__col--promo {
        display: inline-flex
    }
}

.registration-dynamic-form__col--form {
    padding: 0 10px 24px;
    width: 100%;
    height: 100%
}

@media(min-width:720px) {
    .registration-dynamic-form__col--form {
        padding: 24px
    }
}

.registration-dynamic-form__header {
    display: flex;
    position: relative;
    left: -10px;
    width: calc(100% + 20px);
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 10px 30px;
    margin: 0 0 10px;
    min-height: 40px;
    color: #fff;
    font-family: Montserrat, Arial, sans-serif;
    font-size: 16px;
    line-height: 1.2;
    font-weight: 700;
    letter-spacing: normal;
    text-transform: uppercase;
    border-radius: 4px;
    background-color: #32CCF2
}

@media(min-width:720px) {
    .registration-dynamic-form__header {
        min-height: 76px;
        font-size: 24px;
        padding: 10px 54px;
        margin: 0;
        position: absolute;
        top: 0;
        right: 100%;
        left: auto;
        width: 100%
    }
}

.registration-dynamic-form__step {
    font-size: 14px;
    line-height: 18px;
    position: absolute;
    left: 8px;
    top: calc(50% - 9px)
}

@media(min-width:720px) {
    .registration-dynamic-form__step {
        font-size: 16px;
        right: 24px;
        left: auto
    }
}

.registration-dynamic-form__title {
    display: block;
    width: 100%;
    padding: 0;
    margin: 0
}

.registration-dynamic-form__form-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100%;
    padding: 0;
    margin: 0;
    flex: 1
}

.registration-dynamic-form__form {
    flex-grow: 1
}

.registration-dynamic-form__footer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0;
    margin: 8px 0 0
}

.registration-dynamic-form__link {
    font-size: 16px;
    color: #edd3b0;
    text-decoration: none;
    text-transform: capitalize;
    font-weight: 600
}

.registration-dynamic-form__link:hover {
    text-decoration: underline
}

.registration-dynamic-form__form-step {
    display: none;
    margin-bottom: auto;
    width: 100%
}

.registration-dynamic-form__form-step--active {
    display: block
}

.registration-dynamic-form__element+.registration-dynamic-form__element {
    margin-top: 10px
}

.registration-dynamic-form__label-wrapper {
    display: none;
    width: 100%;
    padding: 0;
    margin: 0 0 16px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    text-transform: uppercase
}

@media(min-width:720px) {
    .registration-dynamic-form__label-wrapper {
        font-size: 16px
    }
}

.registration-dynamic-form__label {
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase
}

@media(min-width:720px) {
    .registration-dynamic-form__label {
        font-size: 16px
    }
}

.registration-dynamic-form__buttons {
    display: flex;
    flex-wrap: nowrap;
    margin: 22px 0 0
}

.registration-dynamic-form__button-wrapper,
.registration-dynamic-form__buttons {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0
}

.registration-dynamic-form__button-wrapper {
    display: inline-flex;
    flex-wrap: wrap;
    align-self: stretch;
    margin: 0
}

.registration-dynamic-form__button-wrapper--submit {
    flex: 1
}

.registration-dynamic-form__button-wrapper--step {
    width: auto
}

.registration-dynamic-form__button-wrapper+.registration-dynamic-form__button-wrapper {
    margin-left: 24px
}

.registration-dynamic-form .registration-dynamic-form__back-button {
    min-width: 112px;
    min-height: 35px;
    font-size: 12px
}

.registration-dynamic-form .registration-dynamic-form__submit-button {
    width: 100%;
    min-height: 35px;
    font-size: 12px
}

.select-user-menu__dropdown {
    scrollbar-color: #fff;
    scrollbar-width: thin
}

.select-user-menu__dropdown::-webkit-scrollbar {
    width: 2px;
    height: 2px
}

.select-user-menu__dropdown::-webkit-scrollbar-track {
    border-radius: 2px;
    background: hsla(0, 0%, 60%, .5)
}

.select-user-menu__dropdown::-webkit-scrollbar-thumb {
    background: #fff;
    border-radius: 2px;
    margin: 0
}

.select-user-menu__dropdown::-webkit-scrollbar-thumb:hover {
    background: #fff
}

.select-user-menu {
    position: relative
}

.select-user-menu__button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 40px;
    padding: 0 12px;
    cursor: pointer;
    color: #fff;
    font-size: 22px;
    transition: color .3s ease
}

.select-user-menu__button:hover {
    color: hsla(0, 0%, 100%, .8)
}

.select-user-menu__balance {
    display: block;
    width: 100%;
    padding: 0;
    margin: 2px 0 0
}

.select-user-menu__dropdown {
    position: absolute;
    z-index: 100;
    width: 100%;
    border-radius: 8px;
    margin-top: 6px;
    top: 100%;
    right: 0;
    min-width: 224px;
    max-height: 3000px;
    overflow-y: auto;
    background-color: #2e313d;
    box-shadow: 0 6px 12px rgba(0, 0, 0, .25)
}

.select-user-menu__menu-wrapper {
    display: block;
    position: relative;
    width: 100%;
    padding: 10px 0 5px;
    margin: 0
}

.captcha-term-and-policy {
    margin: 10px 0;
    color: #fff;
    font-size: 14px;
    line-height: 22px
}

.captcha-term-and-policy__link {
    font-size: 14px;
    line-height: 22px;
    color: #818183
}

.select__dropdown {
    scrollbar-color: #fff;
    scrollbar-width: thin
}

.select__dropdown::-webkit-scrollbar {
    width: 2px;
    height: 2px
}

.select__dropdown::-webkit-scrollbar-track {
    border-radius: 2px;
    background: hsla(0, 0%, 60%, .5)
}

.select__dropdown::-webkit-scrollbar-thumb {
    background: #fff;
    border-radius: 2px;
    margin: 0
}

.select__dropdown::-webkit-scrollbar-thumb:hover {
    background: #fff
}

.recommended-options,
.select {
    display: block;
    position: relative;
    width: 100%;
    padding: 0;
    margin: 0;
    font-size: 14px;
    line-height: 1.2;
    text-align: left;
    color: #fff;
    font-weight: 600
}

.recommended-options .input__native:hover,
.select .input__native:hover {
    background-color: #373a47
}

.select__bottom-line {
    position: relative;
    width: 100%;
    height: 0
}

.select__dropdown {
    display: block;
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    width: 100%;
    padding: 0;
    margin: 0;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    background: #2e313d none;
    border: 0 solid transparent;
    border-radius: 8px;
    text-transform: none;
    max-height: 200px;
    overflow: hidden;
    overflow-y: auto;
    z-index: 100;
    box-shadow: 0 6px 12px rgba(0, 0, 0, .25)
}

.recommended-options__arrow-icon-wrapper,
.select__arrow-icon-wrapper {
    position: absolute;
    right: 15px;
    top: 50%;
    cursor: pointer;
    font-size: 8px;
    color: hsla(0, 0%, 100%, .4);
    transform-origin: center;
    transform: rotate(0deg);
    transition: transform .3s;
    transform: translateY(-50%) rotate(0deg)
}

.recommended-options__arrow-icon-wrapper--open,
.select__arrow-icon-wrapper--open {
    color: hsla(0, 0%, 100%, .65);
    transform: translateY(-50%) rotate(180deg)
}

.recommended-options__title {
    display: block;
    margin-left: 24px;
    margin-bottom: 20px;
    margin-top: 4px
}

@media(max-width:960px) {
    .recommended-options__title {
        margin-left: 0;
        margin-top: 20px
    }
}

.recommended-options__dropdown {
    display: block;
    list-style: none;
    width: 100%;
    padding: 0;
    margin-top: 16px;
    border-radius: 8px
}

@media(min-width:960px) {
    .recommended-options__dropdown {
        background-color: #2e313d;
        padding: 16px 0
    }
}

.recommended-options__dropdown--hide {
    display: none
}

.recommended-options__option-image {
    width: 100px;
    height: 50px;
    margin-right: 30px
}

.recommended-options .recommended-options__option {
    display: block;
    width: 100%;
    padding: 0;
    margin: 0
}

.recommended-options__option-link {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 16px;
    margin-left: -16px;
    margin-right: -16px;
    text-decoration: none;
    color: #999;
    font-size: 12px;
    line-height: 1.2;
    text-align: left;
    font-weight: 600;
    letter-spacing: .12px;
    text-transform: uppercase;
    border-radius: 4px;
    transition: background-color .3s ease, color .3s ease
}

@media(min-width:720px) {
    .recommended-options__option-link {
        font-size: 16px
    }
}

@media(min-width:960px) {
    .recommended-options__option-link {
        padding: 16px 40px;
        margin: unset
    }
}

@media(min-width:1248px) {
    .recommended-options__option-link {
        padding: 8px 24px
    }
}

.recommended-options__option-link:hover {
    background-color: #373a47;
    color: #fff
}

.recommended-options__option-title {
    display: block;
    text-transform: none;
    color: #fff
}

.recommended-options__option-description {
    display: block;
    text-transform: none;
    color: hsla(0, 0%, 100%, .4)!important
}

.recommended-options__option-image {
    display: inline-block;
    vertical-align: top;
    width: 32px;
    height: auto;
    border-radius: 8px;
    margin: 0 16px 0 0
}

@media(min-width:720px) {
    .recommended-options__option-image {
        width: 64px
    }
}

.games-filter {
    position: relative
}

@media(min-width:960px) {
    .games-filter__controls {
        display: flex;
        flex-shrink: 0;
        align-self: flex-start
    }
}

@media(min-width:1248px) {
    .games-filter {
        display: flex;
        justify-content: space-between;
        align-items: center
    }
}

@media(min-width:960px) {
    .games-filter__cell {
        display: flex;
        align-items: center
    }
}

@media(min-width:960px) {
    .games-filter__cell--category {
        margin-bottom: 40px
    }
}

@media(min-width:1248px) {
    .games-filter__cell--category {
        margin-bottom: 0
    }
}

@media(min-width:320px) {
    .games-filter__cell--providers {
        display: none
    }
}

@media(min-width:320px)and (min-width:960px) {
    .games-filter__cell--providers {
        display: flex
    }
}

.game-category-menu__menu {
    display: flex;
    flex-wrap: wrap
}

@media screen and (max-width:961px) {
    .game-category-menu__menu {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 12px
    }
}

@media(min-width:960px) {
    .game-category-menu__menu {
        margin: 0 0 -14px -14px
    }
}

@media(min-width:1248px) {
    .game-category-menu__menu {
        justify-content: flex-start
    }
}

.game-category-menu__item-link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #fff;
    text-decoration: none;
    white-space: nowrap
}

@media(max-width:601px) {
    .game-category-menu__item-link {
        flex-direction: column;
        align-items: flex-start
    }
}

@media(max-width:961px) {
    .game-category-menu__item-link {
        padding: 16px;
        background-color: #2e313d;
        border-radius: 8px;
        font-weight: 400
    }
    .game-category-menu__item-link-features {
        padding: 0
    }
}

@media(min-width:960px) {
    .game-category-menu__item-link {
        flex-direction: column;
        text-align: center;
        margin: 0 14px 14px;
        background-color: transparent;
        text-transform: uppercase;
        font-weight: 600;
        width: auto;
        font-size: 14px;
        color: hsla(0, 0%, 100%, .65)
    }
}

@media(min-width:1248px) {
    .game-category-menu__item-link {
        flex-direction: column;
        font-size: 14px
    }
}

@media(min-width:1632px) {
    .game-category-menu__item-link {
        font-size: 16px;
        flex-direction: row
    }
}

.game-category-menu__item-link-active,
.game-category-menu__item-link.active,
.game-category-menu__item-link:hover {
    color: #fff
}

.game-category-menu__item-icon {
    font-size: 24px;
    transition: all .3s;
    color: #3ecdf1
}

.game-category-menu__item-icon,
.game-category-menu__item-link-active .game-category-menu__item-icon,
.game-category-menu__item-link:hover .game-category-menu__item-icon {
    color: #3ecdf1;
    opacity: 1
}

@media(max-width:601px) {
    .game-category-menu__item-icon {
        margin-bottom: 6px
    }
}

@media(max-width:960px) {
    .game-category-menu__item-icon {
        margin-right: 16px;
        opacity: .65;
        color: #3ecdf1
    }
}

@media(min-width:960px) {
    .game-category-menu__item-icon {
        opacity: .65;
        color: #3ecdf1
    }
}

@media(min-width:1632px) {
    .game-category-menu__item-icon {
        margin-right: 10px
    }
}

.search-for-games-filter {
    display: block;
    position: relative;
    width: 100%;
    padding: 0;
    margin-left: -8px
}

@media(min-width:320px) {
    .search-for-games-filter {
        display: none
    }
}

@media(min-width:320px)and (min-width:960px) {
    .search-for-games-filter {
        display: block;
        margin-right: 12px
    }
}

.search-for-games-filter__button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    flex-wrap: nowrap!important;
    width: 100%;
    min-height: 40px;
    border-radius: 8px;
    background-color: #2e313d;
    margin: 0;
    padding: 5px 16px!important;
    cursor: pointer
}

.search-for-games-filter__button:hover {
    background-color: #373a47
}

.search-for-games-filter__button .search-for-games-filter__icon {
    position: relative;
    color: inherit;
    width: auto;
    margin: 0 10px 0 0
}

@media(min-width:720px) {
    .search-for-games-filter__button {
        margin: 0 0 0 auto
    }
}

.search-for-games-filter__block-input {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    pointer-events: none
}

.search-for-games-filter--open .search-for-games-filter__block-input {
    pointer-events: auto;
    opacity: 1
}

.search-for-games-filter__input {
    display: block;
    height: 47px;
    border-radius: 4px;
    background-color: #2e313d;
    border: none;
    box-shadow: none;
    color: #fff;
    font-size: 12px;
    line-height: 1.2;
    vertical-align: middle;
    font-weight: 600;
    text-align: left;
    text-transform: uppercase;
    padding: 0 42px;
    margin: 0;
    outline: none;
    text-overflow: ellipsis;
    overflow: hidden;
    transition: color .3s ease, background-color .3s ease, padding .3s ease;
    width: 100%
}

.search-for-games-filter__input::-moz-placeholder {
    color: #fff!important;
    line-height: 47px
}

.search-for-games-filter__input::-webkit-input-placeholder {
    color: #fff!important;
    line-height: normal
}

.search-for-games-filter__input:-webkit-autofill,
.search-for-games-filter__input:-webkit-autofill:active,
.search-for-games-filter__input:-webkit-autofill:active:focus,
.search-for-games-filter__input:-webkit-autofill:active:hover,
.search-for-games-filter__input:-webkit-autofill:focus,
.search-for-games-filter__input:-webkit-autofill:hover {
    box-shadow: inset 0 0 0 140px #2e313d!important;
    color: #fff!important;
    -webkit-text-fill-color: #fff!important
}

.search-for-games-filter__icon i {
    color: hsla(0, 0%, 100%, .4)
}

.search-for-games-filter__close {
    display: block;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 42px;
    background: transparent none;
    box-shadow: none;
    border: none;
    outline: none;
    font-size: 12px;
    color: #999;
    text-shadow: none;
    line-height: 1.2;
    transition: color .3s ease, opacity .3s ease;
    cursor: pointer;
    z-index: 2;
    overflow: hidden;
    margin: 0;
    opacity: 1
}

.search-for-games-filter__close:active,
.search-for-games-filter__close:focus,
.search-for-games-filter__close:hover {
    background: none transparent;
    box-shadow: none;
    border: none;
    outline: none;
    color: #999
}

.search-for-games-filter__close:hover {
    color: #fff
}

.search-for-games-filter--empty .search-for-games-filter__close {
    opacity: 0;
    pointer-events: none
}

.home__filter .search-for-games-filter .search-for-games-filter__input {
    height: 40px
}

@media(min-width:960px) {
    .home__filter .search-for-games-filter .search-for-games-filter__input {
        height: 47px
    }
}

@media(min-width:960px) {
    .home__filter .search-for-games-filter .search-for-games-filter__icon {
        font-size: 20px
    }
}

@media(min-width:1248px) {
    .home__filter .search-for-games-filter .search-for-games-filter__icon {
        margin: 0
    }
}

.home__filter .search-for-games-filter .search-for-games-filter__placeholder {
    margin-left: 10px
}

@media(max-width:719px) {
    .home__filter .search-for-games-filter.search-for-games-filter--open .search-for-games-filter__block-input {
        right: 0;
        left: auto;
        width: calc(100vw - 48px);
        min-width: 100%;
        z-index: 1
    }
}

@media(min-width:1248px) {
    .home__filter .search-for-games-filter.search-for-games-filter--open .search-for-games-filter__block-input {
        right: 0;
        left: auto;
        width: 300px;
        min-width: 100%;
        z-index: 1
    }
}

.games-search-for-modal-root {
    height: 100%;
    padding: 24px;
    overflow-y: auto
}

@media(min-width:960px) {
    .games-search-for-modal-root {
        padding: 0
    }
}

.games-search-for-modal-root__title {
    margin-bottom: 24px;
    display: inline-block
}

@media(min-width:960px) {
    .games-search-for-modal-root__title {
        display: none
    }
}

.grand-prizes {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: calc(100% + 16px);
    margin-left: -8px;
    margin-top: 24px;
    margin-bottom: 56px
}

@media(min-width:720px) {
    .grand-prizes {
        margin-top: 40px;
        margin-bottom: 72px
    }
}

.grand-prizes__bg--rize {
    position: absolute;
    top: -125px;
    left: 50%;
    width: 200%!important;
    height: 200%!important;
    transform: translateX(-50%)
}

.grand-prizes__bg-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%
}

.grand-prizes__bg-wrap img {
    width: 170%;
    height: 170%
}

.grand-prizes__content {
    position: relative;
    z-index: 2
}

.grand-prizes__block {
    position: relative;
    border-radius: 20px;
    padding: 8px 20px;
    margin: 8px;
    width: 100%;
    height: 256px;
    overflow: hidden;
    max-width: 343px
}

.grand-prizes__block:first-child .grand-prizes__pool {
    color: #ffe600
}

.grand-prizes__block:nth-child(3) .grand-prizes__logo-wrap img {
    max-width: 110px
}

@media(min-width:720px) {
    .grand-prizes__block {
        max-width: inherit;
        padding: 16px 20px;
        width: calc(50% - 16px)
    }
}

@media(min-width:960px) {
    .grand-prizes__block {
        width: calc(25% - 16px);
        padding: 8px 20px
    }
}

.grand-prizes__logo-wrap {
    width: 148px;
    height: 148px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    align-items: center
}

.grand-prizes__logo-wrap img {
    max-width: 100%
}

.grand-prizes__name {
    font-family: Montserrat, Arial, sans-serif;
    margin-bottom: 6px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: hsla(0, 0%, 100%, .65)
}

.grand-prizes__name,
.grand-prizes__pool {
    text-transform: uppercase;
    text-align: center
}

.grand-prizes__pool {
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #fff
}

.notification-center,
.notification-center__button {
    display: inline-flex;
    position: relative
}

.notification-center__button {
    align-items: center;
    height: 40px;
    padding: 0 12px;
    margin: 0;
    cursor: pointer
}

.notification-center__container {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    margin-top: 6px;
    width: 320px;
    background-color: #2e313d;
    border-radius: 8px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, .25)
}

.notification-center--open .notification-center__container {
    display: block
}

@media(min-width:720px) {
    .notification-center__container {
        right: 0
    }
}

.notification-center__icon {
    font-size: 24px;
    transition: color .3s ease;
    color: #fff
}

.notification-center--open .notification-center__icon,
.notification-center__button:hover .notification-center__icon {
    color: hsla(0, 0%, 100%, .8)
}

.notification-center__count {
    position: absolute;
    top: 4px;
    left: 27px;
    width: auto;
    padding: 3px;
    border-radius: 13px;
    background-color: #3ecdf1;
    border: 2px solid #2e313d;
    color: #fff;
    text-align: center;
    font-size: 10px;
    line-height: 7px;
    display: flex;
    justify-content: center;
    align-items: center
}

.notification-center--empty .notification-center__count {
    display: none
}

.notification-center--new .notification-center__count {
    background-color: #32CCF2;
    border-color: #32CCF2
}

.notification-center__list {
    display: block;
    width: 100%;
    padding: 13px 0;
    margin: 0;
    max-height: calc(100vh - 80px);
    overflow: auto
}

.notification-center__empty {
    display: flex;
    justify-content: center;
    /*background: transparent url(url(../assets/notification__empty.7c81df69.svg) no-repeat center 16px;*/
    background-size: auto 116px;
    opacity: 1;
    color: hsla(0, 0%, 100%, .4);
    font-size: 14px;
    line-height: 20px;
    min-height: 192px
}

.notification-center__empty-text {
    margin-top: auto;
    margin-bottom: 12px
}

.home-game-slider {
    position: relative;
    margin-bottom: 24px
}

@media(min-width:720px) {
    .home-game-slider {
        margin-bottom: 32px
    }
}

@media(min-width:1248px) {
    .home-game-slider {
        margin-bottom: 40px
    }
}

.home-game-slider__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 12px
}

@media(min-width:720px) {
    .home-game-slider__head {
        margin-bottom: 16px
    }
}

@media(min-width:1248px) {
    .home-game-slider__head {
        margin-bottom: 20px
    }
}

.home-game-slider__title {
    color: #fff
}

.home-game-slider .catalog__more {
    display: none
}

.logout__icon {
    margin-right: 10px;
    font-size: 18px
}

.notification-center-card {
    display: flex;
    align-items: flex-start;
    width: 100%;
    padding: 8px 16px;
    transition: background-color .3s ease
}

.notification-center-card:hover {
    background-color: #373a47
}

.notification-center-card--new .notification-center-card__description {
    color: #fff
}

.notification-center-card--large {
    padding: 10px 0
}

.notification-center-card--large .notification-center-card__icon {
    width: 48px;
    height: 48px;
    margin-right: 20px
}

.notification-center-card--large .notification-center-card__description {
    font-size: 16px;
    line-height: 24px
}

.notification-center-card--large .notification-center-card__dot {
    margin-right: 0
}

.notification-center-card__icon {
    width: 40px;
    height: 40px;
    -o-object-fit: contain;
    object-fit: contain;
    -o-object-position: center;
    object-position: center;
    margin-right: 12px;
    flex-shrink: 0
}

.notification-center-card__link {
    display: inline-block;
    vertical-align: top;
    color: #999;
    font-size: 14px;
    line-height: 1;
    transition: color .3s ease
}

.notification-center-card__container:hover .notification-center-card__link {
    color: #fff
}

.notification-center-card__content {
    display: flex;
    flex-direction: column;
    text-align: left;
    flex: 1 1 auto
}

.notification-center-card__title {
    font-size: 12px;
    line-height: 18px;
    color: hsla(0, 0%, 100%, .4);
    margin-bottom: 2px
}

.notification-center-card__description {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: hsla(0, 0%, 100%, .65)
}

.notification-center-card__dot {
    width: 4px;
    height: 4px;
    background-color: #3ecdf1;
    border-radius: 50%;
    margin: auto 12px
}

.payment-table-mobile {
    display: grid;
    grid-template-columns: 88px 1fr;
    gap: 25px;
    padding: 20px 0;
    border: solid hsla(0, 0%, 100%, .12);
    border-width: 1px 0 0
}

.payment-table-mobile:last-child {
    border-bottom-width: 1px
}

.payment-table-mobile .bar__auth-btn,
.payment-table-mobile__deposit-button {
    padding: 8px!important;
    background: none!important;
    color: #33d672!important;
    min-width: unset!important;
    width: 100%!important;
    margin-top: 8px!important
}

.payment-table-mobile__method-img-wrapper {
    background: #fff;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 8px;
    min-height: 48px
}

.payment-table-mobile__method-img-wrapper img {
    max-width: 100%
}

.payment-table-mobile__method-content-item:not(:last-child) {
    margin-bottom: 12px
}

.payment-table-mobile__method-content-item-title {
    display: block;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: hsla(0, 0%, 100%, .4)
}

.payment-table-mobile__method-content-item-value {
    font-size: 16px;
    line-height: 24px;
    color: #fff
}

.payment-table {
    display: none
}

@media(min-width:720px) {
    .payment-table {
        display: block;
        width: 100%
    }
}

.payment-table__image-wrap {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    padding: 8px 16px;
    height: 54px;
    width: 130px;
    border-radius: 5px;
    background-color: #fff
}

.payment-table__image-wrap img {
    max-width: 75px;
    height: auto;
    max-height: 100%
}

.payment-table__card-wrap {
    display: flex;
    align-items: center
}

.payment-table:not(:last-child) {
    margin-bottom: 40px
}

@media(min-width:1248px) {
    .payment-table:not(:last-child) {
        margin-bottom: 48px
    }
}

.payment-table__table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 4px 4px 0 0;
    overflow: hidden
}

.payment-table__table td,
.payment-table__table th {
    padding: 0 10px 0 16px;
    background: rgba(38, 41, 51, .65);
    border-bottom: 1px solid #1c1e26
}

@media(min-width:1248px) {
    .payment-table__table td,
    .payment-table__table th {
        padding: 0 10px 0 24px
    }
}

.payment-table__table td:last-child,
.payment-table__table th:last-child {
    padding: 0 16px 0 10px
}

@media(min-width:1248px) {
    .payment-table__table td:last-child,
    .payment-table__table th:last-child {
        text-align: right;
        padding: 0 32px 0 10px
    }
}

.payment-table__table th {
    background-color: #262933
}

.payment-table-title {
    color: #fff!important;
    margin-bottom: 24px
}

.payment-table-head {
    height: 48px;
    color: hsla(0, 0%, 100%, .65);
    font-size: 12px;
    line-height: 16px;
    font-weight: 500
}

@media(min-width:1248px) {
    .payment-table-head {
        font-size: 14px;
        line-height: 20px;
        font-weight: 700
    }
}

.payment-table-row {
    height: 71px;
    color: #fff;
    font-size: 14px;
    line-height: 20px
}

@media(min-width:1248px) {
    .payment-table-row {
        height: 80px;
        font-size: 16px;
        line-height: 24px
    }
}

.payment-table__method-img {
    width: 88px
}

.payment-tabs-mobile {
    width: 100%
}

.payment-tabs-mobile__tab {
    background: none;
    border: none;
    padding: 0;
    color: hsla(0, 0%, 100%, .65);
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase
}

.payment-tabs-mobile__tab:not(:last-child) {
    margin-right: 22px
}

.payment-tabs-mobile__tab.active {
    color: #fff;
    border-bottom: 2px solid #3ecdf1
}

.payment-tabs-mobile__tab-content {
    margin-top: 22px
}

.payment-tabs {
    display: none
}

@media(min-width:720px) {
    .payment-tabs {
        display: block;
        width: 100%
    }
}

.payment-tabs:not(:last-child) {
    margin-bottom: 40px
}

@media(min-width:1248px) {
    .payment-tabs:not(:last-child) {
        margin-bottom: 48px
    }
}

.payment-tabs-title {
    color: #fff!important;
    margin-bottom: 24px
}

.promo-jackpot {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #181920;
    border-radius: 12px;
    padding-right: 57px;
    margin-bottom: 44px;
    position: relative
}

@media(max-width:1248px) {
    .promo-jackpot {
        padding-right: 24px
    }
}

@media(max-width:960px) {
    .promo-jackpot {
        margin-top: 24px;
        width: calc(100% + 40px);
        margin-left: -20px
    }
}

@media(max-width:720px) {
    .promo-jackpot {
        padding-top: 12px;
        padding-bottom: 12px;
        padding-right: 0
    }
}

@media(max-width:600px) {
    .promo-jackpot {
        overflow: scroll;
        width: calc(100% + 32px);
        margin-left: -16px
    }
}

.promo-jackpot::-webkit-scrollbar {
    display: none
}

.promo-jackpot__block {
    display: flex;
    align-items: center
}

.promo-jackpot__block--content {
    width: calc(100% - 157px)
}

@media(max-width:960px) {
    .promo-jackpot__block--content {
        width: 100%
    }
}

@media(max-width:720px) {
    .promo-jackpot__block {
        width: 100%
    }
    .promo-jackpot__block--btn {
        display: none
    }
}

.promo-jackpot__banner {
    position: relative;
    display: flex;
    align-items: center;
    padding: 16px 130px 16px 40px
}

@media(max-width:1248px) {
    .promo-jackpot__banner {
        padding-right: 112px;
        padding-left: 24px
    }
}

@media(max-width:960px) {
    .promo-jackpot__banner {
        padding-right: 64px
    }
}

@media(max-width:720px) {
    .promo-jackpot__banner {
        display: none
    }
}

.promo-jackpot__banner-bg {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%
}

.promo-jackpot__banner-person {
    position: absolute;
    bottom: 0;
    right: -12px
}

@media(max-width:960px) {
    .promo-jackpot__banner-person {
        display: none
    }
}

.promo-jackpot__main-logo {
    height: 64px;
    width: 64px;
    margin-right: 12px;
    position: relative;
    z-index: 2
}

.promo-jackpot__main-logo img {
    max-width: 100%
}

.promo-jackpot__name {
    font-family: Montserrat Alternates, sans-serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: .01em;
    text-transform: uppercase;
    color: #fff;
    position: relative;
    z-index: 2
}

.promo-jackpot__prizez {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%
}

@media(max-width:720px) {
    .promo-jackpot__prizez {
        justify-content: center;
        width: 100%
    }
}

@media(max-width:460px) {
    .promo-jackpot__prizez {
        justify-content: flex-start
    }
}

@media(max-width:960px) {
    .promo-jackpot__seeMore {
        display: none!important
    }
}

.promo-jackpot .jackpot-block__bottom-item {
    display: flex;
    align-items: center;
    padding-left: 40px;
    padding-right: 40px
}

.promo-jackpot .jackpot-block__bottom-item:first-child .jackpot-block__bottom-item-pool {
    color: #ffd653
}

@media(max-width:1248px) {
    .promo-jackpot .jackpot-block__bottom-item {
        padding-left: 16px;
        padding-right: 16px
    }
}

.promo-jackpot .jackpot-block__bottom-item:nth-child(3) .jackpot-block__bottom-logo img {
    height: 54px
}

.promo-jackpot .jackpot-block__bottom-logo {
    margin-right: 12px
}

.promo-jackpot .jackpot-block__bottom-logo img {
    height: 64px
}

.promo-jackpot .jackpot-block__bottom-item-name {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: .01em;
    text-transform: uppercase;
    color: hsla(0, 0%, 100%, .4)
}

.promo-jackpot .jackpot-block__bottom-item-pool {
    font-weight: 900;
    font-size: 20px;
    line-height: 30px;
    color: #fff
}

@media(max-width:1248px) {
    .promo-jackpot .jackpot-block__bottom-item-pool {
        font-size: 16px;
        line-height: 24px
    }
}

.promo-jackpot__mainlink {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: none
}

@media(max-width:960px) {
    .promo-jackpot__mainlink {
        display: block
    }
}

.blocks-timer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    background-color: rgba(24, 25, 32, .65);
    mix-blend-mode: normal;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    max-width: 248px;
    height: 66px
}

.blocks-timer__item {
    flex: 1 1 25%
}

.blocks-timer__item:first-child .blocks-timer__value:before,
.blocks-timer__item:last-child .blocks-timer__value:before {
    display: none
}

.blocks-timer__value {
    position: relative;
    color: #fff;
    font-size: 24px;
    line-height: 32px;
    font-weight: 900;
    text-align: center
}

.blocks-timer__value:before {
    position: absolute;
    content: ":";
    top: 50%;
    right: -3px;
    transform: translateY(-50%);
    color: hsla(0, 0%, 100%, .4);
    font-weight: 900;
    font-size: 24px;
    line-height: 32px
}

.blocks-timer__text {
    color: hsla(0, 0%, 100%, .4);
    text-align: center;
    letter-spacing: .01em;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 8px;
    line-height: 12px
}

.inline-timer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    mix-blend-mode: normal;
    max-width: 135px;
    width: 100%;
    height: 44px
}

@media(min-width:960px) {
    .inline-timer {
        max-width: 164px
    }
}

.inline-timer__item {
    flex: 1 1 25%;
    text-align: center
}

.inline-timer__item:first-child .inline-timer__value:before,
.inline-timer__item:last-child .inline-timer__value:before {
    display: none
}

.inline-timer__value {
    position: relative;
    color: #fff;
    font-size: 20px;
    line-height: 30px;
    font-weight: 900;
    text-align: center
}

@media(min-width:960px) {
    .inline-timer__value {
        font-size: 20px;
        line-height: 32px
    }
}

@media(min-width:1248px) {
    .inline-timer__value {
        font-size: 24px
    }
}

.inline-timer__value:before {
    position: absolute;
    content: ":";
    top: 50%;
    right: -4px;
    transform: translateY(-50%);
    color: #fff;
    font-weight: 900;
    font-size: 24px;
    line-height: 32px
}

.inline-timer__text {
    color: hsla(0, 0%, 100%, .4);
    text-align: center;
    letter-spacing: .01em;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 8px;
    line-height: 12px
}

@media(min-width:960px) {
    .inline-timer__text {
        display: none
    }
}

.inline-timer__text--full {
    display: none
}

@media(min-width:960px) {
    .inline-timer__text--full {
        display: initial
    }
}

.tourn-card {
    display: flex;
    flex-direction: column;
    background-color: #262933;
    border-radius: 12px;
    overflow: hidden;
    margin-bottom: 16px
}

.tourn-card--completed .tourn-card__image {
    filter: grayscale(100%)
}

.tourn-card__wrap {
    position: relative;
    top: -2px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 1 auto;
    background-color: #262933;
    z-index: 3
}

.tourn-card__image {
    position: relative
}

.tourn-card__image:before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(24, 25, 32, 0) 44.11%, rgba(24, 25, 32, .65));
    z-index: 1
}

.tourn-card__image img {
    -o-object-fit: cover;
    object-fit: cover;
    height: 186px;
    width: 100%
}

.tourn-card__title {
    position: absolute;
    font-size: 24px;
    line-height: 32px;
    top: -50px;
    left: 16px;
    z-index: 2
}

.tourn-card__timer {
    margin-left: auto
}

.tourn-card__dot {
    width: 6px;
    height: 6px;
    margin-right: 8px;
    border-radius: 50%;
    background-color: #33d672;
    display: inline-flex
}

.tourn-card__dot--start {
    background-color: #ffd653
}

.tourn-card__options {
    padding: 12px 18px;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0
}

@media(min-width:960px) {
    .tourn-card__options {
        padding: 14px 24px
    }
}

.tourn-card__option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid hsla(0, 0%, 100%, .12);
    padding: 20px 0;
    font-size: 14px
}

.tourn-card__option span {
    font-size: 14px
}

@media(min-width:1248px) {
    .tourn-card__option span {
        font-size: 16px
    }
}

.tourn-card__option strong {
    font-size: 16px
}

.tourn-card__option:last-child {
    border-bottom: none
}

.tourn-card .tourn-card__link {
    width: 100%;
    min-height: 48px;
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2e313d;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    transition: background-color .3s ease
}

.tourn-card .tourn-card__link:hover {
    background-color: #373a47
}

.tourn-main {
    position: relative;
    width: 100%;
    height: auto;
    margin-bottom: 64px
}

.tourn-main .btn--primary {
    background: transparent;
    border: 1px solid hsla(0, 0%, 100%, .12);
    gap: 9px
}

.tourn-main .btn--primary i {
    color: #33d672
}

.tourn-main .btn--primary:disabled {
    opacity: 1
}

.tourn-main .btn--primary:hover {
    box-shadow: none;
    background: none
}

.tourn-main__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 720px;
    filter: blur(20px)
}

.tourn-main__bg img {
    width: 100%;
    height: 100%;
    -o-object-fit: fill;
    object-fit: fill;
    z-index: 0;
    opacity: .5
}

.tourn-main__bg:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(28, 30, 38, 0), #1c1e26);
    z-index: 2
}

.tourn-main .tourn-main__container {
    position: relative;
    z-index: 2;
    padding-top: 16px
}

@media(min-width:720px) {
    .tourn-main .tourn-main__container {
        padding-top: 32px
    }
}

@media(min-width:1248px) {
    .tourn-main .tourn-main__container {
        padding-top: 48px
    }
}

.tourn-main__rules {
    display: flex;
    align-items: baseline;
    gap: 24px;
    margin-top: 40px
}

@media(min-width:720px) {
    .tourn-main__rules {
        margin-top: 48px
    }
}

@media(min-width:1248px) {
    .tourn-main__rules {
        margin-top: 0
    }
}

.tourn-main__content {
    position: relative;
    z-index: 1;
    display: grid;
    grid-template-columns: 1fr;
    gap: 52px
}

@media(min-width:720px) {
    .tourn-main__content {
        grid-template-columns: repeat(2, 1fr);
        gap: 44px
    }
}

@media(min-width:960px) {
    .tourn-main__content {
        grid-template-columns: minmax(486px, 1fr) 1fr;
        gap: 66px
    }
}

@media(min-width:1248px) {
    .tourn-main__content {
        grid-template-columns: 1.5fr 1fr;
        align-items: center;
        gap: 98px
    }
}

@media(min-width:1632px) {
    .tourn-main__content {
        grid-template-columns: 1.2fr 1fr;
        gap: 200px
    }
}

.tourn-main__text {
    font-size: 14px;
    color: hsla(0, 0%, 100%, .65);
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 32px
}

@media(min-width:1248px) {
    .tourn-main__text {
        font-size: 16px;
        line-height: 24px
    }
}

.tourn-main__link {
    cursor: pointer;
    display: block;
    color: #fff;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-decoration: none;
    margin-bottom: 36px
}

@media(min-width:1248px) {
    .tourn-main__link {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 50px
    }
}

.tourn-main__prize {
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-direction: column
}

@media(min-width:1248px) {
    .tourn-main__prize {
        flex-direction: row
    }
}

.tourn-main__prize-places {
    position: relative;
    margin-bottom: 30px
}

@media(min-width:1248px) {
    .tourn-main__prize-places {
        padding-right: 80px
    }
}

.tourn-main__prize-places:before {
    content: "";
    position: absolute;
    top: 50%;
    right: 40px;
    transform: translateY(-50%);
    width: 1px;
    height: 100%;
    background-color: hsla(0, 0%, 100%, .12);
    display: none
}

@media(min-width:1248px) {
    .tourn-main__prize-places:before {
        display: block
    }
}

.pic-block {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex-wrap: wrap;
    overflow: hidden;
    width: auto;
    max-height: 320px;
    border-radius: 12px;
    padding: 24px 16px 32px;
    margin-bottom: 32px
}

@media(min-width:720px) {
    .pic-block {
        padding: 32px 32px 40px;
        margin-bottom: 48px;
        flex-wrap: nowrap
    }
}

@media(min-width:1248px) {
    .pic-block {
        padding: 48px 48px 56px;
        min-height: 416px
    }
}

.pic-block__bg {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: 60%;
    object-position: 60%
}

@media(min-width:720px) {
    .pic-block__bg {
        -o-object-position: top;
        object-position: top
    }
}

.pic-block__bgMobile {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: 60%;
    object-position: 60%
}

@media(min-width:320px) {
    .pic-block__bgMobile {
        display: block
    }
}

@media(min-width:720px) {
    .pic-block__bgMobile {
        display: none
    }
}

.pic-block__content {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column
}

.pic-block__subtitle {
    color: hsla(0, 0%, 100%, .65);
    line-height: 24px;
    font-family: Roboto, Arial, sans-serif;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 8px
}

@media(min-width:720px) {
    .pic-block__subtitle {
        font-size: 20px;
        font-weight: 900;
        line-height: 30px
    }
}

@media(min-width:1248px) {
    .pic-block__subtitle {
        font-size: 24px;
        line-height: 32px
    }
}

.pic-block__title {
    max-width: 200px;
    font-family: Montserrat, Arial, sans-serif;
    font-size: 24px;
    line-height: 32px;
    color: #fff;
    font-weight: 900;
    margin-bottom: 70px
}

@media(min-width:720px) {
    .pic-block__title {
        font-size: 32px;
        line-height: 42px;
        max-width: inherit;
        margin-bottom: 78px
    }
}

@media(min-width:1248px) {
    .pic-block__title {
        font-size: 40px;
        line-height: 52px;
        margin-bottom: 122px
    }
}

@media(min-width:1632px) {
    .pic-block__title {
        font-size: 48px;
        line-height: 60px;
        margin-bottom: 114px
    }
}

.pic-block__timer {
    z-index: 2
}

.prize-block {
    display: flex;
    align-items: center
}

.prize-block__img {
    width: 56px;
    height: 56px;
    margin-right: 20px
}

.prize-block__img img {
    max-width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.prize-block__content {
    display: grid;
    gap: 4px
}

.prize-block__title {
    font-size: 12px;
    color: hsla(0, 0%, 100%, .65);
    line-height: 16px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: .01em
}

.prize-block__place {
    font-family: Montserrat, Arial, sans-serif;
    font-weight: 900;
    font-size: 20px;
    color: #fff;
    line-height: 26px
}

@media(min-width:1248px) {
    .prize-block__place {
        font-size: 24px;
        line-height: 32px
    }
}

.tourn-player-prizes-list__img {
    position: relative;
    width: 100%;
    height: 108px
}

@media(min-width:1248px) {
    .tourn-player-prizes-list__img {
        height: 140px
    }
}

@media(min-width:1632px) {
    .tourn-player-prizes-list__img {
        margin-bottom: 20px
    }
}

.tourn-player-prizes-list__img img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain
}

.tourn-player-prizes-list__img--big {
    position: relative;
    height: 155px
}

@media(min-width:1248px) {
    .tourn-player-prizes-list__img--big {
        height: 200px
    }
}

.tourn-player-prizes-list__img--big:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: /*url(url(../assets/places_shine.b5b9fbca.svg)*/
    url('') 50%/cover no-repeat
}

.tourn-player-prizes-list__nominal-big {
    font-size: 20px;
    line-height: 30px
}

@media(min-width:960px) {
    .tourn-player-prizes-list__nominal-big {
        line-height: 26px
    }
}

@media(min-width:1248px) {
    .tourn-player-prizes-list__nominal-big {
        font-size: 24px;
        line-height: 32px
    }
}

.tourn-player-prizes-list__name {
    font-size: 14px;
    color: hsla(0, 0%, 100%, .65);
    line-height: 20px
}

.tourn-pool {
    display: grid;
    gap: 4px
}

.tourn-pool__label {
    font-size: 12px;
    color: hsla(0, 0%, 100%, .65);
    line-height: 16px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: .01em
}

.tourn-pool__value {
    font-family: Montserrat, Arial, sans-serif;
    font-weight: 900;
    font-size: 20px;
    color: #fff;
    line-height: 26px
}

.tourn-prizes-three {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    gap: 20px;
    align-items: center;
    justify-items: center;
    text-align: center;
    padding: 0 14px
}

@media(min-width:960px) {
    .tourn-prizes-three {
        margin-right: 16px;
        grid-template-columns: 1fr 1.5fr 1fr
    }
}

@media(min-width:1248px) {
    .tourn-prizes-three {
        gap: 26px
    }
}

@media(min-width:1632px) {
    .tourn-prizes-three {
        gap: 18px
    }
}

.tourn-prizes-three__place {
    display: grid;
    gap: 12px;
    width: 100%
}

.tourn-prizes-three__place--1 {
    order: 1
}

.tourn-prizes-three__place--3 {
    order: 2
}

@media(min-width:1248px) {
    .tourn-prizes-three__place {
        gap: 24px
    }
}

@media(min-width:1632px) {
    .tourn-prizes-three__place {
        gap: 4px
    }
}

.tourn-prizes-three .tourn-player-prizes-list__nominal {
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    line-height: 24px;
    white-space: pre-wrap
}

@media(min-width:960px) {
    .tourn-prizes-three .tourn-player-prizes-list__nominal {
        font-family: Montserrat, Arial, sans-serif;
        font-weight: 900;
        line-height: 22px
    }
}

@media(min-width:1248px) {
    .tourn-prizes-three .tourn-player-prizes-list__nominal {
        font-size: 20px;
        line-height: 26px
    }
}

.tourn-timer__title {
    display: inline-block;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 8px
}

.tourn-timer__over {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 15px 20px;
    background: rgba(28, 30, 38, .65);
    mix-blend-mode: normal;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border-radius: 8px;
    font-size: 14px;
    line-height: 20px;
    color: hsla(0, 0%, 100%, .65);
    font-weight: 700
}

.tourn-winners {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #181920;
    border-radius: 12px;
    padding: 24px
}

@media(min-width:1248px) {
    .tourn-winners {
        padding: 32px 24px
    }
}

.tourn-winners__title {
    font-weight: 900;
    font-size: 20px;
    font-family: Roboto, Arial, sans-serif;
    line-height: 30px;
    margin-bottom: 20px
}

@media(min-width:1248px) {
    .tourn-winners__title {
        font-size: 24px;
        line-height: 32px
    }
}

.tourn-winners__table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    border: none;
    margin: 0 auto;
    max-width: 100%
}

.tourn-winners thead .tourn-winners__cell {
    height: 40px;
    background-color: #262933;
    color: hsla(0, 0%, 100%, .65);
    text-transform: uppercase;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500
}

@media(min-width:720px) {
    .tourn-winners thead .tourn-winners__cell {
        height: 48px
    }
}

.tourn-winners tbody .tourn-winners__row:nth-child(2n) .tourn-winners__cell {
    background-color: #1c1e26
}

.tourn-winners tbody .tourn-winners__row:nth-child(2n) .tourn-winners__cell:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px
}

.tourn-winners tbody .tourn-winners__row:nth-child(2n) .tourn-winners__cell:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px
}

.tourn-winners__cell {
    padding: 0 8px;
    text-align: center;
    vertical-align: middle;
    height: 56px;
    color: #fff
}

.tourn-winners__cell--place {
    text-align: center;
    position: relative
}

.tourn-winners__cell--place .tourn-winners__table--user {
    color: #33d672
}

@media(min-width:720px) {
    tbody tr:first-child .tourn-winners__cell--place,
    tbody tr:nth-child(2) .tourn-winners__cell--place,
    tbody tr:nth-child(3) .tourn-winners__cell--place {
        color: transparent
    }
    tbody tr:first-child .tourn-winners__cell--place:before,
    tbody tr:nth-child(2) .tourn-winners__cell--place:before,
    tbody tr:nth-child(3) .tourn-winners__cell--place:before {
        position: absolute;
        content: "";
        width: 24px;
        height: 36px;
        top: 10px;
        left: 50%;
        transform: translateX(-50%);
        background-image: /*url(url(../assets/first.90ce5db7.svg);*/
        url('');
        background-repeat: no-repeat;
        background-position: 50%
    }
    tbody tr:nth-child(2) .tourn-winners__cell--place:before {
        background-image: /*url(url(../assets/second.2e82948b.svg)*/
        url('');
    }
    tbody tr:nth-child(3) .tourn-winners__cell--place:before {
        background-image: /*url(url(../assets/third.6bfe2276.svg)*/
        url('');
    }
}

.tourn-winners__cell--name {
    text-align: left
}

.tourn-winners__cell--points {
    text-align: right
}

.tourn-winners__cell--prize {
    text-align: right;
    font-weight: 700
}

.tourn-winners__table--user tbody tr:nth-child(2) .tourn-winners__cell--prize,
.tourn-winners__table--user tbody tr:nth-child(3) .tourn-winners__cell--prize,
.tourn-winners__table--user tbody tr:nth-child(4) .tourn-winners__cell--prize,
tbody tr:first-child .tourn-winners__cell--prize,
tbody tr:nth-child(2) .tourn-winners__cell--prize,
tbody tr:nth-child(3) .tourn-winners__cell--prize {
    color: #ffd653
}

@media(min-width:720px) {
    .tourn-winners__cell:last-child {
        padding-right: 32px
    }
}

@media(min-width:1248px) {
    .tourn-winners__cell:last-child {
        padding-right: 24px
    }
}

@media(min-width:1632px) {
    .tourn-winners__cell:last-child {
        padding-right: 64px
    }
}

thead .tourn-winners__cell {
    height: 47px;
    background-color: hsla(0, 0%, 60%, .3);
    border-bottom: none;
    color: #fff
}

@media(min-width:720px) {
    thead .tourn-winners__cell {
        font-size: 16px;
        height: 68px
    }
}

thead .tourn-winners__cell:first-child {
    border-radius: 4px 0 0 4px
}

thead .tourn-winners__cell:last-child {
    border-radius: 0 4px 4px 0
}

thead .tourn-winners__cell--place {
    width: 64px
}

@media(min-width:720px) {
    thead .tourn-winners__cell--place {
        width: 72px
    }
}

@media(min-width:1248px) {
    thead .tourn-winners__cell--place {
        width: 100px
    }
}

thead .tourn-winners__cell--name {
    text-align: left
}

thead .tourn-winners__cell--points,
thead .tourn-winners__cell--prize {
    width: 30%
}

.tourn-winners__row--user .tourn-winners__cell {
    background-color: hsla(0, 0%, 60%, .15);
    border-bottom: none;
    color: #fff!important
}

.tourn-winners__row--user .tourn-winners__cell:first-child {
    border-radius: 4px 0 0 4px
}

.tourn-winners__row--user .tourn-winners__cell:last-child {
    border-radius: 0 4px 4px 0
}

.tourn-winners__name {
    display: block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.game-tourn .tourn-winners .tourn-winners__cell {
    font-size: 12px!important;
    font-weight: 700;
    border-bottom: none;
    height: 47px
}

.game-tourn .tourn-winners .tourn-winners__cell--name {
    padding: 0
}

.game-tourn .tourn-winners .tourn-winners__cell:last-child {
    padding-right: 8px
}

.game-tourn .tourn-winners thead .tourn-winners__cell--place {
    width: 32px;
    padding: 0 5px 0 0
}

.game-tourn .tourn-winners thead .tourn-winners__cell--prize {
    width: 40%
}

.game-tourn .tourn-winners thead .tourn-winners__cell--points {
    width: 23%
}

.game-tourn .tourn-winners .tourn-winners__more {
    display: none
}

.user-menu {
    display: flex;
    flex-direction: column
}

.user-menu__item {
    display: flex;
    align-items: center;
    text-align: left;
    height: 40px;
    padding: 0 16px;
    color: #fff;
    font-weight: 700;
    transition: background-color .3s ease, color .3s ease
}

.user-menu__item:hover {
    background-color: #373a47;
    color: #fff
}

.user-menu__user {
    color: hsla(0, 0%, 100%, .4);
    font-size: 14px;
    font-weight: 400
}

.user-menu__user:hover {
    background-color: transparent
}

.user-menu__logout,
.user-menu__user:hover {
    color: hsla(0, 0%, 100%, .4)
}

.divider {
    margin: 8px 0;
    border-top: 1px solid hsla(0, 0%, 100%, .12)
}

.user-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 24px;
    margin: 0
}

@media(min-width:720px) {
    .user-page-container {
        padding: 40px 48px
    }
}

@media(min-width:1248px) {
    .user-page-container {
        padding: 40px 54px
    }
}

@media(min-width:1632px) {
    .user-page-container {
        padding: 40px 64px
    }
}

@media(min-width:1920px) {
    .user-page-container {
        padding: 40px 68px
    }
}

.full-tourn {
    display: block;
    width: 100%;
    padding-bottom: 32px;
    margin: 0
}

@media(min-width:720px) {
    .full-tourn {
        padding-bottom: 64px
    }
}

.full-tourn__title {
    display: block;
    width: 100%;
    padding: 0;
    margin: 0 0 32px;
    color: #fff;
    font-family: Montserrat, Arial, sans-serif;
    font-size: 24px;
    line-height: 1.2;
    text-align: left;
    font-weight: 700;
    letter-spacing: normal;
    text-transform: uppercase
}

@media(min-width:720px) {
    .full-tourn__title {
        font-size: 40px;
        margin-bottom: 64px
    }
}

.full-tourn__section-title {
    display: inline-flex;
    font-weight: 900;
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 20px
}

@media(min-width:720px) {
    .full-tourn__section-title {
        font-size: 24px;
        line-height: 32px
    }
}

@media(min-width:960px) {
    .full-tourn__section-title {
        margin-bottom: 24px
    }
}

.full-tourn__section {
    display: block;
    width: 100%;
    padding: 0;
    margin: 64px 0 0
}

.full-tourn__section--main {
    margin-top: 0
}

.full-tourn__prizes {
    display: block;
    width: 100%;
    padding: 0;
    margin: 24px 0 0
}

@media(min-width:720px) {
    .full-tourn__prizes {
        margin-top: 32px
    }
}

@media(min-width:1248px) {
    .full-tourn__prizes {
        margin-top: 40px
    }
}

.wrap-tourn .how-work__title {
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 30px;
    color: #fff
}

.wrap-tourn .how-work p {
    margin: 20px 0;
    padding-bottom: 0!important;
    font-size: 16px
}

.wrap-tourn .how-work ol {
    color: hsla(0, 0%, 100%, .65)
}

@media(max-width:375px) {
    .wrap-tourn .how-work ol {
        font-size: 14px;
        line-height: 20px
    }
}

.wrap-tourn .how-work ul {
    color: hsla(0, 0%, 100%, .65)
}

@media(max-width:375px) {
    .wrap-tourn .how-work ul {
        font-size: 14px;
        line-height: 20px
    }
}

.wrap-tourn .how-work li {
    margin-top: 10px
}

.select-with-country-icons__dropdown,
.select__dropdown {
    scrollbar-color: #fff;
    scrollbar-width: thin
}

.select-with-country-icons__dropdown::-webkit-scrollbar,
.select__dropdown::-webkit-scrollbar {
    width: 2px;
    height: 2px
}

.select-with-country-icons__dropdown::-webkit-scrollbar-track,
.select__dropdown::-webkit-scrollbar-track {
    border-radius: 2px;
    background: hsla(0, 0%, 60%, .5)
}

.select-with-country-icons__dropdown::-webkit-scrollbar-thumb,
.select__dropdown::-webkit-scrollbar-thumb {
    background: #fff;
    border-radius: 2px;
    margin: 0
}

.select-with-country-icons__dropdown::-webkit-scrollbar-thumb:hover,
.select__dropdown::-webkit-scrollbar-thumb:hover {
    background: #fff
}

.select,
.select-with-country-icons {
    display: block;
    position: relative;
    width: 100%;
    padding: 0;
    margin: 0;
    font-size: 14px;
    line-height: 1.2;
    text-align: left;
    color: #fff;
    font-weight: 600
}

.select-with-country-icons .input__native:hover,
.select .input__native:hover {
    background-color: #373a47
}

.select__option {
    min-height: 40px;
    font-weight: 400;
    font-size: 16px;
    min-height: 48px
}

.select__option:not(:first-child) {
    margin-top: 1px
}

.select-with-country-icons__bottom-line,
.select__bottom-line {
    position: relative;
    width: 100%;
    height: 0
}

.select-with-country-icons__dropdown,
.select__dropdown {
    display: block;
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    width: 100%;
    padding: 0;
    margin: 0;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    background: #2e313d none;
    border: 0 solid transparent;
    border-radius: 8px;
    text-transform: none;
    max-height: 200px;
    overflow: hidden;
    overflow-y: auto;
    z-index: 100;
    box-shadow: 0 6px 12px rgba(0, 0, 0, .25)
}

.select__arrow-icon {
    font-size: 20px;
    display: block
}

.select-with-country-icons__arrow-icon-wrapper,
.select__arrow-icon-wrapper {
    position: absolute;
    right: 15px;
    top: 50%;
    cursor: pointer;
    font-size: 8px;
    color: hsla(0, 0%, 100%, .4);
    transform-origin: center;
    transform: rotate(0deg);
    transition: transform .3s;
    transform: translateY(-50%) rotate(0deg)
}

.select-with-country-icons__arrow-icon-wrapper--open,
.select__arrow-icon-wrapper--open {
    color: hsla(0, 0%, 100%, .65);
    transform: translateY(-50%) rotate(180deg)
}

.select-with-country-icons__input-country-icon,
.select-with-country-icons__option-country-icon {
    min-width: 24px;
    width: 24px;
    height: 24px
}

.select-with-country-icons__option-country-icon {
    margin-right: 12px
}

.select-with-country-icons__globe-icon {
    min-width: 24px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: .6
}

.auth-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%
}

.auth-form__form-element-wrap {
    width: 100%;
    margin-bottom: 12px;
    margin-top: 32px
}

.auth-form__instructions {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 16px;
    text-align: center
}

.auth-form__errors {
    margin-bottom: 12px
}

.auth-form__sign {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px;
    margin-top: 15px;
    font-size: 14px
}

.auth-form__link {
    font-size: 14px;
    line-height: 20px
}

.errors__error {
    font-size: 12px;
    color: #32CCF2
}