.tournaments-page {
    padding: 24px 0
}

@media(min-width:960px) {
    .tournaments-page {
        padding: 40px 0
    }
}

@media(min-width:1248px) {
    .tournaments-page {
        padding: 40px 0
    }
}

.tournaments-page__title {
    display: inline-flex;
    margin-bottom: 16px
}

@media(min-width:1248px) {
    .tournaments-page__title {
        font-size: 40px;
        line-height: 52px
    }
}

.tournaments-page__description {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
    display: block;
    z-index: 2
}

@media(min-width:720px) {
    .tournaments-page__description {
        margin-bottom: 40px
    }
}

@media(min-width:960px) {
    .tournaments-page__description {
        max-width: 745px
    }
}

@media(min-width:1248px) {
    .tournaments-page__description {
        max-width: 900px
    }
}

.tournaments-page .tournaments-page__container {
    z-index: 1
}

.tournaments-page .tournaments-page__container>* {
    position: relative;
    z-index: 1
}

.tournaments-page__items {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 12px
}

@media(min-width:720px) {
    .tournaments-page__items {
        grid-template-columns: repeat(2, 1fr)
    }
}

@media(min-width:960px) {
    .tournaments-page__items {
        grid-template-columns: repeat(3, 1fr)
    }
}

@media(min-width:1248px) {
    .tournaments-page__items {
        grid-gap: 16px
    }
}