.sign-up-page {
    position: relative;
    padding: 0 15px;
    display: flex;
    flex-direction: column
}

.sign-up-page:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: 272px;
    width: 100%;
    background-image: linear-gradient(180deg, rgba(28, 30, 38, 0) 27%, #1c1e26), url(../assets/bg.15086353.png);
    background-size: cover;
    z-index: -1
}

@media(min-width:960px) {
    .sign-up-page:before {
        display: none
    }
}

@media(min-width:720px) {
    .sign-up-page {
        padding: 52px 32px 20px;
        border-radius: 20px
    }
}

@media(min-width:960px) {
    .sign-up-page {
        flex-direction: row;
        padding: 0
    }
}

.sign-up-page__menu {
    padding-top: 52px;
    margin-bottom: 28px
}

@media(min-width:720px) {
    .sign-up-page__menu {
        padding-top: 0
    }
}

@media(min-width:960px) {
    .sign-up-page__menu {
        padding-top: 0;
        display: none
    }
}

.sign-up-page__header {
    display: none;
    font-weight: 900;
    text-align: left;
    margin-bottom: 24px
}

@media(min-width:720px) {
    .sign-up-page__header {
        display: block
    }
}

.sign-up-page__title {
    display: none
}

.sign-up-page__form-title {
    text-align: center;
    display: none;
    font-weight: 900;
    margin-bottom: 32px;
    margin-top: 0;
    font-family: Roboto, Arial, sans-serif
}

@media(min-width:960px) {
    .sign-up-page__form-title {
        display: block
    }
}

@media(min-width:960px) {
    .sign-up-page__form {
        padding: 40px 48px;
        min-width: 444px
    }
}

.registr-promo--mobile .heading1 {
    font-size: 24px;
    font-family: Montserrat, Arial, sans-serif;
    line-height: 32px;
    font-weight: 900
}

.registr-promo-xs {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding-right: 150px;
    margin-bottom: 36px
}

@media(min-width:720px) {
    .registr-promo-xs {
        margin-bottom: 42px
    }
}

@media(min-width:960px) {
    .registr-promo-xs {
        display: none
    }
}

.registr-promo-xs__img {
    position: absolute;
    right: -32px;
    top: -54px;
    max-width: 200px
}

@media(min-width:720px) {
    .registr-promo-xs__img {
        max-width: 220px
    }
}

.registr-promo-xl {
    width: 100%;
    min-height: 600px;
    padding: 42px 55px;
    text-align: center;
    background-image: /*url(url(../assets/bg-desktop.7dfe9471.png);*/
    url('');
    background-size: cover;
    display: none
}

@media(min-width:960px) {
    .registr-promo-xl {
        display: block
    }
}

.registr-promo-xl .register-promo--desctop {
    display: flex;
    flex-direction: column;
    margin-bottom: auto
}

.registr-promo-xl .register-promo--desctop h6 {
    margin-top: 0;
    margin-bottom: 20px
}

.registr-promo-xl .register-promo--desctop .font-extra {
    margin-top: 0;
    color: #ffd653;
    margin-bottom: 12px
}

.registration-dynamic-form {
    display: block;
    width: 100%
}

.registration-dynamic-form__form-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100%;
    padding: 0;
    margin: 0;
    flex: 1
}

.registration-dynamic-form .registration-dynamic-form__form {
    display: block;
    flex-grow: 1
}

.registration-dynamic-form__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;
    margin: 0;
    color: hsla(0, 0%, 100%, .65)
}

@media(max-width:720px) {
    .registration-dynamic-form__footer {
        padding-bottom: 20px
    }
}

.registration-dynamic-form__label-wrapper {
    display: none;
    width: 100%;
    padding: 0;
    margin: 0 0 16px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    text-transform: uppercase
}

@media(min-width:720px) {
    .registration-dynamic-form__label-wrapper {
        font-size: 16px
    }
}

.registration-dynamic-form .registration-dynamic-form__element--receive_all_promos {
    margin-top: 24px
}

.registration-dynamic-form .registration-dynamic-form__element--age_terms_acceptance {
    margin-top: 18px
}

.registration-dynamic-form__label {
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase
}

@media(min-width:720px) {
    .registration-dynamic-form__label {
        font-size: 16px
    }
}

.registration-dynamic-form .registration-dynamic-form__submit-button {
    margin-top: 24px;
    margin-bottom: 20px;
    width: 100%
}

.registration-dynamic-form__link {
    padding-left: 5px
}