.loXGTB {
    color: rgb(74, 74, 75);
    font-size: 14px;
    font-weight: 400;
}

.DwQUP {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    flex-direction: column;
    gap: 0px 16px;
}

.igMKMI {
    color: rgb(129, 129, 131);
}


@media (min-width: 768px) {
    
    .DwQUP {
        flex-direction: row;
    }
}

@media (min-width: 1200px) {
    .dDphJh {
    max-width: 1300px;
    }
}