.terms-and-conditions__block:not(:last-child) {
    margin-bottom: 32px;
}

.terms-and-conditions__wrapper>* {
    z-index: 1;
}

.terms-and-conditions__block>.collapse-block:not(:last-child) {
    margin-bottom: 8px;
}

.title-accent {
    font-size: 24px;
    line-height: 32px;
    position: relative;
    z-index: 1;
}

.terms-and-conditions__page-title {
    margin-bottom: 32px;
}

.terms-and-conditions__title {
    color: #fff;
    margin-bottom: 16px;
}

@media (min-width: 720px) {
    .terms-and-conditions__block:not(:last-child) {
        margin-bottom: 40px;
    }
    title-accent {
        font-size: 32px;
        line-height: 42px;
    }
    .terms-and-conditions__title {
        margin-bottom: 20px;
    }
}

@media (min-width: 960px) {
    .title-accent {
        font-size: 40px;
        line-height: 50px;
    }
}

@media (min-width: 1248px) {
    .terms-and-conditions__page-title {
        margin-bottom: 40px;
    }
}
