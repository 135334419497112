.bonuses-page {
    padding: 24px 0
}

.bonuses-page:before {
    /*background-image: url(url(../assets/bg--large.51a13029.png);*/
    min-height: 1000px
}

@media(min-width:960px) {
    .bonuses-page {
        padding: 32px 0
    }
}

@media(min-width:1248px) {
    .bonuses-page {
        padding: 48px 0
    }
}

.bonuses-page .bonuses-page__container {
    display: flex;
    flex-direction: column;
    justify-content: center
}

@media(min-width:720px) {
    .bonuses-page .bonuses-page__container {
        max-width: 1332px
    }
}

@media(min-width:960px) {
    .bonuses-page .bonuses-page__container {
        max-width: 1352px
    }
}

@media(min-width:1632px) {
    .bonuses-page .bonuses-page__container {
        max-width: 1620px
    }
}

.bonuses-page__title {
    margin-bottom: 12px;
    text-align: center;
    width: 100%
}

@media(min-width:720px) {
    .bonuses-page__title {
        margin-bottom: 16px
    }
}

@media(min-width:1248px) {
    .bonuses-page__title {
        font-size: 40px;
        line-height: 52px
    }
}

.bonuses-page__description {
    text-align: center;
    margin-bottom: 88px
}

@media(min-width:720px) {
    .bonuses-page__description {
        margin-bottom: 96px
    }
}

@media(min-width:960px) {
    .bonuses-page__description {
        max-width: 645px;
        margin: 0 auto 112px
    }
}

.bonuses-page__container {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 1
}

.bonuses-page__container>* {
    z-index: 1
}

@media(min-width:1632px) {
    .bonuses-page__container {
        max-width: 1020px
    }
}

.bonuses-page__content {
    display: flex;
    flex-direction: column;
    width: 100%
}

@media(min-width:960px) {
    .bonuses-page__content {
        flex-direction: row
    }
}

.bonuses-page__cards {
    margin-bottom: 56px
}

@media(min-width:720px) {
    .bonuses-page__cards {
        margin-bottom: 64px
    }
}

@media(min-width:960px) {
    .bonuses-page__cards {
        margin-bottom: 70px
    }
}

@media(min-width:1248px) {
    .bonuses-page__cards {
        margin-bottom: 80px
    }
}