.home {
    display: block;
    width: 100%;
    padding: 0;
    margin: 0
}

.home .game-category-menu .game-category-menu__item-link,
.home .game-features-select .game-features-select__button {
    color: #fff
}

.home .game-category-menu .game-category-menu__item-link>i,
.home .game-features-select .game-features-select__button>i {
    color: #3ecdf1;
    opacity: 1
}

.home__games-love-bg {
    overflow: hidden;
    background: /*url(url(../assets/categories-bg.d0fd75b5.png)*/
    url('') bottom no-repeat;
    background-size: contain
}

.home__filter {
    display: none
}

@media(min-width:960px) {
    .home__filter {
        display: block;
        margin-top: 40px;
        margin-bottom: 32px
    }
}

@media(min-width:1248px) {
    .home__filter {
        display: block;
        margin: 40px 0
    }
}

.home__margin {
    margin-top: 24px
}

@media(min-width:720px) {
    .home__margin {
        margin-top: 32px
    }
}

@media(min-width:960px) {
    .home__margin {
        display: none
    }
}

@media(min-width:1350px) {
    div .home__slider-item-big {
        width: 247px!important
    }
}

@media(min-width:1632px) {
    div .home__slider-item-big {
        width: 301px!important
    }
}

.home__overflow {
    overflow: hidden
}

.home__providers {
    display: block;
    width: 100%;
    padding: 0;
    margin: 64px 0
}

.home__slider-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 432px;
    outline: none
}

@media(min-width:720px) {
    .home__slider-item {
        height: 520px
    }
}

.home__slider-item--jackpot {
    height: 560px;
    margin-bottom: 24px
}

@media(max-width:1248px) {
    .home__slider-item--jackpot {
        height: 480px
    }
}

@media(max-width:960px) {
    .home__slider-item--jackpot {
        height: 380px;
        margin-bottom: 70px
    }
}

.home__slider-item--jackpot:after {
    content: "";
    display: block;
    height: 350px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(180deg, rgba(28, 30, 38, 0), rgba(28, 30, 38, .76) 72.57%, #1c1e26)
}

@media(max-width:520px) {
    .home__slider-item--jackpot:after {
        height: 230px
    }
}

.home__slider-item--jackpot .container {
    position: relative;
    z-index: 2
}

@media(max-width:520px) {
    .home__slider-item--jackpot .home-slide__image {
        -o-object-fit: inherit;
        object-fit: inherit;
        width: auto;
        height: 100%;
        position: absolute;
        right: -210px;
        top: 0
    }
}

.home__slider-item--jackpot .home-slide__logo {
    margin-bottom: 12px
}

@media(max-width:960px) {
    .home__slider-item--jackpot .home-slide__logo {
        width: 72px
    }
}

.home__slider-item--jackpot .home-slide__block-inner {
    text-align: center
}

.home__slider-item--jackpot .home-slide__block {
    padding-top: 64px
}

@media(max-width:1248px) {
    .home__slider-item--jackpot .home-slide__block {
        padding-top: 48px
    }
}

@media(max-width:960px) {
    .home__slider-item--jackpot .home-slide__block {
        padding-top: 40px
    }
}

.home__slider-item--jackpot .home-slide__logo-title {
    font-family: Montserrat Alternates, sans-serif;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: .01em;
    text-transform: uppercase;
    color: #fff
}

@media(max-width:960px) {
    .home__slider-item--jackpot .home-slide__logo-title {
        font-size: 28px;
        line-height: 36px
    }
}

.home__slider-item--jackpot .home-slide__pretitle {
    position: relative;
    z-index: 2;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: hsla(0, 0%, 100%, .65)
}

.home__slider-item--jackpot .home-slide__title {
    font-family: Montserrat, Arial, sans-serif;
    margin-bottom: 0;
    font-style: normal;
    font-weight: 900;
    font-size: 64px;
    line-height: 80px;
    text-align: center;
    background: -webkit-linear-gradient(#fffdde, #f8ff87, #ffa000, #ffdc7d);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
    z-index: 2
}

@media(max-width:960px) {
    .home__slider-item--jackpot .home-slide__title {
        font-size: 48px;
        line-height: 60px
    }
}

@media(max-width:520px) {
    .home__slider-item--jackpot .home-slide__title {
        font-size: 40px;
        line-height: 52px
    }
}

.home__slider-item--jackpot .home-slide__jackpot-total-wrap {
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    width: 500px;
    height: 158px;
    padding: 24px;
    background: hsla(0, 0%, 100%, .04);
    -webkit-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px);
    border-radius: 24px;
    position: relative;
    overflow: visible
}

.home__slider-item--jackpot .home-slide__jackpot-total-wrap:after {
    content: "";
    display: block;
    width: 108%;
    height: 108%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: /*url(url(../assets/jackpots-lights.e16e08f4.png)*/
    url('') 50% no-repeat;
    background-size: cover
}

@media(max-width:1248px) {
    .home__slider-item--jackpot .home-slide__jackpot-total-wrap {
        margin-top: 40px
    }
}

@media(max-width:960px) {
    .home__slider-item--jackpot .home-slide__jackpot-total-wrap {
        margin-top: 36px;
        width: 436px;
        height: 142px;
        padding: 26px
    }
    .home__slider-item--jackpot .home-slide__jackpot-total-wrap:after {
        /*background: url(url(../assets/jackpots-lights-md.ccd0dd2a.png) 50% no-repeat;*/
        background-size: cover
    }
}

@media(max-width:520px) {
    .home__slider-item--jackpot .home-slide__jackpot-total-wrap {
        width: 100%;
        height: 128px
    }
    .home__slider-item--jackpot .home-slide__jackpot-total-wrap:after {
        /*background: url(url(../assets/jackpots-lights-xs.39dd9c08.png) 50% no-repeat;*/
        background-size: 100% 100%;
        width: 112%;
        height: 130%
    }
}

.promotions-slider {
    margin-bottom: 24px
}

@media(min-width:720px) {
    .promotions-slider {
        margin-bottom: 32px
    }
}

@media(min-width:1248px) {
    .promotions-slider {
        margin-bottom: 40px
    }
}

.promotions-slider__controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px
}

@media(min-width:720px) {
    .promotions-slider__controls {
        margin-bottom: 22px
    }
}

@media(min-width:960px) {
    .promotions-slider__controls {
        margin-bottom: 16px
    }
}

@media(min-width:1248px) {
    .promotions-slider__controls {
        margin-bottom: 20px
    }
}

.promotions-slider__title {
    color: #fff
}

.promotions-slider__buttons-wrap {
    display: none
}

@media(min-width:960px) {
    .promotions-slider__buttons-wrap {
        display: block
    }
}

.promotions-slider__button {
    min-width: 40px!important;
    width: 40px
}

.promotions-slider__button--prev {
    border-radius: 8px 0 0 8px!important
}

.promotions-slider__button--prev>i {
    transform: rotate(90deg)
}

.promotions-slider__button--next {
    border-radius: 0 8px 8px 0!important
}

.promotions-slider__button--next>i {
    transform: rotate(-90deg)
}

.promotions-slider.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent
}

.promotions-slider.slick-slider .slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0
}

@media(max-width:960px) {
    .promotions-slider.slick-slider .slick-list {
        overflow: visible;
        right: 8px
    }
}

.promotions-slider.slick-slider .slick-list:focus {
    outline: none
}

.promotions-slider.slick-slider .slick-list.dragging {
    cursor: pointer;
    cursor: hand
}

.promotions-slider.slick-slider .slick-list,
.promotions-slider.slick-slider .slick-track {
    transform: translateZ(0)
}

.promotions-slider.slick-slider .slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto
}

.promotions-slider.slick-slider .slick-track:after,
.promotions-slider.slick-slider .slick-track:before {
    content: "";
    display: table
}

.promotions-slider.slick-slider .slick-track:after {
    clear: both
}

.slick-loading .promotions-slider.slick-slider .slick-track {
    visibility: hidden
}

.promotions-slider.slick-slider .slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    display: none
}

.promotions-slider.slick-slider .slick-slide:not(:last-child) {
    margin-right: 8px
}

@media(min-width:720px) {
    .promotions-slider.slick-slider .slick-slide:not(:last-child) {
        margin-right: 12px
    }
}

@media(min-width:1248px) {
    .promotions-slider.slick-slider .slick-slide:not(:last-child) {
        margin-right: 16px
    }
}

[dir=rtl] .promotions-slider.slick-slider .slick-slide {
    float: right
}

.promotions-slider.slick-slider .slick-slide.slick-loading img {
    display: none
}

.promotions-slider.slick-slider .slick-slide.dragging img {
    pointer-events: none
}

.promotions-slider.slick-slider.slick-initialized .slick-slide {
    display: block
}

.promotions-slider.slick-slider.slick-loading .slick-slide {
    visibility: hidden
}

.promotions-slider.slick-slider.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent
}

.promotions-slider.slick-slider .slick-arrow.slick-hidden {
    display: none
}

.provider-slider {
    margin-bottom: 44px
}

@media(min-width:720px) {
    .provider-slider {
        margin-bottom: 60px
    }
}

@media(min-width:960px) {
    .provider-slider {
        margin-bottom: 52px
    }
}

@media(min-width:1248px) {
    .provider-slider {
        margin-bottom: 60px
    }
}

@media(min-width:1632px) {
    .provider-slider {
        margin-bottom: 100px
    }
}

.provider-slider__controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px
}

@media(min-width:720px) {
    .provider-slider__controls {
        margin-bottom: 22px
    }
}

@media(min-width:960px) {
    .provider-slider__controls {
        margin-bottom: 16px
    }
}

@media(min-width:1248px) {
    .provider-slider__controls {
        margin-bottom: 20px
    }
}

.provider-slider__title {
    color: #fff
}

.provider-slider__buttons-wrap {
    display: none
}

@media(min-width:960px) {
    .provider-slider__buttons-wrap {
        display: block
    }
}

.provider-slider__button {
    min-width: 40px!important;
    width: 40px
}

.provider-slider__button--prev {
    border-radius: 8px 0 0 8px!important
}

.provider-slider__button--prev>i {
    transform: rotate(90deg)
}

.provider-slider__button--next {
    border-radius: 0 8px 8px 0!important
}

.provider-slider__button--next>i {
    transform: rotate(-90deg)
}

.provider-slider.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent
}

.provider-slider.slick-slider .slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0
}

.provider-slider.slick-slider .slick-list:focus {
    outline: none
}

.provider-slider.slick-slider .slick-list.dragging {
    cursor: pointer;
    cursor: hand
}

.provider-slider.slick-slider .slick-list,
.provider-slider.slick-slider .slick-track {
    transform: translateZ(0)
}

.provider-slider.slick-slider .slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto
}

.provider-slider.slick-slider .slick-track:after,
.provider-slider.slick-slider .slick-track:before {
    content: "";
    display: table
}

.provider-slider.slick-slider .slick-track:after {
    clear: both
}

.slick-loading .provider-slider.slick-slider .slick-track {
    visibility: hidden
}

.provider-slider.slick-slider .slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    display: none
}

.provider-slider.slick-slider .slick-slide:not(:last-child) {
    margin-right: 8px
}

@media(min-width:720px) {
    .provider-slider.slick-slider .slick-slide:not(:last-child) {
        margin-right: 12px
    }
}

@media(min-width:1248px) {
    .provider-slider.slick-slider .slick-slide:not(:last-child) {
        margin-right: 16px
    }
}

[dir=rtl] .provider-slider.slick-slider .slick-slide {
    float: right
}

.provider-slider.slick-slider .slick-slide.slick-loading img {
    display: none
}

.provider-slider.slick-slider .slick-slide.dragging img {
    pointer-events: none
}

.provider-slider.slick-slider.slick-initialized .slick-slide {
    display: block
}

.provider-slider.slick-slider.slick-loading .slick-slide {
    visibility: hidden
}

.provider-slider.slick-slider.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent
}

.provider-slider.slick-slider .slick-arrow.slick-hidden {
    display: none
}

.categories-game-slider {
    width: calc(100% + 8px);
    margin-left: -4px
}

@media(min-width:720px) {
    .categories-game-slider {
        width: calc(100% + 12px);
        margin-left: -6px
    }
}

@media(min-width:1248px) {
    .categories-game-slider {
        width: calc(100% + 16px);
        margin-left: -8px
    }
}

.categories-game-slider .categories-game-slider__item {
    position: relative;
    min-height: 220px;
    height: 100%;
    border-radius: 20px;
    padding: 16px;
    display: flex!important;
    flex-direction: column;
    justify-content: flex-end;
    color: #fff;
    z-index: 2;
    margin: 0 4px;
    width: calc(50vw - 20px)!important
}

@media(min-width:600px) {
    .categories-game-slider .categories-game-slider__item {
        margin: 0 6px;
        width: calc(33.33333vw - 21px)!important
    }
}

@media(min-width:720px) {
    .categories-game-slider .categories-game-slider__item {
        width: calc(25vw - 21px)!important
    }
}

@media(min-width:960px) {
    .categories-game-slider .categories-game-slider__item {
        min-height: 240px;
        padding: 20px;
        width: calc(20vw - 22px)!important
    }
}

@media(min-width:1248px) {
    .categories-game-slider .categories-game-slider__item {
        min-height: 320px;
        padding: 24px;
        margin: 0 8px;
        width: calc(20vw - 26px)!important
    }
}

@media(min-width:1360px) {
    .categories-game-slider .categories-game-slider__item {
        width: 248px!important
    }
}

.categories-game-slider .categories-game-slider__item:after {
    position: absolute;
    content: "";
    bottom: 0;
    top: auto;
    left: 0;
    right: 0;
    z-index: -2;
    border-radius: 20px;
    height: calc(100% - 20px)
}

.categories-game-slider .categories-game-slider__item--slots:after {
    background: linear-gradient(144.26deg, #c9ff72 1.47%, #0d844b)
}

.categories-game-slider .categories-game-slider__item--jackpots:after {
    background: linear-gradient(144.26deg, #ffdf6c 1.47%, #d97b24)
}

.categories-game-slider .categories-game-slider__item--live:after {
    background: linear-gradient(144.26deg, #6cd3ff 1.47%, #3532d7)
}

.categories-game-slider .categories-game-slider__item--table-games:after {
    background: linear-gradient(144.26deg, #be6cff 1.47%, #7322db)
}

.categories-game-slider .categories-game-slider__item--drops-wins:after {
    background: linear-gradient(144.26deg, #ffb36c 1.47%, #d7324b)
}

.categories-game-slider .categories-game-slider__item-image {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    -o-object-fit: scale-down;
    object-fit: scale-down;
    z-index: -1;
    transition: .5s
}

.categories-game-slider .categories-game-slider__item-image:hover {
    height: calc(100% + 16px);
    top: -25px
}

@media(min-width:1248px) {
    .categories-game-slider .categories-game-slider__item-image {
        -o-object-fit: cover;
        object-fit: cover
    }
}

.categories-game-slider .categories-game-slider__item-title {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 4px;
    font-family: Montserrat, Arial, sans-serif
}

@media(min-width:1248px) {
    .categories-game-slider .categories-game-slider__item-title {
        font-size: 24px;
        line-height: 32px
    }
}

.categories-game-slider .categories-game-slider__item-description {
    font-size: 14px;
    line-height: 20px
}

@media(min-width:1248px) {
    .categories-game-slider .categories-game-slider__item-description {
        font-size: 16px;
        line-height: 24px
    }
}

.categories-game-slider .slick-slide {
    padding: 0!important
}

.categories-game-slider .slick-list {
    overflow: visible!important
}

.home-top-games {
    margin-bottom: 24px
}

@media(min-width:720px) {
    .home-top-games {
        margin-bottom: 32px
    }
}

@media(min-width:1248px) {
    .home-top-games {
        margin-bottom: 40px
    }
}

.home-top-games__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px
}

@media(min-width:720px) {
    .home-top-games__head {
        margin-bottom: 16px
    }
}

@media(min-width:1248px) {
    .home-top-games__head {
        margin-bottom: 20px
    }
}

.home-top-games__title {
    color: #fff
}

.home-top-games__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px
}

@media(min-width:600px) {
    .home-top-games__list {
        grid-template-columns: repeat(3, 1fr)
    }
}

@media(min-width:720px) {
    .home-top-games__list {
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 12px
    }
}

@media(min-width:960px) {
    .home-top-games__list {
        grid-template-columns: repeat(6, 1fr)
    }
}

@media(min-width:1248px) {
    .home-top-games__list {
        grid-gap: 16px
    }
}

.home-top-games__list-item--banner {
    grid-column-start: 1;
    grid-column-end: 3
}

@media screen and (max-width:601px) {
    .home-top-games__list-item--banner .game,
    .home-top-games__list-item--banner .gametile--banner {
        padding-top: 0
    }
}

@media(min-width:600px) {
    .home-top-games__list-item--banner {
        grid-row-start: 1;
        grid-row-end: 3
    }
}

.home-top-games__list-item--orange {
    background: linear-gradient(135.51deg, #fe9705, #c518b3 99.13%)!important
}

.home-top-games__list-item--blue {
    background: linear-gradient(135.51deg, #e0b7ff, #5264ed 99.13%)!important
}

.home-top-games__list-item--banner {
    position: relative;
    background: linear-gradient(135.51deg, #33fac1, #0269af 99.13%);
    border-radius: 8px;
    min-height: 176px
}

.home-top-games__list-item--banner .gametile--banner {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2
}

.home-top-games__list-item--banner .game__wrap:before {
    background: transparent!important
}

.home-top-games__list-item--banner .game__demo,
.home-top-games__list-item--banner .game__favorite {
    display: none
}

.home-top-games__list-item--banner .game__content {
    position: absolute;
    z-index: 2;
    opacity: 1;
    top: 110px;
    left: 16px
}

@media(min-width:600px) {
    .home-top-games__list-item--banner .game__content {
        top: auto;
        bottom: 28px;
        left: 28px
    }
}

.home-top-games__list-item--banner .game__img {
    position: absolute;
    width: 56px;
    height: 56px;
    left: 16px;
    top: 16px;
    z-index: 2
}

@media(min-width:600px) {
    .home-top-games__list-item--banner .game__img {
        width: 104px;
        height: 104px;
        left: 24px;
        top: 24px
    }
}

@media(min-width:720px) {
    .home-top-games__list-item--banner .game__img {
        width: 80px;
        height: 80px
    }
}

@media(min-width:960px) {
    .home-top-games__list-item--banner .game__img {
        width: 104px;
        height: 104px
    }
}

@media(min-width:1632px) {
    .home-top-games__list-item--banner .game__img {
        width: 128px;
        height: 128px;
        left: 32px
    }
}

.home-top-games__list-item--banner .game__button,
.home-top-games__list-item--banner .game__buttons {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0
}

.home-top-games__list-item--banner .game__button {
    display: block;
    opacity: 0
}

.home-top-games__list-item--banner .game__action {
    background: transparent!important;
    opacity: 1!important;
    align-items: flex-end;
    height: 100%;
    flex-direction: inherit;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding: 16px;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2
}

.home-top-games__list-item--banner .game__action .game__play {
    width: 100%;
    height: 100%
}

@media(min-width:600px) {
    .home-top-games__list-item--banner .game__action {
        flex-wrap: wrap;
        justify-content: flex-start;
        padding: 38px
    }
}

.home-top-games__list-item--banner .game__title {
    text-align: left;
    width: 100%;
    margin-bottom: 0;
    font-weight: 900;
    font-size: 24px;
    line-height: 32px;
    color: #fff;
    padding: 0
}

@media(min-width:600px) {
    .home-top-games__list-item--banner .game__title {
        font-size: 32px;
        line-height: 42px;
        margin-bottom: 8px
    }
}

.home-top-games__list-item--banner .game__title a {
    font-size: inherit;
    line-height: inherit
}

.home-top-games__list-item--banner .game__provider {
    text-align: left;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: .01em;
    text-transform: uppercase
}

.home-top-games__person {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 100%
}

@media(min-width:720px) {
    .landing-game .landing-game__slider {
        display: none!important
    }
}

.landing-game .landing-game__slider-item {
    margin-bottom: 8px
}

.landing-game__blocks-item:first-child .game__wrap {
    padding: 28px!important
}

.landing-game__blocks {
    display: none;
    grid-template-columns: repeat(3, minmax(50px, auto));
    grid-template-rows: repeat(3, minmax(50px, auto));
    gap: 8px;
    justify-content: end
}

@media(min-width:720px) {
    .landing-game__blocks {
        display: grid
    }
}

@media(min-width:960px) {
    .landing-game__blocks {
        grid-template-columns: repeat(4, minmax(80px, 160px));
        grid-template-rows: repeat(4, minmax(80px, 160px));
        gap: 12px
    }
    .landing-game__blocks .game__img {
        position: absolute
    }
}

@media(min-width:1248px) {
    .landing-game__blocks {
        grid-template-columns: repeat(4, minmax(80px, 182px));
        grid-template-rows: repeat(4, minmax(80px, 182px));
        gap: 16px
    }
}

.landing-game__blocks-item {
    overflow: hidden
}

.landing-game__blocks-item:first-child {
    grid-column: span 2;
    grid-row: span 2
}

@media(min-width:960px) {
    .landing-game__blocks-item:first-child {
        grid-column: span 2;
        grid-row: span 2
    }
}

@media(min-width:960px) {
    .landing-game__blocks-item:last-child {
        grid-column: span 2;
        grid-row: span 1
    }
    .landing-game__blocks-item:last-child>* {
        height: 100%
    }
}

@media(min-width:720px) {
    .landing-game-wins-slider .landing-game-wins-slider__slider {
        display: none!important
    }
}

.landing-game-wins-slider .landing-game-wins-slider__slider-item {
    margin-bottom: 8px
}

.landing-game-wins-slider__blocks {
    display: none;
    grid-template-columns: repeat(3, minmax(50px, auto));
    grid-template-rows: repeat(2, minmax(50px, auto));
    gap: 8px
}

@media(min-width:720px) {
    .landing-game-wins-slider__blocks {
        display: grid
    }
}

@media(min-width:960px) {
    .landing-game-wins-slider__blocks {
        grid-template-columns: repeat(4, minmax(80px, auto));
        grid-template-rows: repeat(2, minmax(80px, auto));
        gap: 12px
    }
}

@media(min-width:1248px) {
    .landing-game-wins-slider__blocks {
        gap: 16px
    }
}

.landing-game-wins-slider__blocks-item {
    overflow: hidden
}

.landing-game-wins-slider__blocks-item:first-child {
    grid-column: span 2;
    grid-row: span 2
}

@media(min-width:960px) {
    .landing-game-wins-slider__blocks-item:first-child {
        grid-column: span 2;
        grid-row: span 2
    }
}

.slide-tourn {
    position: relative;
    height: 100%;
    padding: 28px 24px 24px;
    border-radius: 20px;
    overflow: hidden
}

.slide-tourn__bg-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-height: 100%;
    min-width: 100%
}

.slide-tourn__bg-wrap img {
    height: 100%;
    min-width: 100%
}

.slide-tourn__content {
    position: relative;
    z-index: 2;
    height: 100%
}

.slide-tourn__pretitle {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: hsla(0, 0%, 100%, .65);
    text-transform: uppercase;
    margin-bottom: 16px
}

.slide-tourn__title {
    font-weight: 900;
    font-size: 28px;
    line-height: 42px;
    margin-bottom: 8px
}

@media(min-width:960px) {
    .slide-tourn__title {
        font-size: 32px
    }
}

.slide-tourn__pool {
    font-weight: 900;
    font-size: 20px;
    line-height: 30px;
    color: #ffd653
}

.slide-tourn__block-button {
    display: flex;
    gap: 12px
}

.slide-tourn__button {
    bottom: 32px;
    padding: 10px 20px;
    background: hsla(0, 0%, 100%, .2);
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    border-radius: 4px;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    z-index: 2
}

.slide-tourn .tourn-timer,
.slide-tourn__button {
    position: absolute;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center
}

.slide-tourn .tourn-timer {
    bottom: -1px;
    min-width: 100%
}

.slide-tourn .tourn-timer__title {
    position: relative;
    padding-left: 26px;
    font-size: 14px;
    line-height: 20px;
    color: #fff
}

.slide-tourn .tourn-timer__title:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    background: /*url(url(../assets/time.0511d20d.svg)*/
    url('') 50% no-repeat
}

.slide-tourn .tourn-timer .inline-timer {
    min-height: inherit;
    margin-top: 0;
    background-color: transparent;
    width: auto;
    padding-top: 0;
    padding-bottom: 0
}

.slide-tourn .tourn-timer .tourn-timer__title {
    width: auto;
    white-space: nowrap
}

.slide-tourn .tourn-timer .inline-timer__label,
.slide-tourn .tourn-timer .inline-timer__text,
.slide-tourn .tourn-timer .inline-timer__value {
    font-size: 14px;
    line-height: 20px;
    color: #fff;
    text-transform: none
}

.slide-tourn__winners-wrap {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%
}

.slide-tourn .promotion-bonus__footer-btn {
    margin-bottom: 16px;
    background: hsla(0, 0%, 100%, .12);
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    border-radius: 8px
}

.slide-tourn .promotion-bonus__footer-btn:hover {
    background: none;
    box-shadow: none
}

.slide-tourn .promotion-bonus__footer-btn--disabled {
    margin-bottom: 16px;
    width: 100%;
    background: transparent;
    border: 1px solid hsla(0, 0%, 100%, .12);
    gap: 9px;
    opacity: 1
}

.slide-tourn .promotion-bonus__footer-btn--disabled i {
    color: #33d672
}

.slide-tourn .promotion-bonus__more-info {
    width: 100%;
    background: none;
    border: 1px solid hsla(0, 0%, 100%, .2);
    margin-bottom: 16px
}

.slide-tourn .promotion-bonus__more-info:hover {
    background: none;
    box-shadow: none
}

.provider-slider__item {
    display: flex!important;
    align-items: center;
    justify-content: center;
    padding: 20px;
    width: calc(50vw - 20px)!important;
    height: 94px!important;
    border-radius: 8px;
    background-color: #262933;
    transition: .3s ease
}

@media(min-width:600px) {
    .provider-slider__item {
        width: calc(33.33333vw - 16px)!important
    }
}

@media(min-width:720px) {
    .provider-slider__item {
        padding: 24px 20px;
        width: calc(25vw - 17px)!important
    }
}

@media(min-width:960px) {
    .provider-slider__item {
        padding: 18px 20px;
        height: 84px!important;
        width: calc(16.66667vw - 19px)!important
    }
}

@media(min-width:1248px) {
    .provider-slider__item {
        padding: 28px 36px;
        height: 110px!important;
        width: calc(16.66667vw - 22px)!important
    }
}

@media(min-width:1350px) {
    .provider-slider__item {
        width: 203px!important
    }
}

@media(min-width:1632px) {
    .provider-slider__item {
        padding: 40px;
        height: 148px!important;
        width: 248px!important
    }
}

.provider-slider__item:hover {
    background-color: #2e313d
}

.provider-slider__item>img {
    max-width: 100%;
    max-height: 100%;
    min-width: 100%
}

.home-device {
    position: relative;
    background: linear-gradient(288.85deg, #48b2ff 26.61%, #2e55df 74.55%);
    padding: 200px 0 40px;
    margin-top: 110px
}

@media(min-width:720px) {
    .home-device {
        padding: 40px 0;
        margin-top: 50px
    }
}

@media(min-width:960px) {
    .home-device {
        padding: 80px 0;
        margin-top: 100px
    }
}

@media(min-width:1248px) {
    .home-device {
        padding: 84px 0;
        margin-top: 140px
    }
}

.home-device .pwa-btn {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    background: transparent!important;
    box-shadow: none!important;
    color: transparent!important;
    z-index: 2
}

.home-device .home-device__container {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: visible
}

@media(min-width:720px) {
    .home-device .home-device__container {
        flex-direction: row;
        align-items: center;
        justify-content: space-between
    }
}

.home-device__info {
    display: flex;
    flex-direction: column;
    text-align: center
}

@media(min-width:720px) {
    .home-device__info {
        text-align: left;
        max-width: 350px
    }
}

@media(min-width:960px) {
    .home-device__info {
        max-width: 372px
    }
}

@media(min-width:1248px) {
    .home-device__info {
        max-width: 500px
    }
}

.home-device__title {
    display: block;
    margin-bottom: 16px;
    font-size: 24px;
    line-height: 32px;
    text-align: center
}

@media(min-width:720px) {
    .home-device__title {
        text-align: left;
        font-size: 32px;
        line-height: 42px
    }
}

@media(min-width:1248px) {
    .home-device__title {
        font-size: 40px;
        line-height: 52px
    }
}

.home-device__description {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: hsla(0, 0%, 100%, .65);
    text-align: center
}

@media(min-width:720px) {
    .home-device__description {
        text-align: left
    }
}

.home-device .home-device__btn {
    display: none;
    background: hsla(0, 0%, 100%, .12);
    color: #fff;
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    margin: 24px auto 0
}

@media(min-width:720px) {
    .home-device .home-device__btn {
        margin-left: 0
    }
}

@media(min-width:960px) {
    .home-device .home-device__btn {
        display: none
    }
}

.home-device__icon {
    margin-right: 8px
}

.home-device__image {
    position: absolute;
    left: 50%;
    top: -300px;
    max-width: 456px;
    transform: translateX(-50%)
}

@media(min-width:720px) {
    .home-device__image {
        left: auto;
        right: -200px;
        top: -40px;
        max-width: 670px;
        transform: none
    }
}

@media(min-width:960px) {
    .home-device__image {
        max-width: 760px;
        right: -150px;
        top: -160px
    }
}

@media(min-width:1248px) {
    .home-device__image {
        max-width: 820px;
        right: -80px;
        top: -200px
    }
}

.home-games-logos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px
}

@media(min-width:720px) {
    .home-games-logos {
        justify-content: flex-start;
        margin-bottom: 40px
    }
}

.home-games-logos__item {
    max-width: 80px;
    width: calc(33% - 20px);
    opacity: .35;
    margin-right: 20px;
    margin-bottom: 20px;
    transition: opacity .3s ease
}

.home-games-logos__item:hover {
    opacity: 1
}

@media(min-width:1248px) {
    .home-games-logos__item {
        max-width: 100px;
        margin-right: 36px
    }
}

.home-games-logos__img {
    width: 100%
}

.home-games-logos .home-games-logos__btn-more {
    margin-bottom: 20px;
    font-size: 16px;
    background-color: transparent;
    border: none;
    text-transform: lowercase
}

@media(max-width:1632px) {
    .home-games-logos .home-games-logos__btn-more {
        border: 1px solid hsla(0, 0%, 100%, .12);
        width: 100px;
        height: 40px;
        border-radius: 8px
    }
}

.home-header-sign {
    position: relative;
    background-image: /*url(/BG_Basegame.png);*/
    url('');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top
}

.home-header-sign:after {
    content: "";
    background: linear-gradient(52.44deg, #a92ad6 3.77%, #313fbf 98.17%);
    opacity: .8;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1
}

.home-header-sign__info-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: nowrap
}

@media(max-width:960px) {
    .home-header-sign__info-wrapper {
        flex-wrap: wrap
    }
}

.home-header-sign__item {
    display: block
}

@media(max-width:960px) {
    .home-header-sign__item {
        width: 50%;
        margin: 0 auto
    }
}

.home-header-sign .home-header-sign__container {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 4
}

@media(min-width:960px) {
    .home-header-sign .home-header-sign__container {
        flex-direction: row;
        align-items: center;
        justify-content: space-between
    }
}

.home-header-sign__description {
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    color: hsla(0, 0%, 100%, .65)
}

.home-header-sign__title {
    margin-bottom: 16px;
    font-size: 24px;
    line-height: 32px;
    font-family: Montserrat, Arial, sans-serif;
    font-weight: 900
}

.home-header-sign__info {
    max-width: 471px;
    left: 460px
}

@media(max-width:1920px) {
    .home-header-sign__info {
        left: 425px
    }
}

@media(max-width:1632px) {
    .home-header-sign__info {
        left: 414px;
        max-width: 388px
    }
}

@media(max-width:1248px) {
    .home-header-sign__info {
        left: 300px;
        max-width: 388px
    }
}

@media(max-width:960px) {
    .home-header-sign__info {
        text-align: center;
        margin: 0 auto 40px;
        max-width: inherit;
        width: 100%
    }
}

@media(max-width:960px) {
    .home-header-sign__btns-wrap {
        display: flex;
        justify-content: center
    }
}

.home-header-sign__btn {
    display: block;
    margin-left: auto;
    min-width: 155px;
    font-size: 16px;
    font-weight: 700;
    padding: 16px 24px!important
}

@media(max-width:960px) {
    .home-header-sign__btn {
        bottom: 30px;
        margin: 0 auto;
        min-width: 300px
    }
}

.home-header-sign__btn--sm {
    display: none
}

.home-header {
    position: relative;
    z-index: 1;
    padding: 90px 0 34px;
    margin-top: -56px;
    margin-bottom: 30px
}

@media(min-width:960px) {
    .home-header {
        margin-bottom: 0
    }
}

@media(min-width:1248px) {
    .home-header {
        margin-top: -80px;
        padding: 100px 0 32px
    }
}

.home-header__banner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    z-index: -1
}

.home-header__banner-desktop {
    display: none
}

@media(min-width:720px) {
    .home-header__banner-desktop {
        display: block
    }
}

.home-header__banner-mobile {
    display: block
}

@media(min-width:720px) {
    .home-header__banner-mobile {
        display: none
    }
}

.home-header .home-header__container {
    display: flex
}

@media(min-width:720px) {
    .home-header .home-header__container {
        flex-direction: row;
        align-items: center;
        justify-content: space-between
    }
}

@media(max-width:720px) {
    .home-header .home-header__container--nosign {
        flex-wrap: wrap
    }
}

.home-header__info {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    padding-right: 0
}

@media(min-width:720px) {
    .home-header__info {
        padding-right: 40px;
        text-align: left;
        width: calc(100% - 330px)
    }
}

@media(min-width:960px) {
    .home-header__info {
        width: calc(100% - 420px)
    }
}

@media(min-width:1248px) {
    .home-header__info {
        width: calc(100% - 620px);
        margin: 80px 0
    }
}

.home-header__subtitle {
    margin-bottom: 8px;
    color: #fff
}

@media(min-width:960px) {
    .home-header__subtitle {
        margin-bottom: 12px
    }
}

@media(min-width:1248px) {
    .home-header__subtitle {
        margin-bottom: 16px
    }
}

.home-header__title {
    text-transform: uppercase;
    margin-bottom: 16px
}

.home-header__description {
    margin-bottom: 24px;
    font-size: 20px;
    line-height: 30px;
    color: #fff
}

@media(max-width:960px) {
    .home-header__description {
        display: none
    }
}

.home-header__form {
    position: relative;
    width: 100%;
    /*background: rgba(19, 21, 28, .65);*/
    -webkit-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px);
    border-radius: 20px;
    padding: 40px 44px 170px;
    text-align: center;
    margin-left: auto
}

@media(min-width:720px) {
    .home-header__form {
        padding: 32px 40px 170px;
        max-width: 343px
    }
}

@media(min-width:960px) {
    .home-header__form {
        min-width: 420px
    }
}

.home-header__form h3 {
    font-weight: 700;
    font-family: Roboto, Arial, sans-serif;
    font-size: 16px;
    line-height: 30px;
    color: #fff;
    margin-bottom: 16px;
    text-align: center;
    display: block
}

.home-header__form h4 {
    display: block;
    font-weight: 900;
    font-size: 64px;
    line-height: 80px;
    color: #ffc200;
    text-align: center
}

@media(max-width:960px) {
    .home-header__form h4 {
        font-size: 48px;
        margin-top: 8px;
        line-height: 56px
    }
}

.home-header__form p {
    font-weight: 900;
    font-size: 24px;
    line-height: 32px;
    margin-top: 8px;
    text-align: center;
    color: #fff
}

.home-header__form_btn {
    display: block;
    width: 100%;
    margin: 32px auto 0!important;
    padding: 16px 24px!important;
    max-width: inherit!important;
    height: 48px
}

.home-header__form_btn--sm {
    display: none
}

@media(min-width:720px) {
    .home-header__form_btn--sm {
        display: block;
        margin-left: 0
    }
}

.home-header__info {
    display: block
}

.home-header__title {
    display: inline-block
}

.home-header__btns-wrap {
    position: absolute;
    bottom: 32px;
    left: 20px;
    width: calc(100% - 40px)
}

@media(min-width:720px) {
    .home-header__btns-wrap {
        bottom: 40px;
        width: calc(100% - 80px);
        left: 40px
    }
}

.home-header .home-header__reg {
    height: auto;
    min-height: inherit;
    display: inline-block;
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    color: hsla(0, 0%, 100%, .65);
    font-weight: 400;
    margin-top: 20px!important;
    background: none!important;
    padding: 0!important;
    box-shadow: none!important
}

.home-header__payments-items-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%
}

@media(max-width:960px) {
    .home-header__payments-items-wrap {
        flex-wrap: wrap
    }
}

.home-header__payments {
    display: flex;
    align-items: center;
    min-height: 96px;
    background-color: #262933
}

@media(max-width:960px) {
    .home-header__payments {
        margin-top: -30px;
        padding: 12px 0;
        margin-bottom: 30px
    }
}

.home-header__payments .home-header__container {
    display: flex;
    justify-content: space-between
}

.home-header__payments-item {
    display: flex;
    align-items: center;
    margin-left: 35px;
    margin-right: 35px
}

.home-header__payments-item--image {
    max-width: 100%;
    max-height: 40px
}

@media(max-width:1200px) {
    .home-header__payments-item {
        margin-left: 20px;
        margin-right: 20px
    }
}

@media(max-width:960px) {
    .home-header__payments-item {
        margin-top: 12px;
        margin-bottom: 12px
    }
}

.home-header__payments-btn {
    text-transform: lowercase;
    text-align: center;
    padding: 7px;
    border: 1px solid hsla(0, 0%, 100%, .12);
    min-width: 100px;
    color: hsla(0, 0%, 100%, .65);
    border-radius: 8px
}

.home-header__payments-btn:hover {
    color: #fff
}

.home-keep-calm {
    position: relative;
    padding: 64px 0 20px
}

@media(min-width:720px) {
    .home-keep-calm {
        padding: 104px 0 40px
    }
}

.home-keep-calm .home-keep-calm__container {
    display: flex;
    flex-direction: column;
    align-items: center
}

.home-keep-calm .home-keep-calm__title {
    display: flex;
    margin-bottom: 30px;
    text-align: center
}

@media(min-width:720px) {
    .home-keep-calm .home-keep-calm__title {
        margin-bottom: 56px
    }
}

@media(min-width:960px) {
    .home-keep-calm .home-keep-calm__title {
        max-width: 604px;
        margin-bottom: 70px
    }
}

@media(min-width:1632px) {
    .home-keep-calm .home-keep-calm__title {
        max-width: 850px;
        font-size: 48px;
        line-height: 60px
    }
}

.home-keep-calm__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1300px;
    margin: 0 auto
}

@media(min-width:960px) {
    .home-keep-calm__items {
        flex-wrap: nowrap
    }
}

@media(min-width:720px) {
    .home-keep-calm__content {
        width: calc(100% - 96px)
    }
}

@media(min-width:960px) {
    .home-keep-calm__content {
        width: 100%
    }
}

.home-keep-calm__item {
    width: calc(50% - 12px);
    margin-bottom: 30px;
    display: flex;
    flex-direction: column
}

@media(min-width:720px) {
    .home-keep-calm__item {
        flex-direction: row;
        align-items: flex-start;
        width: calc(50% - 22px)
    }
}

@media(min-width:960px) {
    .home-keep-calm__item {
        width: calc(25% - 18px);
        flex-direction: column;
        align-items: center;
        text-align: center
    }
}

.home-keep-calm__item-img-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 64px;
    width: 64px;
    margin-bottom: 41px
}

@media(min-width:720px) {
    .home-keep-calm__item-img-wrap {
        width: 80px;
        height: 64px;
        margin-right: 16px
    }
}

@media(min-width:960px) {
    .home-keep-calm__item-img-wrap {
        margin-bottom: 28px
    }
}

.home-keep-calm__item-img {
    max-width: 100%;
    max-height: 100%
}

.home-keep-calm__item-title {
    display: inline-flex;
    margin-bottom: 8px;
    font-weight: 900
}

@media(min-width:1632px) {
    .home-keep-calm__item-title {
        margin-bottom: 12px
    }
}

.home-keep-calm__item-description {
    font-size: 14px
}

@media(min-width:1248px) {
    .home-keep-calm__item-description {
        font-size: 16px
    }
}

.home-new-betting {
    position: relative;
    background: /*url(url(../assets/new-betting-bg.693ce052.png)*/
    url('') #262933 bottom no-repeat;
    background-size: cover;
    overflow: hidden
}

.home-new-betting .home-new-betting__container {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-top: 48px;
    padding-bottom: 48px
}

@media(min-width:720px) {
    .home-new-betting .home-new-betting__container {
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        padding-top: 64px;
        padding-bottom: 64px
    }
}

@media(min-width:960px) {
    .home-new-betting .home-new-betting__container {
        padding-top: 80px;
        padding-bottom: 80px
    }
}

@media(min-width:1248px) {
    .home-new-betting .home-new-betting__container {
        padding-top: 104px;
        padding-bottom: 104px;
        min-height: 578px
    }
}

@media(min-width:1632px) {
    .home-new-betting .home-new-betting__container {
        padding-top: 88px;
        padding-bottom: 88px
    }
}

.home-new-betting__person-img {
    position: absolute;
    bottom: 0;
    height: 70%;
    width: auto;
    background: radial-gradient(50% 50% at 50% 50%, rgba(19, 225, 39, .19) 0, rgba(15, 194, 33, 0) 100%);
    left: 50%;
    transform: translateX(-50%)
}

@media(min-width:720px) {
    .home-new-betting__person-img {
        left: 0;
        right: auto;
        height: 95%;
        transform: none
    }
}

@media(min-width:1248px) {
    .home-new-betting__person-img {
        left: auto;
        right: 600px
    }
}

.home-new-betting__info {
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: center;
    z-index: 2
}

@media(min-width:720px) {
    .home-new-betting__info {
        text-align: left;
        width: 298px
    }
}

@media(min-width:960px) {
    .home-new-betting__info {
        width: 472px
    }
}

.home-new-betting__title {
    display: block;
    margin-bottom: 16px;
    font-size: 24px;
    line-height: 32px;
    text-align: center
}

@media(min-width:720px) {
    .home-new-betting__title {
        text-align: left;
        font-size: 32px;
        line-height: 42px
    }
}

@media(min-width:1248px) {
    .home-new-betting__title {
        font-size: 40px;
        line-height: 52px
    }
}

@media(min-width:1632px) {
    .home-new-betting__title {
        font-size: 48px;
        line-height: 60px
    }
}

.home-new-betting__description {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: hsla(0, 0%, 100%, .65);
    text-align: center
}

@media(min-width:720px) {
    .home-new-betting__description {
        text-align: left
    }
}

@media(min-width:1248px) {
    .home-new-betting__description {
        font-size: 20px;
        line-height: 30px
    }
}

.home-new-betting__btns-wrap {
    justify-content: center;
    margin-top: 264px
}

@media(min-width:720px) {
    .home-new-betting__btns-wrap {
        margin-top: 32px;
        display: flex;
        justify-content: flex-start
    }
}

@media(min-width:1248px) {
    .home-new-betting__btns-wrap {
        margin-top: 40px
    }
}

.home-new-betting .home-new-betting__btn {
    width: auto;
    padding: 12px 20px;
    min-width: 200px;
    height: 48px
}

@media(min-width:720px) {
    .home-new-betting .home-new-betting__btn {
        min-width: inherit;
        margin-bottom: 0
    }
}

@media(min-width:960px) {
    .home-new-betting .home-new-betting__btn {
        padding: 16px 24px
    }
}

@media(min-width:1248px) {
    .home-new-betting .home-new-betting__btn {
        height: 56px
    }
}

.home-play-more {
    position: relative;
    background: /*url(/play-more-bg.png)*/
    url('') #1c1e26 bottom no-repeat;
    background-size: cover;
    padding: 48px 0 56px
}

.home-play-more:before {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    transform: translateY(40%);
    width: 312px;
    min-height: 242px;
    background: /*url(url(../assets/golds.706eddfc.png)*/
    url('') bottom no-repeat;
    background-size: contain;
    display: none
}

@media(min-width:720px) {
    .home-play-more:before {
        display: block;
        transform: translateY(40%)
    }
}

@media(min-width:1248px) {
    .home-play-more:before {
        transform: translateY(36%);
        width: 640px;
        height: 500px;
        transform: translateY(40%)
    }
}

@media(min-width:720px) {
    .home-play-more {
        padding: 64px 0 72px
    }
}

@media(min-width:960px) {
    .home-play-more {
        padding: 90px 0 80px
    }
}

@media(min-width:1248px) {
    .home-play-more {
        padding: 148px 0 120px
    }
}

.home-play-more .home-play-more__container {
    display: flex;
    flex-direction: column
}

@media(min-width:720px) {
    .home-play-more .home-play-more__container {
        flex-direction: row
    }
}

.home-play-more__info {
    display: flex;
    flex-direction: column;
    text-align: center
}

@media(min-width:720px) {
    .home-play-more__info {
        text-align: left;
        width: calc(100% - 244px);
        padding-right: 40px
    }
}

@media(min-width:960px) {
    .home-play-more__info {
        width: calc(100% - 520px);
        padding-right: 16px
    }
}

@media(min-width:1248px) {
    .home-play-more__info {
        width: calc(100% - 654px);
        padding-right: 12px
    }
}

@media(min-width:1632px) {
    .home-play-more__info {
        width: calc(100% - 714px);
        padding-right: 48px
    }
}

.home-play-more__items {
    display: flex;
    flex-direction: column;
    margin-top: 32px
}

@media(min-width:720px) {
    .home-play-more__items {
        margin-top: 0;
        width: 244px
    }
}

@media(min-width:960px) {
    .home-play-more__items {
        padding-left: 70px;
        width: 520px;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: wrap
    }
}

@media(min-width:1248px) {
    .home-play-more__items {
        width: 654px
    }
}

@media(min-width:1632px) {
    .home-play-more__items {
        width: 714px
    }
}

.home-play-more__title {
    display: block;
    margin-bottom: 16px;
    font-size: 24px;
    line-height: 32px;
    text-align: center
}

@media(min-width:720px) {
    .home-play-more__title {
        text-align: left;
        font-size: 32px;
        line-height: 42px
    }
}

@media(min-width:1248px) {
    .home-play-more__title {
        font-size: 40px;
        line-height: 52px
    }
}

@media(min-width:1632px) {
    .home-play-more__title {
        font-size: 48px;
        line-height: 60px
    }
}

.home-play-more__description {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: hsla(0, 0%, 100%, .65);
    text-align: center
}

@media(min-width:720px) {
    .home-play-more__description {
        text-align: left
    }
}

@media(min-width:1248px) {
    .home-play-more__description {
        font-size: 20px;
        line-height: 30px
    }
}

.home-play-more__btns-wrap {
    display: none;
    justify-content: center;
    margin-top: 24px
}

@media(min-width:720px) {
    .home-play-more__btns-wrap {
        margin-top: 32px;
        display: flex;
        justify-content: flex-start
    }
}

@media(min-width:1248px) {
    .home-play-more__btns-wrap {
        margin-top: 40px
    }
}

.home-play-more .home-play-more__btn {
    width: auto;
    padding: 12px 20px;
    margin-bottom: 32px;
    min-width: 200px;
    height: 48px
}

@media(min-width:720px) {
    .home-play-more .home-play-more__btn {
        min-width: inherit
    }
}

@media(min-width:960px) {
    .home-play-more .home-play-more__btn {
        padding: 16px 24px
    }
}

@media(min-width:1248px) {
    .home-play-more .home-play-more__btn {
        height: 56px
    }
}

.home-play-more__item-description {
    margin-top: 12px;
    font-size: 16px;
    line-height: 24px;
    color: hsla(0, 0%, 100%, .65);
    background-color: transparent
}

@media(min-width:320px) {
    .home-play-more__item-description {
        display: none!important
    }
}

@media(min-width:960px) {
    .home-play-more__item-description {
        display: inline-flex!important
    }
}

.home-play-more__item {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    min-height: 110px;
    width: 100%;
    margin-bottom: 12px!important;
    border-radius: 12px;
    z-index: 1;
    color: #fff;
    padding: 16px 120px 16px 16px!important;
    overflow: hidden
}

@media(min-width:320px) {
    .home-play-more__item {
        display: flex!important
    }
    .home-play-more__item .home-play-more__item-title {
        font-size: 20px;
        line-height: 26px;
        color: #fff
    }
    .home-play-more__item:first-child {
        width: 100%;
        justify-content: flex-end
    }
    .home-play-more__item:first-child .home-play-more__item-image {
        max-width: 340px
    }
}

@media(min-width:720px) {
    .home-play-more__item {
        padding: 12px!important
    }
}

@media(min-width:960px) {
    .home-play-more__item {
        width: calc(50% - 6px);
        min-height: 180px;
        padding: 12px!important;
        display: flex!important
    }
    .home-play-more__item .home-play-more__item-title {
        font-size: 20px;
        line-height: 26px
    }
    .home-play-more__item:first-child {
        overflow: visible;
        width: 100%;
        padding: 32px 28px 40px 154px!important;
        justify-content: flex-start
    }
    .home-play-more__item:first-child .home-play-more__item-title {
        font-size: 24px;
        line-height: 32px
    }
    .home-play-more__item:first-child .home-play-more__item-image {
        max-width: 240px;
        position: absolute;
        left: -55px;
        top: -80px;
        transform: none
    }
}

@media(min-width:1248px) {
    .home-play-more__item {
        margin-bottom: 16px!important;
        width: calc(50% - 8px);
        min-height: 256px;
        padding: 24px!important;
        display: flex!important
    }
    .home-play-more__item .home-play-more__item-title {
        font-size: 24px;
        line-height: 32px
    }
    .home-play-more__item:first-child {
        padding: 40px 40px 28px 230px!important;
        position: relative
    }
    .home-play-more__item:first-child .home-play-more__item-image {
        max-width: 350px;
        position: absolute;
        left: -70px;
        top: -50px;
        transform: none
    }
}

.home-play-more__item:after {
    position: absolute;
    content: "";
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    z-index: -2;
    border-radius: 12px
}

.home-play-more__item--vip:after {
    background: linear-gradient(144.26deg, #48b2ff 1.47%, #2e55df)
}

.home-play-more__item--tournaments:after {
    background: linear-gradient(144.26deg, #ff986c 1.47%, #ea2c48)
}

.home-play-more__item--bonuses:after {
    background: linear-gradient(144.26deg, #c9ff72 1.47%, #0d844b)
}

.home-play-more__item-image {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    -o-object-fit: scale-down;
    object-fit: scale-down;
    z-index: -1
}

@media(min-width:720px) {
    .home-play-more__item-image {
        right: -40px
    }
}

@media(min-width:960px) {
    .home-play-more__item-image {
        display: none
    }
}

.home-play-more__item-image--md {
    display: none
}

@media(min-width:960px) {
    .home-play-more__item-image--md {
        display: inline-flex
    }
}

@media(min-width:960px) {
    .home-play-more__item-title {
        font-size: 20px;
        line-height: 26px
    }
}

@media(min-width:1248px) {
    .home-play-more__item-title {
        font-size: 24px;
        line-height: 32px
    }
}

.home-promotions-slider .slick-track {
    display: flex!important
}

.home-promotions-slider__item {
    width: calc(100vw - 32px)!important;
    height: 412px
}

@media(min-width:720px) {
    .home-promotions-slider__item {
        width: calc(50vw - 23px)!important
    }
}

@media(min-width:960px) {
    .home-promotions-slider__item {
        width: calc(33.33333vw - 26px)!important
    }
}

@media(min-width:1248px) {
    .home-promotions-slider__item {
        width: calc(25vw - 24px)!important
    }
}

@media(min-width:1350px) {
    .home-promotions-slider__item {
        width: 312px!important
    }
}

@media(min-width:1632px) {
    .home-promotions-slider__item {
        width: 380px!important;
        height: 432px
    }
}

.home-true-wins {
    position: relative;
    padding: 48px 0;
    background: #262933/*url(url(../assets/true-wins--xs.2ff1dff2.png)*/
    url('') bottom no-repeat;
    background-position: 100% 100%;
    overflow: hidden
}

@media(min-width:720px) {
    .home-true-wins {
        background-image: none;
        padding: 64px 0
    }
}

@media(min-width:960px) {
    .home-true-wins {
        padding: 80px 0
    }
}

@media(min-width:1248px) {
    .home-true-wins {
        padding: 120px 0
    }
}

.home-true-wins:before {
    position: absolute;
    content: "";
    bottom: 0;
    right: -100px;
    width: 396px;
    height: 223px;
    /*background: url(url(../assets/true-wins.bf4491ff.png) bottom no-repeat;*/
    background-size: cover;
    display: none
}

@media(min-width:720px) {
    .home-true-wins:before {
        display: block;
        width: 426px;
        height: 240px
    }
}

@media(min-width:1248px) {
    .home-true-wins:before {
        width: 622px;
        height: 350px
    }
}

.home-true-wins__container {
    display: flex;
    flex-wrap: wrap
}

@media(min-width:720px) {
    .home-true-wins__container {
        flex-wrap: nowrap
    }
}

.home-true-wins__content {
    position: relative;
    z-index: 2;
    width: 100%
}

@media(min-width:720px) {
    .home-true-wins__content {
        order: 2;
        padding-left: 36px;
        width: calc(100% - 328px)
    }
}

@media(min-width:960px) {
    .home-true-wins__content {
        padding-left: 40px;
        width: calc(100% - 524px)
    }
}

@media(min-width:1248px) {
    .home-true-wins__content {
        padding-left: 32px;
        width: calc(100% - 684px)
    }
}

@media(min-width:1632px) {
    .home-true-wins__content {
        padding-left: 56px;
        width: calc(100% - 776px)
    }
}

.home-true-wins__content .home-true-wins__btns-wrap {
    display: none
}

@media(min-width:720px) {
    .home-true-wins__content .home-true-wins__btns-wrap {
        display: flex
    }
}

.home-true-wins__games {
    position: relative;
    z-index: 2;
    margin-top: 32px;
    width: 100%
}

@media(min-width:720px) {
    .home-true-wins__games {
        order: 1;
        margin-top: 0;
        width: 328px
    }
}

@media(min-width:960px) {
    .home-true-wins__games {
        width: 524px
    }
}

@media(min-width:1248px) {
    .home-true-wins__games {
        width: 684px
    }
}

@media(min-width:1632px) {
    .home-true-wins__games {
        width: 776px
    }
}

.home-true-wins__games .slick-list {
    overflow: visible!important
}

.home-true-wins__games .landing-game-wins-slider__slider-item {
    width: calc(50vw - 26px)!important
}

.home-true-wins__games .slick-track {
    display: flex!important
}

.home-true-wins__games .home-true-wins__btns-wrap {
    display: flex
}

@media(min-width:720px) {
    .home-true-wins__games .home-true-wins__btns-wrap {
        display: none
    }
}

.home-true-wins__title {
    display: block;
    margin-bottom: 16px;
    font-size: 24px;
    line-height: 32px;
    text-align: center
}

@media(min-width:720px) {
    .home-true-wins__title {
        text-align: left;
        font-size: 32px;
        line-height: 42px;
        margin-bottom: 24px
    }
}

@media(min-width:1248px) {
    .home-true-wins__title {
        font-size: 40px;
        line-height: 52px
    }
}

@media(min-width:1632px) {
    .home-true-wins__title {
        font-size: 48px;
        line-height: 60px;
        margin-bottom: 32px
    }
}

.home-true-wins__description {
    margin-bottom: 8px;
    color: #fff;
    font-size: 16px;
    text-align: center
}

@media(min-width:720px) {
    .home-true-wins__description {
        font-size: 20px;
        text-align: left
    }
}

.home-true-wins__count {
    color: #ffd653;
    font-size: 32px;
    line-height: 42px;
    text-align: center;
    width: 100%;
    display: block
}

@media(min-width:720px) {
    .home-true-wins__count {
        margin-bottom: 32px;
        text-align: left
    }
}

@media(min-width:960px) {
    .home-true-wins__count {
        font-size: 48px;
        line-height: 60px
    }
}

.home-true-wins .home-true-wins__slider {
    margin-bottom: 24px
}

@media(min-width:720px) {
    .home-true-wins .home-true-wins__slider {
        margin-bottom: 8px
    }
}

@media(min-width:960px) {
    .home-true-wins .home-true-wins__slider {
        margin-bottom: 14px
    }
}

@media(min-width:1248px) {
    .home-true-wins .home-true-wins__slider {
        margin-bottom: 16px
    }
}

.home-true-wins__btns-wrap {
    display: flex;
    justify-content: center;
    margin-top: 24px
}

@media(min-width:720px) {
    .home-true-wins__btns-wrap {
        justify-content: flex-start;
        margin-top: 32px;
        display: block
    }
}

@media(min-width:960px) {
    .home-true-wins__btns-wrap {
        margin-top: 36px
    }
}

.home-true-wins .home-true-wins__btn {
    width: auto;
    padding: 12px 20px;
    min-width: 200px;
    height: 48px
}

@media(min-width:720px) {
    .home-true-wins .home-true-wins__btn {
        min-width: inherit
    }
}

@media(min-width:960px) {
    .home-true-wins .home-true-wins__btn {
        padding: 16px 24px
    }
}

@media(min-width:1248px) {
    .home-true-wins .home-true-wins__btn {
        height: 56px
    }
}

.promotion-bonus--jackpots .promotion-bonus__wrap {
    text-align: center
}

.promotion-bonus--jackpots .promotion-bonus__logo-wrap {
    margin-bottom: 8px
}

.promotion-bonus--jackpots .promotion-bonus__logo-wrap .image {
    width: 72px
}

.promotion-bonus--jackpots .promotion-bonus__title {
    font-family: Montserrat Alternates, sans-serif;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: .01em;
    text-transform: uppercase;
    color: #fff;
    max-width: 260px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 32px
}

@media(max-width:960px) {
    .promotion-bonus--jackpots .promotion-bonus__title {
        font-size: 28px;
        line-height: 36px
    }
}

.promotion-bonus--jackpots .promotion-bonus__subtitle {
    margin-bottom: 4px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: hsla(0, 0%, 100%, .65)
}

.promotion-bonus--jackpots .promotion-bonus__pool {
    font-family: Montserrat, Arial, sans-serif;
    font-weight: 900;
    font-size: 40px;
    line-height: 52px;
    text-align: center;
    color: #fff
}

.promotion-bonus--jackpots .promotion-bonus__footer-content {
    opacity: 0
}

.promotion-bonus {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 28px 24px 24px;
    border-radius: 20px;
    z-index: 2;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover
}

.promotion-bonus__personBg {
    position: absolute;
    background-size: cover;
    overflow: hidden;
    z-index: -2;
    height: 350px;
    left: 110px
}

@media(max-width:720px) {
    .promotion-bonus__personBg {
        left: 60px
    }
}

.promotion-bonus__title {
    color: hsla(0, 0%, 100%, .65);
    text-transform: uppercase;
    margin-bottom: 28px
}

.promotion-bonus__info {
    position: absolute;
    padding: 0;
    top: 18px;
    right: 18px
}

.promotion-bonus__wrap {
    display: flex;
    flex: auto;
    flex-direction: column;
    justify-content: space-between
}

.promotion-bonus__head-title {
    display: block;
    margin-bottom: 8px
}

.promotion-bonus__head-prize {
    color: #fff;
    max-width: 168px
}

.promotion-bonus__footer-btn {
    width: 100%;
    margin-bottom: 16px;
    background-color: hsla(0, 0%, 100%, .12);
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px)
}

.promotion-bonus__footer-btn:hover {
    background-color: hsla(0, 0%, 100%, .2)
}

.promotion-bonus__footer-content {
    display: flex;
    justify-content: center;
    align-items: center
}

.tourn-timer {
    display: block;
    width: 100%;
    max-width: 335px;
    padding: 0;
    margin: 0 auto 3px;
    color: hsla(0, 0%, 100%, .7);
    font-size: 12px;
    line-height: 1.2;
    text-align: center;
    font-weight: 500;
    text-transform: none
}

@media(min-width:720px) {
    .tourn-timer {
        font-size: 14px;
        font-weight: 600
    }
}

@media(min-width:960px) {
    .tourn-timer {
        font-size: 16px;
        text-align: left;
        margin: 0
    }
}

.tourn-timer .inline-timer__text {
    display: block!important;
    font-weight: 500
}

.tourn-timer .inline-timer__text--full {
    display: none!important
}

.tourn-timer .inline-timer__item {
    display: flex;
    margin-left: 4px
}

.tourn-timer .inline-timer__value {
    font-weight: 500;
    margin-right: 1px
}

.tourn-timer .inline-timer__value:after,
.tourn-timer .inline-timer__value:before {
    display: none
}

.tourn-timer .inline-timer {
    height: auto
}

.tourn-timer__title {
    display: block;
    width: 100%;
    padding: 0;
    margin: 0!important;
    font-weight: 400
}

.tourn-timer__timer {
    margin: 8px 0 0
}

@media(min-width:960px) {
    .tourn-timer__timer {
        margin-top: 12px
    }
}

.tourn-winners-slide {
    display: block;
    width: 100%;
    padding: 0;
    margin: 0
}

.tourn-winners-slide__more {
    display: block;
    width: 100%;
    padding: 0;
    margin: 24px 0 0;
    text-align: center
}

@media(min-width:720px) {
    .tourn-winners-slide__more {
        margin-top: 32px
    }
}

.tourn-winners-slide__table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    border: none;
    margin: 0 auto 12px;
    max-width: 100%
}

.tourn-winners-slide__row:nth-child(n+4) {
    display: none
}

.tourn-winners-slide__row:nth-child(3) .tourn-winners-slide__cell {
    border-bottom: none
}

.tourn-winners-slide__cell {
    padding: 0 5px;
    text-align: center;
    vertical-align: middle;
    font-style: normal;
    letter-spacing: normal;
    height: 34px;
    color: #fff;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    border: none;
    border-bottom: 1px solid hsla(0, 0%, 100%, .2)
}

@media(min-width:720px) {
    .tourn-winners-slide__cell {
        height: 40px;
        font-size: 14px;
        line-height: 17px
    }
}

.tourn-winners-slide__cell:first-child {
    text-align: left
}

.tourn-winners-slide__cell:last-child {
    text-align: right
}

@media(min-width:720px) {
    .tourn-winners-slide__cell {
        height: 40px
    }
}

.tourn-winners-slide__name {
    display: block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.game-tourn .tourn-winners-slide .tourn-winners-slide__cell {
    font-size: 12px!important;
    font-weight: 700;
    border-bottom: none;
    height: 47px
}

.game-tourn .tourn-winners-slide .tourn-winners-slide__cell--name {
    padding: 0
}

.game-tourn .tourn-winners-slide .tourn-winners-slide__cell:last-child {
    padding-right: 8px
}

.game-tourn .tourn-winners-slide thead .tourn-winners-slide__cell--place {
    width: 32px;
    padding: 0 5px 0 0
}

.game-tourn .tourn-winners-slide thead .tourn-winners-slide__cell--prize {
    width: 40%
}

.game-tourn .tourn-winners-slide thead .tourn-winners-slide__cell--points {
    width: 23%
}

.game-tourn .tourn-winners-slide .tourn-winners-slide__more {
    display: none
}

.tourn-winners-slide .tourn-player-prizes-list__nominal {
    font-weight: 700
}